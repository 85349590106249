import React, { Children, Component } from "react";
import logoImage from "../assets/img/logo-new.svg";
import HeadContact from "../assets/img/mobile_head.svg";
import HeadGetQuote from "../assets/img/get_quote_head.svg";
import HeadUser from "../assets/img/Head_User.svg";
import HeadSearch from "../assets/img/HeadSearch.svg";
import HeadMenu from "../assets/img/HeadMenu.svg";
import FormHelperText from "@material-ui/core/FormHelperText";
import SuccessCheck from "../assets/img/suss-check.svg";
import api from "../utils/apiClient";
import cogoToast from "cogo-toast";

var stepValidate = false;
var allValid = false;

const commonUrl = "https://rates.sflworldwide.com/";
const rateurl = "https://rates.sflworldwide.com/"; //rates.sflworldwide.com/";
const productiourl = "https://www.sflworldwide.com/";
let BaseUrlData = localStorage.getItem("BaseUrlData");
localStorage.removeItem("BaseUrlData")
let PhoneNumberTollFree = "1-800-691-2335"
let hrefPhone = "tel:18006912335"
let indiaornot = "No"

let dataindia = localStorage.getItem("forIndia")
if(dataindia == "Yes"){
  indiaornot = "Yes"
  PhoneNumberTollFree = "1-800-833-4849"
  hrefPhone = "tel:18008334849"
}

// localStorage.removeItem("forIndia")
class SuccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PhoneNumbervalueErr:"",
      ContactNumberErrText:"",
      onlyNumberErrText:"",
      contactErrText:"",
      EmailIdErrText:"",
      EmailErrText:"",
      ContactvalueErr:"",
      cnameErrText:"",
      email:"",
      PhoneNumber:"",
      ContactName:"",


    }
  }

  async componentDidMount() {
    debugger
    let LeadDetails = [JSON.parse(localStorage.getItem("ThankYou"))];
    console.log("Lead = ",LeadDetails);
    // console.log(LeadDetails[0].ContactName);
    if(LeadDetails[0]){
      this.setState({ContactName:LeadDetails[0].ContactName})
      this.setState({PhoneNumber:LeadDetails[0].PhoneNumber})
      this.setState({email:LeadDetails[0].Email})
    }else{
      document.getElementById("contactinfo").style.display = "none"
    }
    

  }


  urlclick() {
    debugger;
    window.location = "https://www.sflworldwide.com/";
  }
  GetNewGetRate() {
    window.location = commonUrl ;
  }
 
  shipperInfoChange = (event, type) => {
    if (type === "ContactName") {
      this.setState({ ContactName: event.target.value });
    } else if (type === "Number") {
      this.setState({ PhoneNumber: event.target.value });
    } else if (type === "Email") {
      this.setState({ email: event.target.value });
    }
  };
  showHideMenu() {
    let stylediv = document.querySelector('#sidebarCheck').style.display
    if (stylediv == "none") {
      document.getElementById("sidebarCheck").style.display = "block"
    } else {
      document.getElementById("sidebarCheck").style.display = "none"
    }
  }

  validateShipperInfo = (evt, type) => {
    debugger;
    if (type == "cname") {
      if (this.state.ContactName != "") {
        if (this.state.ContactName.trim().length < 3) {
          stepValidate = false;
          allValid = false;
          this.setState({
            cnameErrText: "Please enter atleast 3 character",
          });
        } else {
          if (this.state.PhoneNumber != "" && this.state.email != "") {
            stepValidate = true;
            allValid = true;
          } else {
            stepValidate = false;
            allValid = false;
          }
        }
      } else {
        stepValidate = false;
        allValid = false;
        this.setState({
          cnameErrText: "",
        });
      }
    } else if (type === "phone") {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.setState({
          onlyNumberErrText: "Enter only digits",
        });
        stepValidate = false;
        allValid = false;
        return false;
      } else if (this.state.email != "") {
        stepValidate = true;
        allValid = true;
        return true;
      } else {
        stepValidate = false;
        allValid = false;
      }
    } else if (type === "phoneblur") {
      var phonenoRegex = /^\d{10}$/;
      if (this.state.PhoneNumber != "") {
        if (
          this.state.PhoneNumber.length < 10 ||
          !phonenoRegex.test(this.state.PhoneNumber)
        ) {
          this.setState({
            contactErrText: "Please enter valid phone number",
          });
          stepValidate = false;
          allValid = false;
        } else if (this.state.email != "") {
          stepValidate = true;
          allValid = true;
        } else {
          stepValidate = false;
          allValid = false;
        }
      } else {
        stepValidate = false;
        allValid = false;
        this.setState({
          contactErrText: "Please enter valid phone number",
        });
      }
    } else if (type === "email") {
      debugger;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email != "") {
        if (!emailRegex.test(this.state.email)) {
          this.setState({
            EmailErrText: "Please enter valid email",
          });
          stepValidate = false;
          allValid = false;
        } else {
          stepValidate = true;
          allValid = true;
        }
      } else {
        stepValidate = false;
        allValid = false;
      }
    }
  };

  updateInfo = () =>{
    debugger
    var name = this.state.ContactName
    var phoneNumber = this.state.PhoneNumber
    var email = this.state.email

    var val = 0;
    if (this.state.ContactName == "") {
      this.setState({
        cnameErrText: "Please enter contact name",
      });
      val = 1;
    }

    if (this.state.email == "") {
      this.setState({
        EmailErrText: "Please enter email",
      });
      val = 1;
    }
    if (this.state.PhoneNumber == "") {
      this.setState({
        // cnameErrText: "Please enter contact name",
        ContactNumberErrText: "Please enter phone number",
        // EmailErrText: "Please enter email",
      });
      val = 1;
    } else {
      if (this.state.PhoneNumber.length == 10) {
        val = 0;
      } else {
        this.setState({
          // cnameErrText: "Please enter contact name",
          ContactNumberErrText: "Please enter valid phone number",
          // EmailErrText: "Please enter email",
        });
        val = 1;
      }
    }
    if(val == 0){
      allValid = true
    }

    if(val == 0 && allValid == true){
      console.log("here = ", name , " + ", email , " + ", phoneNumber);
      var Id = localStorage.getItem("LeadID")
      var Senddata = {
        name: name,
        email:email,
        phone: phoneNumber,
        Id: Id
      }

      api
      .post("salesLead/UpdateforThankyou", Senddata)
      .then((res) => {
        if (res.success) {
          cogoToast.success("Information updated successfully");
          document.getElementById("contactinfo").style.display = "none"
          localStorage.removeItem("ThankYou")
        } else {
          cogoToast.error("Something went wrong");
        }
      })
      .catch((error) => {});

    }
  }

  render() {
    return (
      <div className="get-quote-wrap success-outer">
        <div className="quote-header">
          <div className="container">
            <div className="header-inner">
              <div className="logo">
                <a href={BaseUrlData}>
                  <img src={logoImage} alt="SFL Worldwide" />
                </a>
              </div>
              <div className="header-main-menu">
                <ul>
                  <li>
                    <a href={BaseUrlData + "track-shipment/"}>
                      Tracking
                    </a>
                  </li>
                  <li>
                    <a href="https://hub.sflworldwide.com/">Book Shipment</a>
                  </li>
                  <li>
                    <a href={BaseUrlData + "contact-us"}>
                      Contact Us
                    </a>
                  </li>
                  <li className="head-contact-no">
                    <a href={hrefPhone}>
                      <img src={HeadContact}></img>
                      <label>{PhoneNumberTollFree}</label>
                    </a>
                  </li>
                  <li className="head-get-quote">
                    <a href="https://rates.sflworldwide.com/">
                      <img src={HeadGetQuote}></img>
                      <label>Get Quote</label>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-right">
                <ul>
                  <li>
                    <a href="#">
                      <img src={HeadUser}></img>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={HeadSearch}></img>
                    </a>
                  </li>
                  <li className="head-menu-outer">
                    <a onClick={() => this.showHideMenu()}>
                      <img src={HeadMenu}></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="success-wrap">
          <div className="success-wrap-inner">
            <span>
              <img src={SuccessCheck}></img>
            </span>
            <h2>Wonderful! Thank You for Submitting Your Request.</h2>
            <p>
              One of our representatives will call you or email you shortly with
              pricing you will love!
            </p>

            <div className="form-thankyou" id = "contactinfo">
            <span className="font-info">Please confirm the contact information below</span>
              <div className="box-outer section-1 thankyou">
                
                <div className="input-outer col-3">
                  
                  <label>Name</label>
                  <input
                    autoComplete="off"
                    role="presentation"
                    id="ContactName"
                    type="text"
                    value = {this.state.ContactName || ""}
                    maxLength={30}
                    onBlur={(e) => this.validateShipperInfo(e, "cname")}
                    onChange={(e) => {
                      this.shipperInfoChange(e, "ContactName");
                    }}
                    onFocus={() =>
                      this.setState({
                        cnameErrText: "",
                      })
                    }
                    placeholder="Enter name"
                  ></input>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.cnameErrText}
                  </FormHelperText>

                  <span className="user-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ContactvalueErr}
                  </FormHelperText>
                </div>
                <div className="input-outer col-3">
                  <label>Email Address</label>
                  <input
                    autoComplete="off"
                    role="presentation"
                    type="email"
                    maxLength={60}
                    id="email"
                    value={this.state.email || ""}
                    onBlur={(e) => this.validateShipperInfo(e, "email")}
                    onChange={(e) => this.shipperInfoChange(e, "Email")}
                    onFocus={() =>
                      this.setState({
                        EmailErrText: "",
                        shipperdetailsErrText: "",
                        EmailIdErrText: "",
                      })
                    }
                    // onBlur={() => {
                    //   this.setState({
                    //     EmailIdErrText: "Please enter valid email"
                    //   })
                    // }}

                    placeholder="Enter email"
                  ></input>
                  <span className="email-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.EmailErrText
                      ? this.state.EmailErrText
                      : this.state.EmailIdErrText}
                  </FormHelperText>
                </div>
                <div className="input-outer col-3">
                  <label>Phone Number</label>
                  <input
                    autoComplete="off"
                    role="presentation"
                    id="Number"
                    type="tel"
                    placeholder="Enter phone number"
                    value={this.state.PhoneNumber || ""}
                    // onChange={(e) => this.getBoxesData(i, e)}
                    onChange={(e) => {
                      e.target.value.length <= 10 &&
                        // this.getBoxesData(i, e);
                        this.setState({ PhoneNumber: e.target.value });
                    }}
                    onBlur={(e) => this.validateShipperInfo(e, "phoneblur")}
                    onFocus={() =>
                      this.setState({
                        contactErrText: "",
                        onlyNumberErrText: "",
                        ContactNumberErrText: "",
                      })
                    }
                  ></input>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.contactErrText}
                  </FormHelperText>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.onlyNumberErrText}
                  </FormHelperText>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ContactNumberErrText}
                  </FormHelperText>
                  <span className="phone-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PhoneNumbervalueErr}
                  </FormHelperText>
                </div>                
                <div className="input-outer col-3">
                  <div className="thanks-update-btn">
                    <a className="get-new-quote" onClick={this.updateInfo}>
                      Update
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <a href={productiourl}>Back to Home</a>
            <a className="get-new-quote" onClick={this.GetNewGetRate}>
              Get a New Quote
            </a>
          </div>
        </div>
        <div className="sidebar-outer" style={{ display: "none" }} id="sidebarCheck">
        <div className="sidenav_wrap">
            <a
              href="javscript:;"
              onClick={() => this.showHideMenu()}
              className="side_nav_close"
              aria-label="Close Sidebar"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.04962 14.95C1.23715 15.1375 1.49146 15.2428 1.75662 15.2428C2.02179 15.2428 2.27609 15.1375 2.46362 14.95L7.99962 9.414L13.5356 14.95C13.7242 15.1322 13.9768 15.233 14.239 15.2307C14.5012 15.2284 14.752 15.1232 14.9374 14.9378C15.1228 14.7524 15.228 14.5016 15.2303 14.2394C15.2326 13.9772 15.1318 13.7246 14.9496 13.536L9.41362 8L14.9496 2.464C15.1318 2.2754 15.2326 2.02279 15.2303 1.7606C15.228 1.4984 15.1228 1.24759 14.9374 1.06218C14.752 0.876772 14.5012 0.771603 14.239 0.769325C13.9768 0.767046 13.7242 0.867841 13.5356 1.05L7.99962 6.586L2.46362 1.05C2.27502 0.867841 2.02242 0.767046 1.76022 0.769325C1.49802 0.771603 1.24721 0.876772 1.0618 1.06218C0.876396 1.24759 0.771227 1.4984 0.768949 1.7606C0.76667 2.02279 0.867464 2.2754 1.04962 2.464L6.58562 8L1.04962 13.536C0.862151 13.7235 0.756836 13.9778 0.756836 14.243C0.756836 14.5082 0.862151 14.7625 1.04962 14.95Z"></path>
              </svg>
            </a>
            <div className="sidenav_inner">
              {indiaornot == "Yes"?(

                    <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
                    <li className="menu-item">
                    <a href={BaseUrlData} className="menu-link">
                      <i
                        className="icon before line-icon icon-home"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Home</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href="https://rates.sflworldwide.com/"
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-cursor"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Get Quote</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-tag"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Book Shipment</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "track-shipment"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-target"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Tracking</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href="https://hub.sflworldwide.com/" className="menu-link">
                      <i
                        className="icon before line-icon icon-user"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">My Account</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "about-us"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-briefcase"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">About Us</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "services"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-list"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Services</span>
                    </a>
                    </li>
                    
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "worldwide-destinations"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-globe-alt"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Worldwide Destinations</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "resources"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-notebook"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Resources</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href={BaseUrlData + "faq"} className="menu-link">
                      <i
                        className="icon before line-icon icon-question"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">FAQs</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "contact-us/locations"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-location-pin"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Locations</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href={BaseUrlData + "blog"} className="menu-link">
                      <i
                        className="icon before line-icon icon-info"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Blog</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "contact-us"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-envelope"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Contact Us</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "search"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-magnifier"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Site Search</span>
                    </a>
                    </li>
                    </ul>

              ):
              <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
              <li className="menu-item">
              <a href={BaseUrlData} className="menu-link">
                <i
                  className="icon before line-icon icon-home"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Home</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href="https://rates.sflworldwide.com/"
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-cursor"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Get Quote</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-tag"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Book Shipment</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "track-shipment"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-target"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Tracking</span>
              </a>
              </li>
              <li className="menu-item">
              <a href="https://hub.sflworldwide.com/" className="menu-link">
                <i
                  className="icon before line-icon icon-user"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">My Account</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "about-us"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-briefcase"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">About Us</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "services"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-list"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Services</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "shipping-for-small-business"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-organization"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Small Business Shipping</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "shop-us-stores-ship-worldwide"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-globe"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">
                  Shop US and Ship worldwide
                </span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "carriers"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-paper-plane"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Major Carriers</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "worldwide-destinations"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-globe-alt"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Worldwide Destinations</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "resources"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-notebook"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Resources</span>
              </a>
              </li>
              <li className="menu-item">
              <a href={BaseUrlData + "faq"} className="menu-link">
                <i
                  className="icon before line-icon icon-question"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">FAQs</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "contact-us/locations"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-location-pin"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Locations</span>
              </a>
              </li>
              <li className="menu-item">
              <a href={BaseUrlData + "blog"} className="menu-link">
                <i
                  className="icon before line-icon icon-info"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Blog</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "contact-us"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-envelope"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Contact Us</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "search"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-magnifier"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Site Search</span>
              </a>
              </li>
              </ul>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessPage;
