import React, { Children, Component } from "react";
import axios from "axios";
import { apiBase } from "../utils/config";
//import { makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from "@mui/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import logoImage from "../assets/img/logo-new.svg";
import backIcon from "../assets/img/back-icon.svg";
import infoIcon from "../assets/img/info-icon.svg";
import GridItem from "../components/Grid/GridItem";
import plusIcon from "../assets/img/Add-btn.svg";
import removeIcon from "../assets/img/delete-btn.svg";
import GridContainer from "../components/Grid/GridContainer";
import tickIcon from "../assets/img/get-quote/step-completed.svg";
import HeadContact from "../assets/img/mobile_head.svg";
import HeadGetQuote from "../assets/img/get_quote_head.svg";
import HeadUser from "../assets/img/Head_User.svg";
import HeadSearch from "../assets/img/HeadSearch.svg";
import HeadMenu from "../assets/img/HeadMenu.svg";
import Step1 from "../assets/img/step-1.svg";
import Step2 from "../assets/img/step-2.svg";
import Step3 from "../assets/img/step-3.svg";
import Step4 from "../assets/img/step-4.svg";
// import InfoIcon from '@material-ui/icons/Info';
import Envelop from "../assets/img/Envelop.svg";
import envelopActive from "../assets/img/envalope-filled.svg";
import Boxes from "../assets/img/box.svg";
import boxesActive from "../assets/img/box-filled.svg";
import Television from "../assets/img/TV.svg";
import televisionActive from "../assets/img/tv-filled.svg";
import Furniture from "../assets/img/Furniture.svg";
import furnitureActive from "../assets/img/furniture-filled.svg";
import Auto from "../assets/img/Auto.svg";
import autoActive from "../assets/img/auto-filled.svg";
import AddBtn from "../assets/img/Add-btn.svg";
import DeleteBtn from "../assets/img/delete-btn.svg";
import mobileAddBtn from "../assets/img/mobile-add.svg";
import mobileDelet from "../assets/img/mobile-delete.svg";
import mapIcon from "../assets/img/map-icon.svg";
import FormHelperText from "@material-ui/core/FormHelperText";
import cogoToast from "cogo-toast";
import { CommonConfig } from "../utils/constant";
import arrowRightWhite from "../assets/img/get-quote/arrow-right-white.svg";
import api from "../utils/apiClient";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import _, { indexOf, remove, times } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import SimpleBackdrop from "../utils/general";
import GetRate from "./GetRate";
import { NavLink } from "react-router-dom";
import { lightGreen } from "@material-ui/core/colors";

// const useStyles = () => makeStyles(styles);
// const classes = useStyles();
// const useStyles = makeStyles((theme) => ({
//   input: {
//     fontSize: "16px", // Adjust the font size as needed
//   },
// }));
// const classes = useStyles();

var stepValidate = false;
var allValid = false;
// const commonUrl = "https://phpstack-773983-3486562.cloudwaysapps.com/";
const commonUrl = "https://rates.sflworldwide.com/";
// const commonUrl = "http://localhost:3000/";

const productiourl = "https://www.sflworldwide.com/";
const bookurl = `https://hub.sflworldwide.com/auth/SalesLeadsRedirect-page/`;
// const bookurl = `http://localhost:3000/auth/SalesLeadsRedirect-page/`;
const rateurl = "https://rates.sflworldwide.com/";
const nextpageurl = commonUrl + "GetQuoteThankyou";
var BaseUrlData = "https://www.sflworldwide.com/"
class GetQuoteThankyouDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packdata: [],
      contactvalid: false,
      FirstStep: "",
      stepChanges: 0,
      SetMultiStep: "",
      setCurrencyIcon:"",
      ContactValidation: "",
      CheckZipCode: null,
      AllPackaeseArry: [],
      caryearlist: [],
      PackageStatusValue: "",
      BoxesStatusValue: "",
      AutoStatusValue: "",
      TelevisionStatusValue: "",
      EnvelopStatusValue: "",
      FurnitureStatusValue: "",
      premeta:"",
      PhoneNumberTollFree:"1-800-691-2335",
      hrefPhone:"tel:18006912335",
      indiaornot:"No",
      // BaseUrlData:"https://www.sflworldwide.com/",
      getWidthvalue: null,

      AllBoxValue: null,
      TotalChargableSend: 0,
      displayBack: 0,
      ratestoIndia: 100,
      AllAutoValue: null,
      AllTelevisionValue: null,
      AllPcakegesValue: null,
      IndiaManagedBy:16,

      allpackagesValue: [
        {
          ActualWeight: "",
          ChargeableWeight: "0",
          DimensionH: "",
          DimensionL: "",
          DimensionW: "",
          PackageType: "1",
          Quantity: "1",
          Status: "Active",
        },
      ],
      packdetailsChage: "",
      packtypeDetail: "",

      SelectedWeightType: "LB/IN",
      preSelected: "LB",
      packedBy: [
        { value: "1993", label: "1993" },
        { value: "2023", label: "2023" },
      ],
      SelectedDimensitionType: "Inches",
      DimensitionpackedBy: [
        { value: "Inches", label: "Inches" },
        { value: "CM", label: "CM" },
      ],
      allAutoValue: [
        {
          CarMake: "",
          CarModel: "",
          CarYear: "",
          PackageType: "5",
          Status: "Active",
        },
      ],

      EnvelopValue: [
        {
          PackageType: "2",
          ActualWeight: 0.5,
          DimensionL: 10,
          DimensionW: 13,
          DimensionH: 1,
          ChargeableWeight: 1,
          Status: "Active",
          Quantity: 1,
        },
      ],

      allTelevisionValue: [
        {
          ActualWeight: "",
          ChargeableWeight: "0",
          DimensionH: "",
          DimensionL: "",
          DimensionW: "",
          Quantity: "1",
          PackageType: "4",
          Status: "Active",
          TVMake: "",
          TVModel: "",
        },
      ],
      AllfurnitureValue: [
        {
          ActualWeight: "0",
          PackageType: "3",
          Quantity: "1",
          Status: "Active",
        },
      ],

      submitList: [],

      AllRatesDetails: null,

      BoxWeightvalue: null,
      BoxDimantionLengthValue: null,
      BoxDimantionWidthValue: null,
      BoxDimantionHeightValue: null,
      TelBrandName: null,
      TelModel: null,
      TelWeight: null,
      TelDLength: null,
      TelDWeight: null,
      TelDHeight: null,
      AutoBrandName: null,
      AutoCarModel: null,
      AutoCarYear: null,

      TelBrandNameErr: "",
      TelModelErr: "",
      TelWeightErr: "",
      TelDLengthErr: "",
      TelDWeightErr: "",
      TelDHeightErr: "",
      AutoBrandNameErr: "",
      AutoCarModelErr: "",
      AutoCarYearErr: "",
      PackageDetailsErr: "",
      BoxDimantionLengthErr: "",
      BoxDimantionWidthErr: "",
      BoxDimantionHeightErr: "",

      ShipmentErr: "",
      ToStateCodeValueErr: "",
      FromFedExCityValueErr: "",
      ToZipCodeValueErr: "",

      CountryList: [],
      countryCodeList:[],
      CountryListValue: [],
      countryCodeListValue:[],
      FromFedExCityOptionValue: [],
      FromFedExCityValue: [],
      ToFedExCityOptionValue: [],
      ToFedExCityValue: [],
      SelectedOption: "",
      // SelectedOptionCountryCode:"",
      USSelectedOption: {
        label: "Select Country",
        value: -1,
      },

      SelectedOptionCountryCode: {
        label: "United States(+1)",
        value: "United States(+1)",
      },

      SelectedOptionTo: "",
      FromSelectedCountry: {},

      FromZipCodeValue: null,
      FromZipCodeErr: "",

      ContactvalueErr: "",
      EmailvalueErr: "",
      PhoneNumbervalueErr: "",

      StepThirdtoSecond: "",
      FinaleBoxStart: "",
      EnvelopeStep4TO3: "",
      EnvelopeStep3TO1: "",

      noOfBoxinPackeges: [],

      ToSelectedCountry: {
        label: "",
        value: "",
      },
      hidefromzipcode: false,
      hidetozipcode: false,
      FromIsFedxCity: 0,
      ToIsFedxCity: 0,
      ThankyouPageMoveFromZip: false,
      ThankyouPageMoveToZip: false,
      FromCityList: [],
      FromFedExCityList: [],
      FromUPSCityList: [],
      ToCityList: [],
      ToFedExCityList: [],
      ToUPSCityList: [],
      setselcetedBox: [],
      inputs: [],
      television: [],
      auto: [],

      EnvelopStatus: null,
      LocationStatus: null,
      PackageStatus: null,
      ContactStatus: null,
      LastStatus: null,
      validationenable: false,

      WeightBoxDetailsValue: null,

      FromSelectedCity: {},
      FromFedExSelectedCity: {},
      FromUPSSelectedCity: {},
      ToFedExSelectedCity: {},
      ToUPSSelectedCity: {},
      ToSelectedCity: {},
      FromCity: "",
      ToCity: "",
      flag: 0,
      flag2: 0,
      FromState: "",
      ToState: "",

      PersonID: "",
      UserAccess: "",
      BoxesErrText: "",
      ContactName: "",
      PhoneNumber: "",
      email: "",

      FromZip: "",
      ToZip: "",
      page_redirectionPath: "",

      documentData: [],
      packagetype: "",
      SelectedPakage: [],
      checkSelectedPakage: [],
      finalPackage: [],
      televisionValue: "",
      nextStepValue: null,
      PackageStatus: null,
      previousIndex: null,
      currentIndex: null,
      rowId: null,

      NextButtonIdx: 0,
      IsResidential: 0,
      activeIndex: 0,

      showBoxDetails: false,
      showTVDetails: false,
      showCarDetails: false,
      packDetails: false,
      ShowEnvelop: false,
      Showfurniture: false,

      Loading: false,
      getRatesModule: false,
      fromStateName: "",
      toStateName: "",
      newcountryid: "",
      FromZipCode: "",
      FromZipCodeErr: false,
      FromZipCodeErrText: "",
      FromCityErr: false,
      FromCityErrText: "",
      ToCityErr: false,
      ToCityErrText: "",
      new: "",
      ToZipCode: "",
      ToZipCodeErr: false,
      ToZipCodeHelperText: "",
      TozipCodeError: "",

      tempboxDetails: [],
      boxDetails: [],
      tempTvDetails: [],
      tvDetails: [],
      tempCarDetails: [],
      carDetails: [],

      tempCountryList: [],
      tempCountryCodeList: [],

      finalImage: [],
      tempFinalImage: [],
      erorrmsg: "",

      packgedata: {
        no_pack: [],
        weight: [],
        len: [],
        width: [],
        height: [],
        chargableWeight: [],
        insuredValue: [],
      },
      Steps: [
        {
          stepName: Step1,
          stepId: "shipperdetails", //packagedetails
          classname: "active",
        },
        {
          stepName: Step2,
          stepId: "packagedetails", //shipmentdetails
          classname: "inactive",
        },
        {
          stepName: Step3,
          stepId: "shipmentdetails", //shipperdetails
          classname: "inactive",
        },
        {
          stepName: Step4,
          stepId: "shipsmart", //shipsmart
          classname: "inactive",
        },
      ],
    };
  }

  urlclick() {
    window.location = "https://www.sflworldwide.com/";
  }

  ChangeResidential = (e) => {
    // this.setState({ SelectedResidential: e.target.value });
    // if (e.target.value == "yes") {
    //   this.setState({ IsResidential: true });
    // } else {
    //   this.setState({ IsResidential: false });
    // }
  };

  showHideMenu() {
    let stylediv = document.querySelector("#sidebarCheck").style.display;
    if (stylediv == "none") {
      document.getElementById("sidebarCheck").style.display = "block";
    } else {
      document.getElementById("sidebarCheck").style.display = "none";
    }
  }

  GetSuccessPage() {
    window.location = commonUrl + "SuccessPage";
  }

  CallGetRate() {
    window.location = commonUrl + "GetRate";
  }

  showLoader = () => {
    this.setState({ Loading: true });
  };

  hideLoader = () => {
    this.setState({ Loading: false });
  };

  async GetCountry() {
    try {
      await api
        .get("location/getCountryList")
        .then((res) => {
          if (res.success) {
            var Country = res.data;
            this.state.tempCountryList.push(res.data);
            this.setState({ CountryList: Country });
            // document.getElementById("fromCountrySelect").value = 202;
            var datacountry = -1 
            fetch("https://ipinfo.io/json") 
              .then(response => response.json())
              .then(data => {
                console.log("Datas = ",data.country)
                if(data.country == "IN"){
                  // this.state.PhoneNumberTollFree = "1-800-833-4849"
                  // this.state.hrefPhone = "tel:18008334849"
                  // this.state.indiaornot = "Yes"
                  // localStorage.setItem("forIndia","Yes")
                  datacountry = 89;
                }else{
                  datacountry = 202;
                  
                }
              })
          .catch(error => {
            // console.error("Error fetching IP address:", error);
            datacountry = 202;
            
          });
            this.setState({
              newcountryid: document.getElementById("fromCountrySelect").value,
            });
            document.getElementById("fromCountrySelect").value = datacountry;

            var selectedCountryList = _.filter(Country, { CountryID: datacountry });

            if (selectedCountryList[0].IsZipAvailable === 0) {
              this.setState({ hidefromzipcode: false });
            } else {
              this.setState({ hidefromzipcode: true });
            }
            var SelectedCountry = {
              value: selectedCountryList[0].CountryID,
              label: selectedCountryList[0].CountryName,
            };
            this.setState({
              FromSelectedCountry: SelectedCountry,
            });
            //================== FOR TO COUNTRY =========================
            document.getElementById("toCountrySelect").value = "";
          }
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } catch (error) {}
  }

  countryChange = (e, type) => {
    if (type === "from") {
      this.setState({ FromIsFedxCity: 0, FromSelectedCountry: {} });
      let CountryId = e.target.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ FromIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "from");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "from");
      }
      var FromSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };

      this.setState({ FromSelectedCountry: FromSelectedCountry });
      this.setState({ FromZip: "", FromCity: "", FromFedExSelectedCity: "" });
      document.getElementById("fromzipSelect").value = "";
    }
    if (type === "to") {
      this.setState({ ToIsFedxCity: 0, ToSelectedCountry: {} });
      let CountryId = e.target.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ ToIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "to");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "to");
      } else {
        this.setState({ hidetozipcode: true });
      }
      var ToSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };
      this.setState({ ToSelectedCountry: ToSelectedCountry });
      this.setState({ ToZip: "", ToCity: "", ToFedExSelectedCity: "" });
      // document.getElementById("TozipSelect").value = "";
    }
  };

  handleChange_Value1(type) {
    if (type === "FromFedExCity") {
      let selectedCity = this.state.FromFedExSelectedCity.label;
      if (
        selectedCity == "" ||
        selectedCity == undefined ||
        selectedCity == null
      ) {
        this.setState({
          FromCityErr: true,
          FromCityErrText: "Please select from city",
        });
      } else {
        this.setState({
          FromCityErr: false,
          FromCityErrText: "",
        });
      }
    }

    if (type === "ToFedExCity") {
      let selectedCity = this.state.ToFedExSelectedCity.value;

      if (
        selectedCity == "" ||
        selectedCity == undefined ||
        selectedCity == null
      ) {
        this.setState({
          ToCityErr: true,
          ToCityErrText: "Please select to fedex city",
        });
      } else {
        this.setState({ ToCityErr: false, ToCityErrText: "" });
      }
    }
  }

  getCityList = (value, type) => {
    var CityData = { CityType: "FedEx", CountryId: value };
    if (type === "from") {
      api
        .post("location/getCityList", CityData)
        .then((res) => {
          if (res.success) {
            this.setState({ FromFedExCityList: res.data });
          } else {
            this.setState({ FromFedExCityList: [] });
          }
        })
        .catch((error) => {});
    } else {
      api
        .post("location/getCityList", CityData)
        .then((res) => {
          if (res.success) {
            this.setState({ ToFedExCityList: res.data });
          } else {
            this.setState({ ToFedExCityList: [] });
          }
        })
        .catch((error) => {});
    }
  };

  renderCountryOptions() {
    return this.state.CountryList.map((value) => {
      return <option value={value.CountryID}>{value.CountryName}</option>;
    });
  }


  getIndiaManagedBy(){

    try {
      api
        .get("getQuote/getIndiaManagedBy")
        .then((res) => {
          if (res.success) {
            console.log("Res data = ", res.data[0][0].ManagedBy);
            //this.state.ratestoIndia = res.data[0][0].ManagedBy;
            if(res.data[0][0].ManagedBy == 16){
              this.state.IndiaManagedBy = 12
            }else{
              this.state.IndiaManagedBy = 16
            }
          }
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } catch (error) {}


  }

  getfetcherMaxrate() {
    try {
      api
        .get("getQuote/getFetcherMaxRate")
        .then((res) => {
          if (res.success) {
            console.log("Res data = ", res.data[0][0].Kgend);
            this.state.ratestoIndia = res.data[0][0].Kgend;
          }
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } catch (error) {}
  }

  shipperInfoChange = (event, type) => {
    
    if (type === "ContactName") {
      this.setState({ ContactName: event.target.value });
    } else if (type === "Number") {
      this.setState({ PhoneNumber: event.target.value });
    } else if (type === "Email") {
      this.setState({ email: event.target.value });
    }
    else if (type === "countryCode") {
      console.log("Event = ,", event.target.value)
      // var FromSelectedCountry = {
      //   value: zip.CountryID,
      //   label: zip.CountryName,
      // }
      this.setState({ SelectedOptionCountryCode: event.target.value });
    }
  };

  ChangeFromZipUS = (e) => {
    var zip = e.target.value.trim().replace(/\s/g, "");
    let newzip = zip.trim();
    let modifyzip = newzip.replaceAll(" ", "");
    this.setState({ FromZip: modifyzip.replace(/\s/g, "") });
    if (modifyzip.length) {
      var SelectedCity = { value: null, label: null };
      this.setState({
        FromFedExSelectedCity:
          this.state.FromIsFedxCity === 1
            ? this.state.FromFedExSelectedCity.label
            : "",
        FromUPSSelectedCity: SelectedCity,
        FromSelectedCity: SelectedCity,
      });
      let citydata={
        "PostalCode" : modifyzip,
        "CountryID": this.state.FromSelectedCountry.value
      }
      api
      .post(
        "https://hubapi.sflworldwide.com/contactus/SflPostalCode",
        citydata
      )
      .then((res) => {
        if (res.success) {
          console.log("CheckRessData", res);
          if (res.success === true) {
            var IsValidCountry = false;
           let data = res.Data.data;
           let RecCount = data.length;
        if(RecCount !=0)
          {   var FinalCity = [];
            var state = "";
            var countryShortName = data[0].Country
            this.setState({
              CheckZipCode: "OK",
            });
            for(let i=0;i<RecCount;i++)
              FinalCity.push({
              City_code: data[i].City,
              CityName: data[i].City,
            });
            var SelectedCity =
            FinalCity.length > 0
              ? {
                  value: FinalCity[0].Citycode,
                  label: FinalCity[0].CityName,
                }
              : "";
            this.setState({ FromCityList: FinalCity });
              let fromStatename = "";

              fromStatename = data[0].State;
              state = data[0].State;
              if (this.state.FromSelectedCountry.label === "China") {
                this.setState({
                  FromState: fromStatename,
                  fromStateName: "",
                  FromFedExSelectedCity: SelectedCity,
                });
              } else {
                this.setState({
                  FromState: fromStatename,
                  FromSelectedCity: SelectedCity,
                  FromCity: SelectedCity.label,
                  fromStateName: "",
                });
              }


              
          }
      else
      { var countryName = this.state.FromSelectedCountry.label;
        var countryValue = this.state.FromSelectedCountry.value;
        console.log("IN Postal Else")
        fetch(
        CommonConfig.zipCodeAPIKey(
          modifyzip,
          this.state.FromSelectedCountry.label
        )
      )
        .then((result) => result.json())
        .then((data) => {
          this.setState({
            CheckZipCode: data.status,
          });
          if (data["status"] === "OK") {
            if (
              data["results"][0] &&
              data["results"][0].hasOwnProperty("postcode_localities")
            ) {
              var FinalCity = [];
              var state = "";
              var CityData = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "locality") {
                    return data.types[0] === "locality";
                  }
                }
              );

              var CityData2 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "neighborhood") {
                    return data.types[0] === "neighborhood";
                  }
                }
              );

              var CityData3 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "administrative_area_level_2") {
                    return data.types[0] === "administrative_area_level_2";
                  }
                }
              );

              var CityData4 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "postal_town") {
                    return data.types[0] === "postal_town";
                  }
                }
              );

              var CityData5 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "administrative_area_level_1") {
                    return data.types[0] === "administrative_area_level_1";
                  }
                }
              );

              if (CityData.length > 0) {
                CityData = CityData[0].long_name;
                FinalCity.push({
                  City_code: CityData,
                  Name: CityData,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData2.length > 0) {
                CityData2 = CityData2[0].long_name;
                FinalCity.push({
                  City_code: CityData2,
                  Name: CityData2,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData3.length > 0) {
                CityData3 = CityData3[0].long_name;
                FinalCity.push({
                  City_code: CityData3,
                  Name: CityData3,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData4.length > 0) {
                CityData4 = CityData4[0].long_name;
                FinalCity.push({
                  City_code: CityData4,
                  Name: CityData4,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData5.length > 0) {
                CityData5 = CityData5[0].long_name;
                FinalCity.push({
                  City_code: CityData5,
                  Name: CityData5,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              }

              this.setState({ FromCityList: FinalCity });
              var fromStatename = "";
              if (
                state == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                state = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].short_name;

                fromStatename = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              }
              var city = FinalCity[0].Name;
              var SelectedCity =
                FinalCity.length > 0
                  ? {
                      value: FinalCity[0].City_code,
                      label: FinalCity[0].Name,
                    }
                  : "";

              if (this.state.FromSelectedCountry.label === "China") {
                this.setState({
                  FromState: fromStatename,
                  fromStateName: state,
                  FromFedExSelectedCity: SelectedCity,
                });
              } else {
                this.setState({
                  FromState: fromStatename,
                  FromSelectedCity: SelectedCity,
                  FromCity: SelectedCity.label,
                  fromStateName: state,
                });
              }
              if (FinalCity.length === 0) {
                this.getCityList(this.state.FromSelectedCountry.value, "from");
              }
            } else if (data["results"][0]) {
              var FinalCity = [];
              var city = "";
              var state = "";

              if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_3";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_3";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "political";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "political";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "neighborhood";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "neighborhood";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_2";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_2";
                  }
                )[0].long_name;
              } else if (city == "") {
                city = "";
              }
              var fromStatename = "";
              if (
                state == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                state = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].short_name;

                fromStatename = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              }

              FinalCity.push({
                Citycode: city,
                CityName: city,
              });
              this.setState({
                FromCityList: [
                  {
                    City_code: FinalCity[0].Citycode,
                    Name: FinalCity[0].CityName,
                  },
                ],
                FromState: fromStatename,
                fromStateName: state,
              });

              var SelectedCity =
                FinalCity.length > 0
                  ? {
                      value: FinalCity[0].Citycode,
                      label: FinalCity[0].CityName,
                    }
                  : "";

              if (this.state.FromSelectedCountry.label === "China") {
                this.setState({
                  GetRate: GetRate,
                  FromFedExSelectedCity: SelectedCity,
                });
              } else {
                this.setState({
                  FromSelectedCity: SelectedCity,
                  GetRate: GetRate,
                  FromCity: FinalCity[0].Citycode,
                });
              }
              if (FinalCity.length === 0) {
                this.getCityList(this.state.FromSelectedCountry.value, "from");
              }
            } else {
              this.setState({ FromCityList: [] });
              var GetRate = this.state.GetRate;
              GetRate.FromCity = null;
              GetRate.FromFedExCity = null;
              GetRate.FromUPSCity = null;
              GetRate.FromState = null;
              GetRate.FromZipCode = "";
              this.setState({ GetRate: GetRate });
            }
            this.state.flag = 0;
            this.setState({
              FromZipCodeErr: false,
              FromZipCodeErrText: "",
              FromZipNotFoundErrText: "",
              ThankyouPageMoveFromZip: false,
            });
            if(this.state.FromSelectedCountry.label == "United States" ||this.state.FromSelectedCountry.label == "India" ||this.state.FromSelectedCountry.label == "Canada"  )
              {
                
                var newZipcodedata = {
                "Pincode" : modifyzip,
                "PickupCityList": city,
                "CountryID": countryValue,
                "CountryName": countryName,
                "StateName" : fromStatename,
                
              };
              console.log("newZipcodedata",newZipcodedata);
              api
              .post(
                "https://hubapi.sflworldwide.com/contactus/SflInsertPostalCode",
                newZipcodedata
              )
              .then((res) => {
                if (res.success) {
                  console.log("CheckRessData", res);
                  if (res.success === true) {
                   
                    console.log("New Zipcode Enter Successfully");
                  } else {
                    console.log("Something Went Wrong");
                  }
                }
              })
              .catch((err) => {
                  console.log("err...", err);
                 
                });
              }



          } else {
            if (
              this.state.FromZip.length >= 4 ||
              /[^A-Za-z@.0-9\d]/.test(this.state.FromZip)
            ) {
              if (this.state.FromSelectedCountry.label !== "China") {
                this.setState({
                  FromZipNotFoundErr: false,
                  FromZipNotFoundErrText: "",
                  ThankyouPageMoveFromZip: true,
                });
              }
            }
            if (this.state.FromSelectedCountry.value === 37) {
              if (
                this.state.FromZip.length < 5 ||
                /[^A-Za-z@.0-9\d]/.test(this.state.FromZip)
              ) {
                this.setState({
                  FromZipNotFoundErr: true,
                  FromZipNotFoundErrText:
                    "Please enter the zip code at list 5 character",
                });
              }
            }
            if (this.state.FromSelectedCountry.label !== "China") {
              this.state.flag = 1;
            }
            // this.validate1();
            this.setState({ FromCityList: [] });
          }
        });}
      }
    }
  });
  
    } else if (this.state.GetRate.FromCountry.IsFedexCity === 0) {
      var GetRate = this.state.GetRate;
      GetRate.FromCity = null;
      GetRate.FromFedExCity = null;
      GetRate.FromUPSCity = null;
      GetRate.FromState = null;
      GetRate.FromZipCode = zip;
      this.setState({ GetRate: GetRate });
    } else {
      var GetRate = this.state.GetRate;
      GetRate.FromCity = null;

      GetRate.FromZipCode = zip;
      this.setState({ GetRate: GetRate });
    }
    this.setState({ disableBtn: 1 });
  };

  ChangeToZipUS = (e) => {
    this.setState({ TozipCodeError: e.target.value });

    this.setState({ disableBtn: 0 });
    if (e.target.name === "ToZipCode") {
      if (
        !e.target.value ||
        e.target.value === undefined ||
        e.target.value === null
      ) {
        this.setState({ ToZipError: false });
        this.show("ToZipCode", true, "ToZipError", "Please enter To zipcode");
      } else {
        this.setState({ ToZipError: false });
        this.show("ToZipCode", false, "ToZipError", "");
      }
    }

    var zip = e.target.value.trim().replace(/\s/g, "");
    let newzip = zip;
    let modifyzip = newzip.replaceAll(" ", "");
    this.setState({ ToZip: modifyzip });
    if (modifyzip.length) {
      var SelectedCity = { value: null, label: null };
      this.setState({
        ToFedExSelectedCity:
          this.state.ToIsFedxCity === 1
            ? this.state.ToFedExSelectedCity.value
            : "",
        ToUPSSelectedCity: SelectedCity,
        ToSelectedCity: SelectedCity,
      });
      let citydata={
        "PostalCode" : modifyzip,
        "CountryID": this.state.ToSelectedCountry.value
      }
      api
      .post(
        "https://hubapi.sflworldwide.com/contactus/SflPostalCode",
        citydata
      )
      .then((res) => {
        if (res.success) {
          console.log("CheckRessData", res);
          if (res.success === true) {
            var IsValidCountry = false;
           let data = res.Data.data;
           let RecCount = data.length;
        if(RecCount !=0)
          {   var FinalCity = [];
            var state = "";
            var countryShortName = data[0].Country
            for(let i=0;i<RecCount;i++)
              FinalCity.push({
              City_code: data[i].City,
              CityName: data[i].City,
            });
            var SelectedCity = {
              value: FinalCity[0].City_code,
              label: FinalCity[0].CityName,
            };
            let toStatename = data[0].State;
             state = data[0].State;
            var SelectedCity =
            FinalCity.length > 0
              ? {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].CityName,
                }
              : "";
              if (this.state.ToSelectedCountry.label === "China") {
                this.setState({
                  toStateName: state,
                  ToState: toStatename,
                  ToFedExSelectedCity: SelectedCity,
                });
              } else {
                this.setState({
                  toStateName: state,
                  ToState: toStatename,
                  ToSelectedCity: SelectedCity,
                  ToCity: SelectedCity ? SelectedCity.label : SelectedCity,
                });
              }
              if (FinalCity.length === 0) {
                this.getCityList(this.state.ToSelectedCountry.value, "from");
              }
          }
      else
      {
        var countryName = this.state.ToSelectedCountry.label;
        var countryValue = this.state.ToSelectedCountry.value;
        
        fetch(
        CommonConfig.zipCodeAPIKey(
          modifyzip,
          this.state.ToSelectedCountry.label
        )
      )
        .then((result) => result.json())
        .then((data) => {
          if (data["status"] === "OK") {
            if (
              data["results"][0] &&
              data["results"][0].hasOwnProperty("postcode_localities")
            ) {
              var FinalCity = [];
              var state = "";
              var CityData = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "locality") {
                    return data.types[0] === "locality";
                  }
                }
              );

              var CityData2 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "neighborhood") {
                    return data.types[0] === "neighborhood";
                  }
                }
              );

              var CityData3 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "administrative_area_level_2") {
                    return data.types[0] === "administrative_area_level_2";
                  }
                }
              );

              var CityData4 = _.filter(
                data["results"][0]["address_components"],
                function (data) {
                  if (data.types[0] == "administrative_area_level_1") {
                    return data.types[0] === "administrative_area_level_1";
                  }
                }
              );

              if (CityData.length > 0) {
                CityData = CityData[0].long_name;
                FinalCity.push({
                  City_code: CityData,
                  Name: CityData,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData2.length > 0) {
                CityData2 = CityData2[0].long_name;
                FinalCity.push({
                  City_code: CityData2,
                  Name: CityData2,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData3.length > 0) {
                CityData3 = CityData3[0].long_name;
                FinalCity.push({
                  City_code: CityData3,
                  Name: CityData3,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              } else if (CityData4.length > 0) {
                CityData4 = CityData4[0].long_name;
                FinalCity.push({
                  City_code: CityData4,
                  Name: CityData4,
                });
                var SelectedCity = {
                  value: FinalCity[0].City_code,
                  label: FinalCity[0].Name,
                };
              }

              this.setState({ ToCityList: FinalCity });
              var toStatename = "";
              if (
                state == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                state = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].short_name;

                toStatename = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              }
              var city =FinalCity[0].Name;
              var SelectedCity =
                FinalCity.length > 0
                  ? {
                      value: FinalCity[0].City_code,
                      label: FinalCity[0].Name,
                    }
                  : "";

              if (this.state.ToSelectedCountry.label === "China") {
                this.setState({
                  toStateName: state,
                  ToState: toStatename,
                  ToFedExSelectedCity: SelectedCity,
                });
              } else {
                this.setState({
                  toStateName: state,
                  ToState: toStatename,
                  ToSelectedCity: SelectedCity,
                  ToCity: SelectedCity ? SelectedCity.label : SelectedCity,
                });
              }
              if (FinalCity.length === 0) {
                this.getCityList(this.state.ToSelectedCountry.value, "from");
              }
            } else if (data["results"][0]) {
              var FinalCity = [];
              var city = "";
              var state = "";
              if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_3";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_3";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "political";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "political";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "neighborhood";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "neighborhood";
                  }
                )[0].short_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_2";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_2";
                  }
                )[0].long_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              } else if (
                city == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                ).length > 0
              ) {
                city = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "locality";
                  }
                )[0].long_name;
              } else if (city == "") {
                city = "";
              }

              var toStatename = "";
              if (
                state == "" &&
                _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                ).length > 0
              ) {
                state = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].short_name;
                toStatename = _.filter(
                  data["results"][0]["address_components"],
                  function (data) {
                    return data.types[0] === "administrative_area_level_1";
                  }
                )[0].long_name;
              }

              FinalCity.push({
                Citycode: city,
                CityName: city,
              });

              this.setState({
                ToCityList: [
                  {
                    City_code: FinalCity[0].Citycode,
                    Name: FinalCity[0].CityName,
                  },
                ],
                ToCity: FinalCity[0].Citycode,
                toStateName: state,
                ToState: toStatename,
              });

              var SelectedCity =
                FinalCity.length > 0
                  ? {
                      value: FinalCity[0].Citycode,
                      label: FinalCity[0].CityName,
                    }
                  : "";

              if (this.state.ToSelectedCountry.label === "China") {
                this.setState({ ToFedExSelectedCity: SelectedCity });
              } else {
                this.setState({ ToSelectedCity: SelectedCity });
              }
              if (FinalCity.length === 0) {
                this.getCityList(this.state.ToSelectedCountry.value, "from");
              }
            } else {
              this.setState({ ToCityList: [] });
            }
            this.setState({
              ToZipCodeErr: true,
              ToZipCodeHelperText: "",
              ToZipCodeEnterText: "",
              ToZipErrText: "",
              ToZipErr: false,
              ThankyouPageMoveToZip: false,
            });
            this.state.flag2 = 0;
            if(this.state.ToSelectedCountry.label == "United States" ||this.state.ToSelectedCountry.label == "India" ||this.state.ToSelectedCountry.label == "Canada"  )
              {
                
                var newZipcodedata = {
                "Pincode" : modifyzip,
                "PickupCityList": city,
                "CountryID": countryValue,
                "CountryName": countryName,
                "StateName" : toStatename,
                
              };
              console.log("newZipcodedata",newZipcodedata);
              api
              .post(
                "https://hubapi.sflworldwide.com/contactus/SflInsertPostalCode",
                newZipcodedata
              )
              .then((res) => {
                if (res.success) {
                  console.log("CheckRessData", res);
                  if (res.success === true) {
                   
                    console.log("New Zipcode Enter Successfully");
                  } else {
                    console.log("Something Went Wrong");
                  }
                }
              })
              .catch((err) => {
                  console.log("err...", err);
                 
                });
              }
          } else {
            if (this.state.ToSelectedCountry.label !== "China") {
              this.state.flag2 = 1;
              this.state.ThankyouPageMoveToZip = true;
            }
            if (this.state.ToZip.lenght > 4) {
              cogoToast.error("Zip code not found 2");
            }
            this.setState({ ToCityList: [] });
          }
        });     
      }
    }
  }
});
    } else {
    }
    this.setState({ disableBtn: 1 });
  };

  renderCityOptions = (type) => {
    if (type === "from") {
      return this.state.FromCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    } else {
      return this.state.ToCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    }
  };

  isResidenceChange = (event) => {
    if (event.target.checked) {
      this.setState({ IsResidential: 1 });
    } else {
      this.setState({ IsResidential: 0 });
    }
  };
  renderFedxCityOptions = (type) => {
    if (type === "from") {
      return this.state.FromFedExCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    } else {
      return this.state.ToFedExCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    }
  };

  ChangeFromCity = (event, type) => {
    if (type === "FedEx") {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        this.setState({ FromFedExSelectedCity: "" });
        this.setState({ FromFedExSelectedCity: event });
      }
    } else if (type === "FedEx1") {
      this.setState({ FromFedExCityValue: event });

      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        this.setState({ FromFedExSelectedCity: "" });
        var fromcity = {
          value: event.value,
          label: event.label,
        };
        this.setState({ FromFedExSelectedCity: fromcity });
      }
    } else if (type === "UPS") {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        let fromcity = {
          value: event.target.value,
          label: event.target.value,
        };
        this.setState({ FromUPSSelectedCity: fromcity });
      }
    } else {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        let fromcity = {
          value: event.target.value,
          label: event.target.value,
        };
        this.setState({ FromSelectedCity: fromcity });
      }
    }
  };

  ChangeToCity = (event, type) => {
    if (type === "FedEx") {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        this.setState({ ToFedExSelectedCity: "" });
        this.setState({ ToFedExSelectedCity: event });
      }
    } else if (type === "FedEx1") {
      this.setState({ ToFedExCityValue: event });
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        this.setState({ ToFedExSelectedCity: "" });
        let tocity = {
          value: event.value,
          label: event.label,
        };
        this.setState({ ToFedExSelectedCity: tocity });
      }
    } else if (type === "UPS") {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        let tocity = {
          value: event.target.value,
          label: event.target.value,
        };
        this.setState({ ToUPSSelectedCity: tocity });
      }
    } else {
      if (CommonConfig.isEmpty(event)) {
        return null;
      } else {
        let tocity = {
          value: event.target.value,
          label: event.target.value,
        };
        this.setState({ ToSelectedCity: tocity });
      }
    }
  };
  shipperInfoChange = (event, type) => {
    
    if (type === "ContactName") {
      this.setState({ ContactName: event.target.value });
    } else if (type === "Number") {
      this.setState({ PhoneNumber: event.target.value });
    } else if (type === "Email") {
      this.setState({ email: event.target.value });
    }else if (type === "countryCode") {
      var FromSelectedCountryCode = {
        value: event.label,
        label: event.label,
      }
      console.log("event.label = ",event.label)
      this.setState({ SelectedOptionCountryCode: FromSelectedCountryCode });
    }
  };
  validateShipperInfo = (evt, type) => {
    if (type == "cname") {
      if (this.state.ContactName != "") {
        if (this.state.ContactName.trim().length < 3) {
          stepValidate = false;
          allValid = false;
          this.setState({
            cnameErrText: "Please enter atleast 3 character",
          });
        } else {
          if (this.state.PhoneNumber != "" && this.state.email != "") {
            stepValidate = true;
            allValid = true;
          } else {
            stepValidate = false;
            allValid = false;
          }
        }
      } else {
        stepValidate = false;
        allValid = false;
        this.setState({
          cnameErrText: "",
        });
      }
    } else if (type === "phone") {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        this.setState({
          onlyNumberErrText: "Enter only digits",
        });
        stepValidate = false;
        allValid = false;
        return false;
      } else if (this.state.email != "") {
        stepValidate = true;
        allValid = true;
        return true;
      } else {
        stepValidate = false;
        allValid = false;
      }
    } else if (type === "phoneblur") {
      var phonenoRegex = /^\d{10}$/;
      if (this.state.PhoneNumber != "") {
        if (
          this.state.PhoneNumber.length < 10 ||
          !phonenoRegex.test(this.state.PhoneNumber)
        ) {
          this.setState({
            contactErrText: "Please enter valid phone number",
          });
          stepValidate = false;
          allValid = false;
        } else if (this.state.email != "") {
          stepValidate = true;
          allValid = true;
        } else {
          stepValidate = false;
          allValid = false;
        }
      } else {
        stepValidate = false;
        allValid = false;
        this.setState({
          contactErrText: "Please enter valid phone number",
        });
      }
    } else if (type === "email") {
      ;
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email != "") {
        if (!emailRegex.test(this.state.email)) {
          this.setState({
            EmailErrText: "Please enter valid email",
          });
          stepValidate = false;
          allValid = false;
        } else {
          stepValidate = true;
          allValid = true;
        }
      } else {
        stepValidate = false;
        allValid = false;
      }
    }
  };

  addBoxes = () => {
    this.setState({ submitList: this.state.allpackagesValue });
    this.setState((prevState) => ({
      allpackagesValue: [
        ...prevState.allpackagesValue,
        {
          ActualWeight: "",
          ChargeableWeight: "0",
          DimensionH: "",
          DimensionL: "",
          DimensionW: "",
          Quantity: "1",
          PackageType: "1",
          Status: "Active",
        },
      ],
    }));
  };

  removeBoxes = (i) => {
    let allpackagesValue = [...this.state.allpackagesValue];
    allpackagesValue.splice(i, 1);
    this.setState({ allpackagesValue });
  };

  getBoxesData = (i, e) => {
    this.setState({ getWidthvalue: e.target });
    const { name, value } = e.target;
    let allpackagesValue = [...this.state.allpackagesValue];
    allpackagesValue[i] = { ...allpackagesValue[i], [name]: value };
    this.setState({ allpackagesValue });
  };

  getTelevisionData = (i, e) => {
    const { name, value } = e.target;
    let allTelevisionValue = [...this.state.allTelevisionValue];
    allTelevisionValue[i] = { ...allTelevisionValue[i], [name]: value };
    this.setState({ allTelevisionValue });
  };

  addTelevision = () => {
    this.setState((prevState) => ({
      allTelevisionValue: [
        ...prevState.allTelevisionValue,
        {
          ActualWeight: "",
          ChargeableWeight: "0",
          DimensionH: "",
          DimensionL: "",
          DimensionW: "",
          Quantity: "1",
          PackageType: "4",
          Status: "Active",
          TVMake: "",
          TVModel: "",
        },
      ],
    }));
  };

  removeTelevision = (i) => {
    let allTelevisionValue = [...this.state.allTelevisionValue];
    allTelevisionValue.splice(i, 1);
    this.setState({ allTelevisionValue });
  };

  addAutoData = (i, e) => {
    const { name, value } = e.target;
    let allAutoValue = [...this.state.allAutoValue];
    allAutoValue[i] = { ...allAutoValue[i], [name]: value };
    this.setState({ allAutoValue });
  };

  addAuto = () => {
    this.setState((prevState) => ({
      allAutoValue: [
        ...prevState.allAutoValue,
        {
          CarMake: "",
          CarModel: "",
          CarYear: "",
          PackageType: "5",
          Status: "Active",
        },
      ],
    }));
  };
  removeAuto = (i) => {
    let allAutoValue = [...this.state.allAutoValue];
    allAutoValue.splice(i, 1);
    this.setState({ allAutoValue });
  };

  boxesPackagesDetails() {
    var nopack = [];
    var temp = document.getElementsByName("BoxDetails[No_Pack]");
    var boxarray = [].concat.apply(nopack, temp);
    var No_packBoxDetails = boxarray.map((x) => {
      return x.value;
    });
    var weightpack = [];
    var temp = document.getElementsByName("BoxDetails[Weight]");
    var boxweightarray = [].concat.apply(weightpack, temp);
    var WeightBoxDetails = boxweightarray.map((x) => {
      return x.value;
    });
    this.setState({
      WeightBoxDetailsValue: WeightBoxDetails[0],
    });
  }

  async componentDidMount() {
    localStorage.setItem("BaseUrlData",BaseUrlData)
    localStorage.setItem("forIndia","No")
    // var data = {
    //   // 'Accept': 'application/json',
    //   "md": "E",
    //   "cgm": "500",
    //   "ss": "Delivered",
    //   "o_pin": "312001",
    //   "d_pin": "311001",

    // };
    var headers = {
      // 'Accept': 'application/json',
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    };
    var url ="https://staging-express.delhivery.com/api/kinko/v1/invoice/charges/.json?";
    var method = "get";
    const insta = axios.create({
      baseURL: apiBase,
      timeout: 60000,
      responseType: "json",
    });
   

// return insta.request({
//   url,
//   method,
//   params: data,
//   headers: headers,
// });


    //axios.get('https://staging-express.delhivery.com/api/kinko/v1/invoice/charges/.json?parameter')
    //this.getcarYear();
    this.renderCountryOptions();
    this.getfetcherMaxrate();
    this.getIndiaManagedBy();
    let dummyTxt = "1234567890123456";

    let joy = dummyTxt.match(/.{1,4}/g);
    this.state.new = joy.join(" ");
    await this.GetCountry();
    await this.GetCountryCode();

    if (localStorage.getItem("loggedInUserData")) {
      var dataTest = JSON.parse(localStorage.getItem("loggedInUserData"));
      this.setState({
        PersonID: CommonConfig.loggedInUserData().PersonID,
        userName: CommonConfig.loggedInUserData().Name,
        UserAccess: dataTest.userModuleAccess[9].WriteAccess,
      });
    }
    var queryString = new Array();
    if (queryString.length == 0) {
      if (window.location.search.split("?").length > 1) {
        var params2 = window.location.search.split("?")[1].split("&");
        for (var i = 0; i < params2.length; i++) {
          var key = params2[i].split("=")[0];
          var value = decodeURIComponent(params2[i].split("=")[1]);
          queryString[key] = value;
        }
      }
    }

    if(queryString["dataSet"] != undefined || queryString["dataSet"] != null){
      var dataset = queryString["dataSet"];

      this.state.premeta = dataset;

    }

    if(queryString["utm_id"] != undefined || queryString["utm_id"] != null){
      var dataset = queryString["utm_id"];

      this.state.premeta = dataset;

    }

    if (queryString["package"] != null) {
      var packageDetailsArr = queryString["package"].split(",");

      for (var i = 0; i < packageDetailsArr.length; i++) {
        var packageName = "";
        if (packageDetailsArr[i] == "2") {
          packageName = "envelope";
        } else if (packageDetailsArr[i] == "1") {
          packageName = "box";
        } else if (packageDetailsArr[i] == "3") {
          packageName = "furniture";
        } else if (packageDetailsArr[i] == "4") {
          packageName = "television";
        } else if (packageDetailsArr[i] == "5") {
          packageName = "auto";
        }
        this.state.packdetailsChage = packageDetailsArr[i];
        this.state.packtypeDetail = packageName;
        this.packageDetailsChange("", packageDetailsArr[i], packageName);
      }
    }
    if (queryString["fullname"] != null && queryString["email"] != null) {
      var blogName = queryString["fullname"];
      var blogEmail = queryString["email"];
      var blogPhone = queryString["phone"];

      document.getElementById("ContactName").value = blogName;
      document.getElementById("Number").value = blogPhone;
      document.getElementById("email").value = blogEmail;

      this.setState({
        ContactName: blogName,
        email: blogEmail,
        PhoneNumber: blogPhone,
      });
    }
    setTimeout(() => {
      var from_country_home = parseInt(queryString["fromcountry"]);
      var to_country_home = parseInt(queryString["tocountry"]);
      if(from_country_home == 89){
        this.state.PhoneNumberTollFree = "1-800-833-4849"
        this.state.hrefPhone = "tel:18008334849"
        this.state.indiaornot = "Yes"
        localStorage.setItem("forIndia","Yes")
        var FromSelectedCountryCode = {
          value: "India(+91)",
          label: "India(+91)",
        }
        this.state.SelectedWeightType = "KG/CM";
        this.state.SelectedDimensitionType = "CM";
        // console.log("event.label = ",event.label)
        this.setState({ SelectedOptionCountryCode: FromSelectedCountryCode });
        BaseUrlData = "https://www.sflworldwide.com/en-in/"
        localStorage.setItem("BaseUrlData",BaseUrlData)
      }
      this.state.page_redirectionPath = queryString["pagePath"];

      if (
        this.state.page_redirectionPath == undefined ||
        this.state.page_redirectionPath == ""
      ) {
        this.setState({ page_redirectionPath: "rates.sflworldwide.com" });
      } else {
        this.setState({
          page_redirectionPath: this.state.page_redirectionPath,
        });
      }

      if (
        from_country_home != undefined &&
        from_country_home != "" &&
        !isNaN(from_country_home)
      ) {
        var selectedCountryList = _.filter(this.state.tempCountryList[0], {
          CountryID: from_country_home,
        });

        var SelectedCountry = {
          value: selectedCountryList[0].CountryID,
          label: selectedCountryList[0].CountryName,
        };

        this.state.USSelectedOption.label = selectedCountryList[0].CountryName;
        this.state.USSelectedOption.value = selectedCountryList[0].CountryID;

        this.setState({
          FromSelectedCountry: SelectedCountry,
          FromIsFedxCity: selectedCountryList[0].IsFedexCity,
        });

        if (selectedCountryList[0].IsZipAvailable === 0) {
          this.setState({ hidefromzipcode: false });
        } else {
          this.setState({ hidefromzipcode: true });
        }
        this.getCityList(selectedCountryList[0].CountryID, "from");
        document.getElementById("fromCountrySelect").value =
          selectedCountryList[0].CountryID;
      }else{

        fetch("https://ipinfo.io/json") 
          .then(response => response.json())
          .then(data => {
            console.log("Datas = ",data.country)
            if(data.country == "IN"){
              // document.getElementById("fromCountrySelect").value = 89;
              from_country_home = 89
              var FromSelectedCountryCode = {
                value: "India(+91)",
                label: "India(+91)",
              }
              // this.state.PhoneNumberTollFree = "1-800-833-4849"
              // this.state.hrefPhone = "tel:18008334849"
              // this.state.indiaornot = "Yes"
              // localStorage.setItem("forIndia","Yes")
              // console.log("event.label = ",event.label)
              this.setState({ SelectedOptionCountryCode: FromSelectedCountryCode });
            }else{
              from_country_home = 202
              // document.getElementById("fromCountrySelect").value = 202;
              
            }

            var selectedCountryList = _.filter(this.state.tempCountryList[0], {
              CountryID: from_country_home,
            });
    
            var SelectedCountry = {
              value: selectedCountryList[0].CountryID,
              label: selectedCountryList[0].CountryName,
            };
    
            this.state.USSelectedOption.label = selectedCountryList[0].CountryName;
            this.state.USSelectedOption.value = selectedCountryList[0].CountryID;
    
            this.setState({
              FromSelectedCountry: SelectedCountry,
              FromIsFedxCity: selectedCountryList[0].IsFedexCity,
            });
    
            if (selectedCountryList[0].IsZipAvailable === 0) {
              this.setState({ hidefromzipcode: false });
            } else {
              this.setState({ hidefromzipcode: true });
            }
            this.getCityList(selectedCountryList[0].CountryID, "from");
            document.getElementById("fromCountrySelect").value =
              selectedCountryList[0].CountryID;
    
          })
          .catch(error => {
            // console.error("Error fetching IP address:", error);
            document.getElementById("fromCountrySelect").value = 202;
            
          });


       
      }
      if (
        from_country_home != undefined &&
        from_country_home != "" &&
        !isNaN(from_country_home)
      ) {
        document.getElementById("fromCountrySelect").value =
          selectedCountryList[0].CountryID;
      } else {
        
        
      }

      if (
        to_country_home != undefined &&
        to_country_home != "" &&
        !isNaN(to_country_home)
      ) {
        var selectedToCountryList = _.filter(this.state.tempCountryList[0], {
          CountryID: to_country_home,
        });

        var SelectedToCountry = {
          value: selectedToCountryList[0].CountryID,
          label: selectedToCountryList[0].CountryName,
        };

        this.setState({
          ToSelectedCountry: SelectedToCountry,
          ToIsFedxCity: selectedToCountryList[0].IsFedexCity,
        });

        if (selectedToCountryList[0].IsZipAvailable === 0) {
          this.setState({ hidetozipcode: false });
        } else {
          this.setState({ hidetozipcode: true });
        }

        this.getCityList(selectedToCountryList[0].CountryID, "to");
        document.getElementById("toCountrySelect").value =
          selectedToCountryList[0].CountryID;
      }

      this.state.tempboxDetails.push(
        <div className="cst-row" id="rows">
          <div className="input-outer col-1">
            <label>No. of Boxes</label>
            <select name="BoxDetails[No_Pack]" id="no_pack0">
              {this.getNumberofBox()}
            </select>
          </div>
          <div className="input-outer col-1">
            <div className="gt-input">
              <label>Weight</label>

              <div className="ipt-with-icon">
                <input
                  type="text"
                  maxLength={3}
                  name="BoxDetails[Weight]"
                  id="weight_pack0"
                  autoComplete="off"
                  placeholder="Weight"
                  onChange={() =>
                    this.calculatechWeightonchange("0", "weight_pack0")
                  }
                  onBlur={() => this.calculatechWeight("0", "box")}
                  onFocus={() =>
                    this.setState({
                      BoxesErrText: "",
                    })
                  }
                ></input>
                {/* <span>{this.state.preSelected}</span> */}
              </div>
            </div>
          </div>
          <div className="input-outer col-3">
            <label>Dimensions</label>
            <div className="input-inner first">
              <input
                type="text"
                maxLength={3}
                name="BoxDetails[Length]"
                id="length0"
                autoComplete="off"
                placeholder="Length"
                onChange={() => this.calculatechWeightonchange("0", "length0")}
                onBlur={() => this.calculatechWeight("0", "box")}
                onFocus={() =>
                  this.setState({
                    BoxesErrText: "",
                  })
                }
              ></input>
              <span>X</span>
            </div>
            <div className="input-inner">
              <input
                type="text"
                maxLength={3}
                name="BoxDetails[Width]"
                id="width0"
                autoComplete="off"
                onChange={() => this.calculatechWeightonchange("0", "width0")}
                onBlur={() => this.calculatechWeight("0", "box")}
                placeholder="Width"
                onFocus={() =>
                  this.setState({
                    BoxesErrText: "",
                  })
                }
              ></input>
              <span>X</span>
            </div>
            <div className="input-inner last">
              <input
                type="text"
                maxLength={3}
                name="BoxDetails[Height]"
                id="height0"
                autoComplete="off"
                placeholder="Height"
                onChange={() => this.calculatechWeightonchange("0", "height0")}
                onBlur={() => this.calculatechWeight("0", "box")}
                onFocus={() =>
                  this.setState({
                    BoxesErrText: "",
                  })
                }
              ></input>
              {/* <span>X</span> */}
            </div>
          </div>
          <div className="input-outer col-1">
            <label>Chargable Weight</label>
            <div className="ipt-with-info">
              <input
                type="text"
                maxLength={3}
                readOnly
                name="BoxDetails[chargableWeight]"
                id="chargable0"
                placeholder="Weight"
                onFocus={() =>
                  this.setState({
                    BoxesErrText: "",
                  })
                }
              ></input>
              <a
                href="https://www.sflworldwide.com/resources/volumetric-weight"
                target="_blank"
                className="cw-info-icon"
              >
                <img src={infoIcon}></img>
              </a>
              {/* <span>{this.state.preSelected}</span> */}
            </div>
          </div>
          <div className="button-outer col-50">
            <a
              className=" plus-btn first-add-btn"
              id="add_new_box0"
              onClick={() => this.addRowPackage("rows", "0", "Box")}
            >
              <img className="disktop" src={plusIcon}></img>
              <img className="mobile-add-icon" src={mobileAddBtn}></img>
            </a>
            {/* <a href="#" className="delete-btn">
                <img className="disktop" src={removeIcon}></img>
                <img className="mobile-delete-icon" src={mobileDelet}></img>
              </a> */}
          </div>
        </div>
        // </div>
      );
      this.state.tempTvDetails.push(
        // <div className="bx-wrap" >
        <div className="cst-row" id="rowsteli">
          <div className="input-outer col-1">
            <label>Brand Name</label>
            <input
              type="text"
              name="TelevisionDetails[Make]"
              id="no_packteli0"
              autoComplete="off"
              placeholder="Brand Name"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
          </div>

          <div className="input-outer col-1">
            <label>Model</label>
            <input
              type="text"
              name="TelevisionDetails[Model]"
              id="model_teli0"
              autoComplete="off"
              placeholder="TV Model"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
          </div>

          <div className="input-outer col-1">
            <label>Weight</label>
            <div className="ipt-with-icon">
              <input
                type="text"
                maxLength={3}
                name="TelevisionDetails[Weight]"
                id="wight_packteli0"
                autoComplete="off"
                onChange={() =>
                  this.calculatechWeightonchange("0", "wight_packteli0")
                }
                onBlur={() => this.calculatechWeight("0", "tv")}
                placeholder="Weight"
                onFocus={() =>
                  this.setState({
                    TelevisionErrText: "",
                  })
                }
              ></input>
              {/* <span>lbs</span> */}
            </div>
          </div>

          <div className="input-outer col-3 tvweight">
            <label>Dimensions</label>
            <div className="input-inner first">
              <input
                type="text"
                maxLength={3}
                name="TelevisionDetails[Length]"
                id="lengthteli0"
                onChange={() =>
                  this.calculatechWeightonchange("0", "lengthteli0")
                }
                onBlur={() => this.calculatechWeight("0", "tv")}
                autoComplete="off"
                placeholder="Length"
                onFocus={() =>
                  this.setState({
                    TelevisionErrText: "",
                  })
                }
              ></input>
              {/* <span>in</span> */}
              <span>X</span>
            </div>
            <div className="input-inner">
              <input
                type="text"
                maxLength={3}
                name="TelevisionDetails[Width]"
                id="widthteli0"
                onChange={() =>
                  this.calculatechWeightonchange("0", "widthteli0")
                }
                onBlur={() => this.calculatechWeight("0", "tv")}
                autoComplete="off"
                placeholder="Width"
                onFocus={() =>
                  this.setState({
                    TelevisionErrText: "",
                  })
                }
              ></input>
              {/* <span>in</span> */}
              <span>X</span>
            </div>
            <div className="input-inner last">
              <input
                type="text"
                maxLength={3}
                name="TelevisionDetails[Height]"
                id="heightteli0"
                onChange={() =>
                  this.calculatechWeightonchange("0", "heightteli0")
                }
                onBlur={() => this.calculatechWeight("0", "tv")}
                autoComplete="off"
                placeholder="Height"
                onFocus={() =>
                  this.setState({
                    TelevisionErrText: "",
                  })
                }
              ></input>
              {/* <span>in</span> */}
            </div>
          </div>
          <div className="input-outer col-1">
            <label>Chargable Weight</label>
            <div className="ipt-with-icon">
              <input
                type="text"
                maxLength={3}
                name="TelevisionDetails[chargableWeight]"
                id="chargableWeighttv0"
                autoComplete="off"
                placeholder="Weight"
                onFocus={() =>
                  this.setState({
                    TelevisionErrText: "",
                  })
                }
              ></input>
              <a
                href="https://www.sflworldwide.com/resources/volumetric-weight"
                target="_blank"
                className="cw-info-icon"
              >
                <img src={infoIcon}></img>
              </a>
              {/* <span>lbs</span> */}
            </div>
          </div>
          <div className="button-outer col-50 tvplus">
            <a
              onClick={() => this.addRowTV("rowsteli", "0", "Tele")}
              id="add_new_tv0"
            >
              <img src={plusIcon}></img>
            </a>
          </div>
        </div>
        // </div>
      );
      this.state.tempCarDetails.push(
        <div className="bx-wrap">
          <div className="cst-row" id="rowscar">
            <div className="input-outer col-2">
              <label>Brand Name</label>
              <input
                type="text"
                name="CarDetails[Make]"
                id="car_make0"
                autoComplete="off"
                placeholder="Car Make"
                onFocus={() =>
                  this.setState({
                    CarErrText: "",
                  })
                }
              ></input>
              <FormHelperText style={{ color: "red" }}>
                {this.state.CarErrText}
              </FormHelperText>
            </div>

            <div className="input-outer col-5">
              <label>Car Model</label>
              <input
                type="text"
                name="CarDetails[Model]"
                id="car_model0"
                autoComplete="off"
                placeholder="Car Model"
                onFocus={() =>
                  this.setState({
                    CarErrText: "",
                  })
                }
              ></input>
              <FormHelperText style={{ color: "red" }}>
                {this.state.CarErrText}
              </FormHelperText>
            </div>

            <div className="input-outer col-1">
              <label>Car Year</label>

              <select
                name="CarDetails[Year]"
                id="car_year0"
                onFocus={() =>
                  this.setState({
                    CarErrText: "",
                  })
                }
              >
                {this.getcarYear()}
              </select>
              <FormHelperText style={{ color: "red" }}>
                {this.state.CarErrText}
              </FormHelperText>
            </div>
            <div className="button-outer col-50">
              <a
                className="plus-btn"
                id="add_new_car0"
                onClick={() => this.addCarData("rowscar0", "0", "Car")}
              >
                <img src={plusIcon}></img>
              </a>
            </div>
          </div>
        </div>
      );
      this.setState({
        boxDetails: this.state.tempboxDetails,
        tvDetails: this.state.tempTvDetails,
        carDetails: this.state.tempCarDetails,
      });
    }, 10);
    var noOfBox = [];
    for (var i = 1; i <= 25; i++) {
      let mainBoxObject = {};
      mainBoxObject.label = i;
      mainBoxObject.value = i;
      noOfBox.push(mainBoxObject);
      this.setState({ noOfBoxinPackeges: noOfBox });
    }
  }
  calculatechWeightonchange = (index, type) => {
    if (type == "weight_pack0") {
      var regex = /[.,\s]/g;

      var inputString = document.getElementById("weight_pack" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("weight_pack" + index).value = result;
        var weight_pack = result;
      } else {
        document.getElementById("weight_pack" + index).value = "";
      }
    } else if (type == "length0") {
      var inputString = document.getElementById("length" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("length" + index).value = result;
        var length = result;
      } else {
        document.getElementById("length" + index).value = "";
      }
    } else if (type == "height0") {
      var inputString = document.getElementById("height" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("height" + index).value = result;
        var height = result;
      } else {
        document.getElementById("height" + index).value = "";
      }
    } else if (type == "width0") {
      var inputString = document.getElementById("width" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("width" + index).value = result;
        var width = result;
      } else {
        document.getElementById("width" + index).value = "";
      }
    } else if (type == "heightteli0") {
      var inputString = document.getElementById("heightteli" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("heightteli" + index).value = result;
        var heightteli = result;
      } else {
        document.getElementById("heightteli" + index).value = "";
      }
    } else if (type == "widthteli0") {
      var inputString = document.getElementById("widthteli" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("widthteli" + index).value = result;
        var widthteli = result;
      } else {
        document.getElementById("widthteli" + index).value = "";
      }
    } else if (type == "lengthteli0") {
      var inputString = document.getElementById("lengthteli" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("lengthteli" + index).value = result;
        var lengthteli = result;
      } else {
        document.getElementById("lengthteli" + index).value = "";
      }
    } else if (type == "wight_packteli0") {
      var inputString = document.getElementById("wight_packteli" + index).value;
      var result = parseInt(inputString, 10);
      if (!isNaN(result)) {
        document.getElementById("wight_packteli" + index).value = result;
        var wight_packteli = result;
      } else {
        document.getElementById("wight_packteli" + index).value = "";
      }
    }
  };
  calculatechWeight = (index, type) => {
    if (type == "box") {
      var regex = /[.,\s]/g;

      var inputString = document.getElementById("weight_pack" + index).value;
      var result = inputString.replace(regex, "");
      document.getElementById("weight_pack" + index).value = result;
      var weight_pack = result;
      var length = document.getElementById("length" + index).value;
      var width = document.getElementById("width" + index).value;
      var height = document.getElementById("height" + index).value;
      var no_pack = document.getElementById("no_pack" + index).value;

      var TotalCh = 0;

      var totalW = weight_pack * no_pack;
      var TotalCHW;
      var divValue = 139;

      if (this.state.SelectedWeightType == "KG/CM") {
        divValue = 5000;
      } else {
        divValue = 139;
      }

      TotalCh =
        Math.ceil(parseFloat((width * length * height) / divValue)) *
        Number(no_pack);

      // console.log("Wights = ", totalW , " | totach = ", TotalCh)

      if (totalW > TotalCh) {
        TotalCHW = totalW;
      } else {
        TotalCHW = TotalCh;
      }
      document.getElementById("chargable" + index).value = TotalCHW;
    } else {
      var weight_pack = parseInt(
        document.getElementById("wight_packteli" + index).value
      );
      var length = document.getElementById("lengthteli" + index).value;
      var width = document.getElementById("widthteli" + index).value;
      var height = document.getElementById("heightteli" + index).value;
      // var no_pack = document.getElementById("no_pack" + index).value;
      var TotalCh = 0;

      var totalW = weight_pack;
      var TotalCHW;
      var divValue = 139;

      if (this.state.SelectedWeightType == "KG/CM") {
        divValue = 5000;
      } else {
        divValue = 139;
      }

      TotalCh = Math.ceil(parseFloat((width * length * height) / divValue));

      // console.log("Wights = ", totalW , " | totach = ", TotalCh)

      if (totalW > TotalCh) {
        TotalCHW = totalW;
      } else {
        TotalCHW = TotalCh;
      }
      document.getElementById("chargableWeighttv" + index).value = TotalCHW;
    }
  };

  backStep = () => {
    var AllPackegeValue = [];

    if (this.state.showBoxDetails === true) {
      AllPackegeValue.push(...this.state.allpackagesValue);

      var BoxesStepValue = this.state.allpackagesValue[0]?.PackageType;
    }
    if (this.state.showCarDetails === true) {
      AllPackegeValue.push(...this.state.allAutoValue);

      var AutoStepValue = this.state.allAutoValue[0]?.PackageType;
    }
    if (this.state.showTVDetails === true) {
      AllPackegeValue.push(...this.state.allTelevisionValue);

      var TelevisionStepValue = this.state.allTelevisionValue[0]?.PackageType;
    }
    if (this.state.ShowEnvelop === true) {
      AllPackegeValue.push(...this.state.EnvelopValue);

      var EnvelopStepValue = this.state.EnvelopValue[0]?.PackageType;
    }
    if (this.state.Showfurniture === true) {
      AllPackegeValue.push(...this.state.AllfurnitureValue);

      var FurnitureStepValue = this.state.AllfurnitureValue[0]?.PackageType;
    }
    if (AutoStepValue === "5" && FurnitureStepValue === "3") {
      if ((document.getElementById("step3").className = "active")) {
        document.getElementById("step3").className = "active";
        document.getElementById("step4").className = "";
        document.getElementById("shipperdetails").style.display = "block";
        document.getElementById("shipsmart").style.display = "none";
        this.setState({
          SetMultiStep: "Active",
        });
      }
      if (this.state.SetMultiStep === "Active") {
        window.location.reload();
      }
    } else {
      if (
        this.state.SelectedPakage === "box" ||
        this.state.SelectedPakage === "television" ||
        this.state.SelectedPakage === "auto"
      ) {
        if (document.getElementById("step4").className === "active") {
          document.getElementById("step3").className = "active";
          document.getElementById("step4").className = "";
          document.getElementById("step2").className = "";
          document.getElementById("shipperdetails").style.display = "block";
          document.getElementById("shipsmart").style.display = "none";
          this.setState({
            LastStatus: "",
          });
        } else if (document.getElementById("step3").className === "active") {
          document.getElementById("step2").className = "active";
          document.getElementById("step3").className = "";
          document.getElementById("shipmentdetails").style.display = "block";
          document.getElementById("shipperdetails").style.display = "none";
          this.setState({
            StepThirdtoSecond: "Active",
          });
        } else if (document.getElementById("step3").className === "active") {
          document.getElementById("step2").className = "active";
          document.getElementById("step3").className = "";
          document.getElementById("shipmentdetails").style.display = "block";
          document.getElementById("shipperdetails").style.display = "none";
        } else if (document.getElementById("step2").className === "active") {
          document.getElementById("step1").className = "active";
          window.location.reload();
        }
      }

      if (
        this.state.SelectedPakage === "envelope" ||
        this.state.SelectedPakage === "furniture"
      ) {
        if (this.state.EnvelopeStep4TO3 === "Active") {
        } else if ((document.getElementById("step4").className = "active")) {
          this.setState({
            FirstStep: "Active",
          });
          this.setState({
            SetMultiStep: "",
          });
          this.setState({
            LocationStatus: "",
          });
          document.getElementById("step3").className = "active";
          document.getElementById("step4").className = "";
          document.getElementById("shipperdetails").style.display = "block";
          document.getElementById("shipsmart").style.display = "none";
        } else if ((document.getElementById("step3").className = "active")) {
          document.getElementById("step2").className = "active";
          document.getElementById("step3").className = "";
          document.getElementById("shipmentdetails").style.display = "block";
          document.getElementById("shipperdetails").style.display = "none";
        }

        if (this.state.FirstStep === "Active") {
          document.getElementById("step1").className = "active";
          window.location.reload();
        }
      }
    }
  };

  navigateChangeprevious = (key) => {
    let stepsList = this.state.Steps;
    let activeIndex = stepsList.findIndex((x) => x.classname === "active");
    this.setState({ activeIndex: activeIndex });
    if (
      activeIndex == 1 &&
      this.state.Steps[activeIndex].stepId == "packagedetails"
    ) {
      document.getElementById("step3").className = "active";
      document.getElementById("step1").className = "";
    } else if (
      activeIndex == 2 &&
      this.state.Steps[activeIndex].stepId == "shipmentdetails"
    ) {
      document.getElementById("step1").className = "active";
      document.getElementById("step2").className = "";
    } else if (
      activeIndex == 3 &&
      this.state.Steps[activeIndex].stepId == "shipsmart"
    ) {
      if (this.state.ShowEnvelop == true || this.state.showfurniture == true) {
        var elementdivs = document.getElementById("nave");
        elementdivs.classList.remove("three-boxes");
        this.state.stepChanges = 0;
        key = key - 1;
        document.getElementById("step1").className = "active";
        document.getElementById("step4").className = "";
      } else {
        if (key == 2) {
        } else {
          key = key - 1;
        }

        this.state.stepChanges = 0;
        document.getElementById("step2").className = "active";
        document.getElementById("step4").className = "";
      }
    }
    if (activeIndex != 0) {
      if (key !== activeIndex) {
        this.setState({ NextButtonIdx: key });
        stepsList[key]["classname"] = "active";
        stepsList[activeIndex]["classname"] = "inactive";
        this.setState({ Steps: stepsList });
        let divID = stepsList[key]["stepId"];
        let activeDiv = stepsList[activeIndex]["stepId"];
        document.getElementById(divID).style.display = "block";
        document.getElementById(activeDiv).style.display = "none";
      }
    }
  };

  renderTvDetails = () => {
    return this.state.tvDetails.map((x) => {
      return x;
    });
  };
  removeboxpackage = (rowID, Index, type) => {
    this.setState({ BoxesErrText: "" });
    var packidx = this.state.tempboxDetails.findIndex(
      (x) => x.props.id === rowID
    );
    this.state.tempboxDetails.splice(packidx, 1);
    this.setState({ boxDetails: this.state.tempboxDetails });
    var preIdx = Index - 1;
    var backidx = "add_new_box" + preIdx;
    document.getElementById(backidx).style.display = "block";
  };

  renderCarDetails = () => {
    return this.state.carDetails.map((x) => {
      return x;
    });
  };

  addCarData = (rowID, Index, type) => {
    let previousIndex = Number(Index);
    let currentIndex = previousIndex + 1;
    let rowId = "rowscar" + currentIndex;
    let newBoxID = "add_new_car" + currentIndex;
    let carMake = "car_make" + currentIndex;
    let car_model = "car_model" + currentIndex;
    let car_year = "car_year" + currentIndex;

    this.state.tempCarDetails.push(
      <div className="cst-row" id={rowId}>
        <div className="input-outer col-2">
          <input
            type="text"
            name="CarDetails[Make]"
            id={carMake}
            autoComplete="off"
            placeholder="Car Make"
            onFocus={() =>
              this.setState({
                CarErrText: "",
              })
            }
          ></input>
          <FormHelperText style={{ color: "red" }}>
            {this.state.CarErrText}
          </FormHelperText>
        </div>

        <div className="input-outer col-5">
          <input
            type="text"
            name="CarDetails[Model]"
            id={car_model}
            autoComplete="off"
            placeholder="Car Model"
            onFocus={() =>
              this.setState({
                CarErrText: "",
              })
            }
          ></input>
          <FormHelperText style={{ color: "red" }}>
            {this.state.CarErrText}
          </FormHelperText>
        </div>

        <div className="input-outer col-1">
          <select name="CarDetails[Year]" id={car_year}>
            {this.getcarYear()}
          </select>
        </div>
        <div className="button-outer col-50">
          <a
            className="plus-btn"
            id={newBoxID}
            onClick={() => this.addCarData(rowId, currentIndex, "Car")}
          >
            <img src={plusIcon}></img>
          </a>
          <a
            onClick={() => this.removecar(rowId, currentIndex, "Car")}
            className="delete-btn"
          >
            <img className="disktop" src={removeIcon}></img>
            <img className="mobile-delete-icon" src={mobileDelet}></img>
          </a>
        </div>
      </div>
      // </div>
    );
    this.setState({ carDetails: this.state.tempCarDetails });
    let prev2BoxID = "add_new_car" + previousIndex;
    document.getElementById(prev2BoxID).style.display = "none";
  };

  removecar = (rowID, Index, type) => {
    this.setState({ CarErrText: "" });
    var caridx = this.state.tempCarDetails.findIndex(
      (x) => x.props.id === rowID
    );
    this.state.tempCarDetails.splice(caridx, 1);
    this.setState({ carDetails: this.state.tempCarDetails });

    var preIdx = Index - 1;
    var backidx = "add_new_car" + preIdx;
    document.getElementById(backidx).style.display = "block";
  };

  addRowTV = (rowID, Index, type) => {
    let previousIndex = Number(Index);
    let currentIndex = previousIndex + 1;
    let rowId = "rowsteli" + currentIndex;
    let lengthIndex = "lengthteli" + currentIndex;
    let widthIndex = "widthteli" + currentIndex;
    let makeIndex = "no_packteli" + currentIndex;
    let modelIndex = "model_teli" + currentIndex;
    let heightIndex = "heightteli" + currentIndex;
    let newBoxID = "add_new_tv" + currentIndex;
    let weightID = "wight_packteli" + currentIndex;
    let chargableID = "chargableWeighttv" + currentIndex;

    this.state.tempTvDetails.push(
      // <div className="bx-wrap" >
      <div className="cst-row" id={rowId}>
        <div className="input-outer col-1">
          <input
            type="text"
            name="TelevisionDetails[Make]"
            id={makeIndex}
            autoComplete="off"
            placeholder="Brand Name"
            onFocus={() =>
              this.setState({
                TelevisionErrText: "",
              })
            }
          ></input>
        </div>

        <div className="input-outer col-1">
          {/* <label>Model</label> */}
          <input
            type="text"
            name="TelevisionDetails[Model]"
            id={modelIndex}
            autoComplete="off"
            placeholder="TV Model"
            onFocus={() =>
              this.setState({
                TelevisionErrText: "",
              })
            }
          ></input>
        </div>

        <div className="input-outer col-1">
          {/* <label>Weight</label> */}
          <div className="ipt-with-icon">
            <input
              type="text"
              maxLength={3}
              name="TelevisionDetails[Weight]"
              id={weightID}
              onBlur={() => this.calculatechWeight(currentIndex, "tv")}
              autoComplete="off"
              placeholder="Weight"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
            {/* <span>lbs</span> */}
          </div>
        </div>

        <div className="input-outer col-3 tvweight">
          {/* <label>Dimensions (Inches)</label> */}
          <div className="input-inner first">
            <input
              type="text"
              maxLength={3}
              name="TelevisionDetails[Length]"
              id={lengthIndex}
              onBlur={() => this.calculatechWeight(currentIndex, "tv")}
              autoComplete="off"
              placeholder="Length"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
            {/* <span>in</span> */}
            <span>X</span>
          </div>
          <div className="input-inner">
            <input
              type="text"
              maxLength={3}
              name="TelevisionDetails[Width]"
              id={widthIndex}
              onBlur={() => this.calculatechWeight(currentIndex, "tv")}
              autoComplete="off"
              placeholder="Width"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
            {/* <span>in</span> */}
            <span>X</span>
          </div>
          <div className="input-inner last">
            <input
              type="text"
              maxLength={3}
              name="TelevisionDetails[Height]"
              id={heightIndex}
              onBlur={() => this.calculatechWeight(currentIndex, "tv")}
              autoComplete="off"
              placeholder="Height"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
            {/* <span>in</span> */}
          </div>
        </div>

        <div className="input-outer col-1">
          {/* <label>Chargable Weight</label> */}
          <div className="ipt-with-icon">
            <input
              type="text"
              maxLength={3}
              name="TelevisionDetails[chargableWeight]"
              id={chargableID}
              autoComplete="off"
              placeholder="Weight"
              onFocus={() =>
                this.setState({
                  TelevisionErrText: "",
                })
              }
            ></input>
            {/* <span>lbs</span> */}
          </div>
        </div>
        <div className="button-outer col-50">
          <a
            onClick={() => this.addRowTV(rowId, currentIndex, "Tele")}
            id={newBoxID}
          >
            <img src={plusIcon}></img>
          </a>
          <a
            onClick={() => this.removetv(rowId, currentIndex, "Tele")}
            className="delete-btn"
          >
            <img className="disktop" src={removeIcon}></img>
            <img className="mobile-delete-icon" src={mobileDelet}></img>
          </a>
        </div>
      </div>
      // </div>
    );

    this.setState({ tvDetails: this.state.tempTvDetails });
    let prev2BoxID = "add_new_tv" + previousIndex;
    document.getElementById(prev2BoxID).style.display = "none";
  };

  removetv = (rowID, Index, type) => {
    this.setState({ TelevisionErrText: "" });
    var tvidx = this.state.tempTvDetails.findIndex((x) => x.props.id === rowID);
    this.state.tempTvDetails.splice(tvidx, 1);
    this.setState({ tvDetails: this.state.tempTvDetails });
    var preIdx = Index - 1;
    var backidx = "add_new_tv" + preIdx;
    document.getElementById(backidx).style.display = "inline-block";
  };

  renderBoxDetails = () => {
    return this.state.boxDetails.map((x) => {
      return x;
    });
  };
  changecaryear(e, value) {
    console.log("eeeee", this.state.allAutoValue);
    document.getElementsByName("CarDetails[Year]");
  }
  changeWeightType(e, value) {
    console.log("eeeee", e.currentTarget.value);

    if (e.currentTarget.value === "KG/CM") {
      this.state.preSelected = "KG";
      this.setState({
        SelectedWeightType: "KG/CM",
        SelectedDimensitionType: "CM",
      });
    } else {
      this.state.preSelected = "LB";
      this.setState({
        SelectedWeightType: "LB/IN",
        SelectedDimensitionType: "Inches",
      });
    }
    // var regex = /\./;

    var input = Number(value.value);
    console.log("number....", input);
    // if (!regex.test(input)) {
    //   this.state.SelectedWeightType = input;
    // }
  }
  addRowPackage = (rowID, Index, type) => {
    let previousIndex = Number(Index);
    let currentIndex = previousIndex + 1;
    let rowId = "rowsbox" + currentIndex;
    let lengthIndex = "length" + currentIndex;
    let widthIndex = "width" + currentIndex;
    let weightIndex = "weight_pack" + currentIndex;
    let heightIndex = "height" + currentIndex;
    let no_packIndex = "no_pack" + currentIndex;
    let newBoxID = "add_new_box" + currentIndex;
    let divID = "action-box" + currentIndex;
    let chargable = "chargable" + currentIndex;

    this.state.tempboxDetails.push(
      // <div className="quote-collect-row" id={rowId}>
      <div className="cst-row" id={rowId}>
        <div className="input-outer col-1">
          <select name="BoxDetails[No_Pack]" id={no_packIndex}>
            {this.getNumberofBox()}
          </select>
        </div>
        <div className="input-outer col-1">
          <div className="gt-input">
            {/* <label>Weight</label> */}
            <div className="ipt-with-icon">
              <input
                type="text"
                maxLength={3}
                name="BoxDetails[Weight]"
                id={weightIndex}
                placeholder="Weight"
                onBlur={() => this.calculatechWeight(currentIndex, "box")}
                onFocus={() =>
                  this.setState({
                    BoxesErrText: "",
                  })
                }
              ></input>
              {/* <span>{this.state.preSelected}</span> */}
            </div>
          </div>
        </div>
        <div className="input-outer col-3">
          {/* <label>Dimensions</label> */}
          <div className="input-inner first">
            <input
              type="text"
              maxLength={3}
              name="BoxDetails[Length]"
              id={lengthIndex}
              placeholder="Length"
              onBlur={() => this.calculatechWeight(currentIndex, "box")}
              onFocus={() =>
                this.setState({
                  BoxesErrText: "",
                })
              }
            ></input>
            <span>X</span>
          </div>
          <div className="input-inner">
            <input
              type="text"
              maxLength={3}
              name="BoxDetails[Width]"
              id={widthIndex}
              onBlur={() => this.calculatechWeight(currentIndex, "box")}
              placeholder="Width"
              onFocus={() =>
                this.setState({
                  BoxesErrText: "",
                })
              }
            ></input>
            <span>X</span>
          </div>
          <div className="input-inner last">
            <input
              type="text"
              maxLength={3}
              name="BoxDetails[Height]"
              id={heightIndex}
              placeholder="Height"
              onBlur={() => this.calculatechWeight(currentIndex, "box")}
              onFocus={() =>
                this.setState({
                  BoxesErrText: "",
                })
              }
            ></input>
            {/* <span>X</span> */}
          </div>
        </div>
        <div className="input-outer col-1">
          {/* <label>Chargable Weight</label> */}
          <div className="ipt-with-icon">
            <input
              type="text"
              maxLength={3}
              readOnly
              name="BoxDetails[chargableWeight]"
              id={chargable}
              placeholder="Weight"
              onFocus={() =>
                this.setState({
                  BoxesErrText: "",
                })
              }
            ></input>
            {/* <span>{this.state.preSelected}</span> */}
          </div>
        </div>
        <div className="button-outer col-50">
          <a
            className=" plus-btn "
            id={rowId}
            onClick={() => this.addRowPackage(rowId, currentIndex, "Box")}
          >
            <img className="disktop" src={plusIcon}></img>
            <img className="mobile-add-icon" src={mobileAddBtn}></img>
          </a>
          <a
            onClick={() => this.removeboxpackage(rowId, currentIndex, "Box")}
            className="delete-btn"
          >
            <img className="disktop" src={removeIcon}></img>
            <img className="mobile-delete-icon" src={mobileDelet}></img>
          </a>
        </div>
      </div>
      // </div>
    );
    this.setState({ boxDetails: this.state.tempboxDetails });
    let prev2BoxID = "add_new_box" + previousIndex;
    document.getElementById(prev2BoxID).style.display = "none";
  };

  getNumberofBox = () => {
    var noOfBox = [];
    for (var i = 1; i <= 25; i++) {
      noOfBox.push(i);
    }
    return noOfBox.map((x) => {
      return <option>{x}</option>;
    });
  };

  getcarYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    console.log("Current year:", currentYear);
    var noOfBox = [];
    noOfBox.push("Select");
    for (var i = currentYear; i >= 1990; i--) {
      noOfBox.push(i);
    }
    console.log("mmmmm", noOfBox);
    this.setState({ caryearlist: noOfBox });
    return noOfBox.map((x) => {
      return <option>{x}</option>;
    });
  };

  packageDetailsChange = (e, value, type) => {
    document.getElementById("referrederror").style.display = "none";
    this.setState({
      PackageDetailsErr: false,
      PackageDetailsErrText: "",
    });
    if (type === "envelope") {
      this.setState({ ShowEnvelop: true });
      this.setState({ packagetype: "Envelope" });
    } else {
      this.setState({ packagetype: "Package" });
    }
    var elements = document.getElementById(type);
    if (elements.classList.contains("active")) {
      elements.classList.remove("active");
      var index = this.state.documentData.indexOf(value);
      if (index != -1) {
        this.state.documentData.splice(index, 1);
      }
      if (type == "box") {
        this.setState({ showBoxDetails: false });
      } else if (type == "television") {
        this.setState({ showTVDetails: false });
      } else if (type == "auto") {
        this.setState({ showCarDetails: false });
      } else if (type == "furniture") {
        this.setState({ showfurniture: false });
      } else if (type == "envelope") {
        this.setState({ ShowEnvelop: false });
      }
    } else {
      elements.classList.add("active");
      this.state.documentData.push(value);

      if (type == "box") {
        this.setState({ showBoxDetails: true });
      } else if (type == "television") {
        this.setState({ showTVDetails: true });
      } else if (type == "auto") {
        this.setState({ showCarDetails: true });
      } else if (type == "furniture") {
        this.setState({ showfurniture: true });
      } else if (type == "envelope") {
        this.setState({ ShowEnvelop: true });
      }
    }
    // ============= CHECK WHETHER 2ND STEP TO BE SHOWED OR NOT ===============
    if (this.state.documentData.length != 0) {
      if (
        this.state.documentData.length == 1 &&
        this.state.documentData.includes("2")
      ) {
        this.setState({ ShowEnvelop: true });
        if (this.state.Steps[1].stepId == "containerdetails") {
          this.state.Steps.splice(1, 1);
        }
      } else {
        if (this.state.documentData.includes("3")) {
        } else {
        }
      }
    }
  };

  renderCountryOptions() {
    return this.state.CountryList.map((value) => {
      return <option value={value.CountryID}>{value.CountryName}</option>;
    });
  }

  handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return this.state.CountryList;
      }
      const updatedItems = this.state.CountryList.map((list) => {
        const newItems = list.CountryName.filter((item) => {
          return item.CountryName.toLowerCase().startsWith(
            searchValue.toLowerCase()
          );
        });
        return { ...list, CountryName: newItems };
      });
      return updatedItems;
    };
  };

  handleSelect(e, type) {
    if (type === "from") {
      this.setState({ SelectedOption: e });
      this.setState({ FromIsFedxCity: 0, FromSelectedCountry: {} });
      let CountryId = e.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ FromIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "from");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "from");
      }
      var FromSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };

      this.setState({ FromSelectedCountry: FromSelectedCountry });
      this.setState({ FromZip: "", FromCity: "", FromFedExSelectedCity: "" });

      if (CountryId == 89) {
        this.state.SelectedWeightType = "KG/CM";
        this.state.SelectedDimensitionType = "CM";
      } else {
        this.state.SelectedWeightType = "LB/IN";
        this.state.SelectedDimensitionType = "Inches";
      }

      document.getElementById("fromzipSelect").value = "";
    } else if (type === "to") {
      this.setState({ SelectedOptionTo: e, ToStateCodeValueErr: "" });
      this.setState({ ToIsFedxCity: 0, ToSelectedCountry: {} });
      let CountryId = e.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ ToIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "to");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "to");
      } else {
        this.setState({ hidetozipcode: true });
      }
      var ToSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };
      this.setState({ ToSelectedCountry: ToSelectedCountry });
      this.setState({ ToZip: "", ToCity: "", ToFedExSelectedCity: "" });
    }
  }

  async GetCountry() {
    try {
      await api
        .get("location/getCountryList")
        .then((res) => {
          if (res.success) {
            var datacountry = -1 
            fetch("https://ipinfo.io/json") 
          .then(response => response.json())
          .then(data => {
            console.log("Datas = ",data.country)
            if(data.country == "IN"){
              datacountry = 89;
              // this.state.PhoneNumberTollFree = "1-800-833-4849"
              // this.state.hrefPhone = "tel:18008334849"
              // this.state.indiaornot = "Yes"
              // localStorage.setItem("forIndia","Yes")
            }else{
              datacountry = 202;
              
            }
          })
          .catch(error => {
            // console.error("Error fetching IP address:", error);
            datacountry = 202;
            
          });
  
            var Country = res.data;
            this.state.tempCountryList.push(res.data);
            this.setState({ CountryList: Country });
  
            // --------------checkdata-----------this.state.CountryList
            let mainCountryList = [];
            for (var i = 0; i < Country?.length; i++) {
              let mainCountryObject = {};
              mainCountryObject.label = Country[i]?.CountryName;
              mainCountryObject.value = Country[i]?.CountryID;
              mainCountryList.push(mainCountryObject);
  
              this.setState({ CountryListValue: mainCountryList });
              console.log("ccccccc", this.state.CountryListValue);
            }
            // --------------checkdata-----------------
            document.getElementById("fromCountrySelect").value = datacountry;
            
            this.setState({
              newcountryid: document.getElementById("fromCountrySelect").value,
            });
  
            var selectedCountryList = _.filter(Country, { CountryID: datacountry });
  
            if (selectedCountryList[0].IsZipAvailable === 0) {
              this.setState({ hidefromzipcode: false });
            } else {
              this.setState({ hidefromzipcode: true });
            }
            var SelectedCountry = {
              value: selectedCountryList[0].CountryID,
              label: selectedCountryList[0].CountryName,
            };
            this.setState({
              FromSelectedCountry: SelectedCountry,
            });
            //================== FOR TO COUNTRY =========================
            document.getElementById("toCountrySelect").value = "";
          }
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } catch (error) {}
  }

  async GetCountryCode() {
    try {
      await api
        .get("contactus/getCountryCode")
        .then((res) => {
          if (res.success) {
            var CountryCode = res.data;
            this.state.tempCountryCodeList.push(res.data);
            this.setState({ countryCodeList: CountryCode });

            // --------------checkdata-----------this.state.CountryList
            let mainCountryList = [];
            for (var i = 0; i < CountryCode?.length; i++) {
              let mainCountryObject = {};
              mainCountryObject.label = CountryCode[i]?.phonecode;
              mainCountryObject.value = CountryCode[i]?.phonecode;
              mainCountryList.push(mainCountryObject);

              this.setState({ countryCodeListValue: mainCountryList });
              console.log("ccccccc", this.state.countryCodeListValue);
            }
            // --------------checkdata-----------------
            
          }
        })
        .catch((err) => {
          console.log("err...", err);
        });
    } catch (error) {}
  }

  countryChange = (e, type) => {
    if (type === "from") {
      this.setState({ FromIsFedxCity: 0, FromSelectedCountry: {} });
      let CountryId = e.target.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ FromIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "from");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "from");
      }
      var FromSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };

      this.setState({ FromSelectedCountry: FromSelectedCountry });
      this.setState({ FromZip: "", FromCity: "", FromFedExSelectedCity: "" });
      document.getElementById("fromzipSelect").value = "";
    }
    if (type === "to") {
      this.setState({ ToIsFedxCity: 0, ToSelectedCountry: {} });
      let CountryId = e.target.value;
      let forzip = _.findIndex(this.state.CountryList, function (country) {
        return country.CountryID == CountryId;
      });
      let zip = this.state.CountryList[forzip];
      if (zip.CountryName !== "United States" && zip.IsFedexCity === 1) {
        this.setState({ ToIsFedxCity: 1 });
        this.getCityList(zip.CountryID, "to");
      } else if (zip.CountryName === "China") {
        this.getCityList(zip.CountryID, "to");
      } else {
        this.setState({ hidetozipcode: true });
      }
      var ToSelectedCountry = {
        value: zip.CountryID,
        label: zip.CountryName,
      };
      this.setState({ ToSelectedCountry: ToSelectedCountry });
      this.setState({ ToZip: "", ToCity: "", ToFedExSelectedCity: "" });
    }
  };

  getCityList = (value, type) => {
    var CityData = { CityType: "FedEx", CountryId: value };
    if (type === "from") {
      api
        .post("location/getCityList", CityData)
        .then((res) => {
          if (res.success) {
            var FedexCityList = res.data;
            this.setState({ FromFedExCityList: res.data });

            let FromFedExCityListOption = [];
            for (var i = 0; i < FedexCityList?.length; i++) {
              let FromFedExCityObject = {};
              FromFedExCityObject.label = FedexCityList[i]?.CityName;
              FromFedExCityObject.value = FedexCityList[i]?.CityCode;
              FromFedExCityListOption.push(FromFedExCityObject);
              this.setState({
                FromFedExCityOptionValue: FromFedExCityListOption,
              });
            }
          } else {
            this.setState({ FromFedExCityList: [] });
          }
        })
        .catch((error) => {});
    } else {
      api
        .post("location/getCityList", CityData)
        .then((res) => {
          if (res.success) {
            var ToFedexCityList = res.data;
            this.setState({ ToFedExCityList: res.data });

            let ToFedExCityListOption = [];
            for (var i = 0; i < ToFedexCityList?.length; i++) {
              let ToFedExCityObject = {};
              ToFedExCityObject.label = ToFedexCityList[i]?.CityName;
              ToFedExCityObject.value = ToFedexCityList[i]?.CityCode;
              ToFedExCityListOption.push(ToFedExCityObject);

              this.setState({
                ToFedExCityOptionValue: ToFedExCityListOption,
              });
            }
          } else {
            this.setState({ ToFedExCityList: [] });
          }
        })
        .catch((error) => {});
    }
  };

  renderFedxCityOptions = (type) => {
    if (type === "from") {
      return this.state.FromFedExCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    } else {
      return this.state.ToFedExCityList.map((x) => {
        return <option value={x.CityCode}>{x.CityName}</option>;
      });
    }
  };

  finalStep = (key) => {

    if(this.state.FromSelectedCountry.value == "89"){
  
      this.state.setCurrencyIcon = "₹ "
      }else if(this.state.FromSelectedCountry.value == "37"){
        this.state.setCurrencyIcon = "$c "
      }else{
        this.state.setCurrencyIcon = "$ "
      }
  
      if (this.state.hidefromzipcode === false) {
        if (this.state.FromCity === "") {
          stepValidate = false;
          this.setState({
            FromCityErr: true,
            FromCityErrText: "From city required",
          });
        } else {
          stepValidate = true;
          this.setState({
            FromCityErr: false,
            FromCityErrText: "",
          });
        }
      }
      if (this.state.flag == 1 && this.state.ThankyouPageMoveFromZip == false) {
        this.setState({ FromZip: "" });
        document.getElementById("fromzipSelect").value = "";
      } else if (
        this.state.flag2 == 1 &&
        this.state.ThankyouPageMoveToZip == false
      ) {
        this.setState({
          ToZipErr: false,
          ToZipErrText: "",
        });
    
        document.getElementById("TozipSelect").value = "";
    
        this.setState({
          FromZipCodeErr: false,
          ToZipCodeHelperText: "",
        });
      } else {
        let emailRegex =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // alert("True")
        let stepsList = this.state.Steps;
        let activeIndex = stepsList.findIndex((x) => x.classname === "active");
        this.setState({ activeIndex: activeIndex });
    
        this.hideLoader();
        var envelope = document.getElementById("envelope");
        var box = document.getElementById("box");
        var television = document.getElementById("television");
        var furniture = document.getElementById("furniture");
        var auto = document.getElementById("auto");
    
        var No_packBoxDetails = [];
        var WeightBoxDetails = [];
        var LengthBoxDetails = [];
        var WidthBoxDetails = [];
        var HeightBoxDetails = [];
        var ChargeWeightBoxDetails = [];
        var MakeCarDetails = [];
        var ModelCarDetails = [];
        var YearCarDetails = [];
        var MakeTelevisionDetails = [];
        var ModelTelevisionDetails = [];
        var WeightTelevisionDetails = [];
        var LengthTelevisionDetails = [];
        var WidthTelevisionDetails = [];
        var HeightTelevisionDetails = [];
    
        if (activeIndex == 0) {
          for (var i = 0; i < this.state.documentData.length; i++) {
            if (
              this.state.documentData[i] == "1" ||
              this.state.documentData[i] == "3" ||
              this.state.documentData[i] == "4" ||
              this.state.documentData[i] == "5"
            ) {
              this.setState({ packagetype: "Package" });
            }
          }
        }
        if (
          activeIndex == 1 &&
          this.state.Steps[activeIndex].stepId == "containerdetails"
        ) {
          var nopack = [];
          var temp = document.getElementsByName("BoxDetails[No_Pack]");
          var boxarray = [].concat.apply(nopack, temp);
          No_packBoxDetails = boxarray.map((x) => {
            return x.value;
          });
    
          var weightpack = [];
          var temp = document.getElementsByName("BoxDetails[Weight]");
          var boxweightarray = [].concat.apply(weightpack, temp);
          WeightBoxDetails = boxweightarray.map((x) => {
            return x.value;
          });
    
          var lengthpack = [];
          var temp = document.getElementsByName("BoxDetails[Length]");
          var lengthboxarray = [].concat.apply(lengthpack, temp);
          LengthBoxDetails = lengthboxarray.map((x) => {
            return x.value;
          });
    
          var widthpack = [];
          var temp = document.getElementsByName("BoxDetails[Width]");
          var widthboxarray = [].concat.apply(widthpack, temp);
          WidthBoxDetails = widthboxarray.map((x) => {
            return x.value;
          });
    
          var heightpack = [];
          var temp = document.getElementsByName("BoxDetails[Height]");
          var heightboxarray = [].concat.apply(heightpack, temp);
          HeightBoxDetails = heightboxarray.map((x) => {
            return x.value;
          });
    
          var carmake = [];
          var temp = document.getElementsByName("CarDetails[Make]");
          var makecararray = [].concat.apply(carmake, temp);
          MakeCarDetails = makecararray.map((x) => {
            return x.value;
          });
    
          var modelcar = [];
          var temp = document.getElementsByName("CarDetails[Model]");
          var modelcararray = [].concat.apply(modelcar, temp);
          ModelCarDetails = modelcararray.map((x) => {
            return x.value;
          });
    
          var yearcar = [];
          var temp = document.getElementsByName("CarDetails[Year]");
          var yearcararray = [].concat.apply(yearcar, temp);
          YearCarDetails = yearcararray.map((x) => {
            return x.value;
          });
    
          var notele = [];
          var temp = document.getElementsByName("TelevisionDetails[Make]");
          var maketelearray = [].concat.apply(notele, temp);
          var No_packTelevisionDetails = maketelearray.map((x) => {
            return x.value;
          });
    
          var notele = [];
          var temp = document.getElementsByName("TelevisionDetails[Make]");
          var makenewtelearray = [].concat.apply(notele, temp);
          MakeTelevisionDetails = makenewtelearray.map((x) => {
            return x.value;
          });
    
          var notele = [];
          var temp = document.getElementsByName("TelevisionDetails[Model]");
          var modelnewtelearray = [].concat.apply(notele, temp);
          ModelTelevisionDetails = modelnewtelearray.map((x) => {
            return x.value;
          });
    
          var weighttele = [];
          var temp = document.getElementsByName("TelevisionDetails[Weight]");
          var weighttelearray = [].concat.apply(weighttele, temp);
          WeightTelevisionDetails = weighttelearray.map((x) => {
            return x.value;
          });
    
          var lengthtele = [];
          var temp = document.getElementsByName("TelevisionDetails[Length]");
          var lengthtelearray = [].concat.apply(lengthtele, temp);
          LengthTelevisionDetails = lengthtelearray.map((x) => {
            return x.value;
          });
    
          var widthtele = [];
          var temp = document.getElementsByName("TelevisionDetails[Width]");
          var widthtelearray = [].concat.apply(widthtele, temp);
          var WidthTelevisionDetails = widthtelearray.map((x) => {
            return x.value;
          });
    
          var heighttele = [];
          var temp = document.getElementsByName("TelevisionDetails[Height]");
          var heighttelearray = [].concat.apply(heighttele, temp);
          var HeightTelevisionDetails = heighttelearray.map((x) => {
            return x.value;
          });
    
          var doc = [];
          var temp = document.getElementsByName("DocumnetDetails[]");
          var docarray = [].concat.apply(doc, temp);
          var DocumnetDetails = docarray.map((x) => {
            return x.value;
          });
        }
        if (activeIndex == 0) {
          if (
            envelope.classList.contains("selected") ||
            box.classList.contains("selected") ||
            television.classList.contains("selected") ||
            furniture.classList.contains("selected") ||
            auto.classList.contains("selected")
          ) {
            stepValidate = true;
          } else {
            stepValidate = false;
            this.setState({
              PackageDetailsErr: true,
              PackageDetailsErrText: "Please select shipment content",
            });
          }
        } else if (
          activeIndex == 1 &&
          this.state.Steps[activeIndex].stepId == "containerdetails"
        ) {
          this.state.documentData.find((x) => {
            if (x === "1") {
              for (let i = 0; i < No_packBoxDetails.length; i++) {
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] != "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] !== ""
                ) {
                  stepValidate = true;
                } else {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter correct values",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] == "" &&
                  LengthBoxDetails[i] == "" &&
                  WidthBoxDetails[i] == "" &&
                  HeightBoxDetails[i] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter weight & dimensions",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] == "" &&
                  WidthBoxDetails[i] == "" &&
                  HeightBoxDetails[i] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter  dimensions",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] == "" &&
                  LengthBoxDetails[i] !== "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter weight ",
                  });
                }
                if (
                  No_packBoxDetails[i] == "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] !== "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter No. of box  ",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] !== "" &&
                  WidthBoxDetails[i] == "" &&
                  HeightBoxDetails[i] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter Dimensions width & Height",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] !== "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter Dimensions Height",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] == "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter Dimensions length & Height",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] == "" &&
                  WidthBoxDetails[i] !== "" &&
                  HeightBoxDetails[i] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter Dimensions length ",
                  });
                }
                if (
                  No_packBoxDetails[i] !== "" &&
                  WeightBoxDetails[i] !== "" &&
                  LengthBoxDetails[i] == "" &&
                  WidthBoxDetails[i] == "" &&
                  HeightBoxDetails[i] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter Dimensions length & width",
                  });
                }
                if (WeightBoxDetails[i] == 0) {
                  stepValidate = false;
                  this.setState({
                    BoxesErr: true,
                    BoxesErrText: "Please enter correct weight",
                  });
                }
              }
            }
            if (x === "2") {
            }
            if (x === "3") {
            }
    
            if (x === "4") {
              for (let k = 0; k < No_packTelevisionDetails.length; k++) {
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] !== "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = true;
                } else {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter correct values",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] == "" &&
                  LengthTelevisionDetails[k] == "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText:
                      "Please Enter Television Weight & Dimensions",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] == "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter Television Dimensions",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] == "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] !== "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter Television Weight",
                  });
                }
                if (
                  No_packTelevisionDetails[k] == "" &&
                  WeightTelevisionDetails[k] == "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] !== "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText:
                      "Please Enter Television make & Television Weight",
                  });
                }
                if (
                  No_packTelevisionDetails[k] == "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] == "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText:
                      "Please Enter Television make & Television Dimensions",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText:
                      "Please Enter  Television Dimensions width & Height",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] !== "" &&
                  HeightTelevisionDetails[k] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter  Television Dimensions Height",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] == "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText:
                      "Please Enter  Television Dimensions length & width ",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] == "" &&
                  WidthTelevisionDetails[k] !== "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter  Television Dimensions length",
                  });
                }
                if (
                  No_packTelevisionDetails[k] !== "" &&
                  WeightTelevisionDetails[k] !== "" &&
                  LengthTelevisionDetails[k] !== "" &&
                  WidthTelevisionDetails[k] == "" &&
                  HeightTelevisionDetails[k] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    TelevisionErrText: "Please Enter  Television Dimensions width ",
                  });
                }
              }
            }
            if (x === "5") {
              for (let j = 0; j < MakeCarDetails.length; j++) {
                if (
                  MakeCarDetails[j] !== "" &&
                  ModelCarDetails[j] !== "" &&
                  YearCarDetails[j] !== ""
                ) {
                  stepValidate = true;
                } else {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter correct values",
                  });
                }
                if (
                  MakeCarDetails[j] !== "" &&
                  ModelCarDetails[j] == "" &&
                  YearCarDetails[j] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car model & car year",
                  });
                }
                if (
                  MakeCarDetails[j] !== "" &&
                  ModelCarDetails[j] !== "" &&
                  YearCarDetails[j] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car year",
                  });
                }
                if (
                  MakeCarDetails[j] !== "" &&
                  ModelCarDetails[j] == "" &&
                  YearCarDetails[j] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car model",
                  });
                }
                if (
                  MakeCarDetails[j] == "" &&
                  ModelCarDetails[j] !== "" &&
                  YearCarDetails[j] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car make",
                  });
                }
                if (
                  MakeCarDetails[j] == "" &&
                  ModelCarDetails[j] == "" &&
                  YearCarDetails[j] !== ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car make & car model",
                  });
                }
                if (
                  MakeCarDetails[j] == "" &&
                  ModelCarDetails[j] !== "" &&
                  YearCarDetails[j] == ""
                ) {
                  stepValidate = false;
                  this.setState({
                    CarErrText: "Please enter car make & car year",
                  });
                }
              }
            }
          });
        } else if (
          (activeIndex == 2 &&
            this.state.Steps[activeIndex].stepId == "shipmentdetails") ||
          (activeIndex == 1 &&
            this.state.Steps[activeIndex].stepId == "shipmentdetails")
        ) {
          if (
            document.getElementById("toCountrySelect").value == "" &&
            document.getElementById("fromCountrySelect").value == "" &&
            this.state.FromZip == "" &&
            this.state.ToZip == ""
          ) {
            stepValidate = false;
            cogoToast.error("Please select country");
          } else {
            if (document.getElementById("toCountrySelect").value == "") {
              stepValidate = false;
              this.setState({
                ToCountryErr: true,
                ToCountryErrText: "Please select To Country",
              });
            } else if (document.getElementById("fromCountrySelect").value == "") {
              stepValidate = false;
              cogoToast.error("Please select From Country");
            } else if (
              this.state.hidefromzipcode &&
              this.state.FromZip == "" &&
              this.state.FromCity !== ""
            ) {
              stepValidate = false;
              this.setState({
                FromZipErr: true,
                FromZipErrText: "Please enter From Zip",
              });
            } else if (
              this.state.FromSelectedCountry.label === "China" &&
              this.state.FromZip == ""
            ) {
              stepValidate = false;
              this.setState({
                FromZipErr: true,
                FromZipErrText: "Please enter From Zip Code",
              });
            } else if (
              this.state.ToSelectedCountry.label === "China" &&
              this.state.ToZip === ""
            ) {
              stepValidate = false;
              this.setState({
                ToZipCodeEnterText: "Please enter To Zip Code",
              });
            } else if (
              this.state.hidetozipcode &&
              this.state.ToZip === "" &&
              this.state.ToCity !== ""
            ) {
              stepValidate = false;
              this.setState({
                ToZipCodeEnterText: "Please enter To Zip",
              });
            } else if (this.state.ToSelectedCountry.value === 37) {
              if (
                this.state.ToZip.length < 5 ||
                /[^A-Za-z@.0-9\d]/.test(this.state.ToZip)
              ) {
                stepValidate = false;
                this.setState({
                  ToZipCodeEnterErr: true,
                  ToZipCodeEnterText:
                    "Please enter the zip code at list 5 character",
                });
              }
            } else if (
              this.state.FromCity == "" &&
              this.state.FromIsFedxCity === 0 &&
              this.state.ThankyouPageMoveFromZip == false &&
              this.state.FromSelectedCountry.label !== "China"
            ) {
              stepValidate = false;
              this.setState({
                FromCityErr: true,
                FromCityErrText: "Please enter from city",
              });
            } else if (
              this.state.FromSelectedCountry.label === "China" &&
              CommonConfig.isEmpty(this.state.FromFedExSelectedCity)
            ) {
              stepValidate = false;
              this.setState({
                FromCityErr: true,
                FromCityErrText: "Please enter from city",
              });
            } else if (
              this.state.ToCity == "" &&
              this.state.ToIsFedxCity === 0 &&
              this.state.ThankyouPageMoveToZip == false &&
              this.state.ToSelectedCountry.label !== "China"
            ) {
              stepValidate = false;
              this.setState({
                ToCityErr: true,
                ToCityErrText: "Please Enter To city",
              });
            } else if (
              this.state.ToSelectedCountry.label === "China" &&
              CommonConfig.isEmpty(this.state.ToFedExSelectedCity)
            ) {
              stepValidate = false;
              this.setState({
                ToCityErr: true,
                ToCityErrText: "Please Enter To city",
              });
            } else {
              stepValidate = true;
            }
          }
        } else if (
          (activeIndex == 2 &&
            this.state.Steps[activeIndex].stepId == "shipperdetails") ||
          (activeIndex == 3 &&
            this.state.Steps[activeIndex].stepId == "shipperdetails")
        ) {
          if (
            this.state.ContactName == "" &&
            this.state.PhoneNumber == "" &&
            this.state.email == "" &&
            !emailRegex.test(this.state.email)
          ) {
            stepValidate = false;
            allValid = false;
            this.setState({
              cnameErrText: "Please enter contact name",
              ContactNumberErrText: "Please enter phone number",
              EmailErrText: "Please enter email",
            });
          } else if (
            (this, this.state.ContactName == "" && this.state.PhoneNumber == "")
          ) {
            stepValidate = false;
            allValid = false;
            this.setState({
              cnameErrText: "Please enter contact name",
              ContactNumberErrText: "Please enter phone number",
            });
          } else if (
            (this,
            this.state.PhoneNumber == "" &&
              this.state.email == "" &&
              !emailRegex.test(this.state.email))
          ) {
            stepValidate = false;
            allValid = false;
            this.setState({
              ContactNumberErrText: "Please enter phone number",
              EmailErrText: "Please enter email",
            });
            stepValidate = false;
            allValid = false;
          } else if (
            (this, this.state.email == "" && this.state.ContactName == "")
          ) {
            stepValidate = false;
            this.setState({
              cnameErrText: "Please enter contact name",
              EmailErrText: "Please enter email",
            });
          } else if ((this, this.state.email == "")) {
            stepValidate = false;
            allValid = false;
            this.setState({
              EmailErrText: "Please enter email",
            });
          } else if ((this, this.state.ContactName == "")) {
            stepValidate = false;
            allValid = false;
            this.setState({
              cnameErrText: "Please enter contact name",
            });
          } else if ((this, this.state.PhoneNumber == "")) {
            stepValidate = false;
            allValid = false;
            this.setState({
              ContactNumberErrText: "Please enter phone number",
            });
            stepValidate = false;
            allValid = false;
          } else {
            if (!emailRegex.test(this.state.email)) {
              stepValidate = false;
              allValid = false;
            } else {
              stepValidate = true;
              allValid = true;
            }
          }
        }
        if (
          (activeIndex == 2 &&
            this.state.Steps[activeIndex].stepId == "shipperdetails") ||
          (activeIndex == 3 &&
            this.state.Steps[activeIndex].stepId == "shipperdetails")
        ) {
          if (allValid) {
            if (key !== activeIndex) {
              this.setState({ NextButtonIdx: key });
              stepsList[key]["classname"] = "active";
              stepsList[activeIndex]["classname"] = "inactive";
              this.setState({ Steps: stepsList });
              let divID = stepsList[key]["stepId"];
              let activeDiv = stepsList[activeIndex]["stepId"];
              document.getElementById(divID).style.display = "block";
              document.getElementById(activeDiv).style.display = "none";
            }
          } else {
            stepValidate = false;
          }
        } else {
          if (stepValidate) {
            // if (key !== activeIndex) {
            //   this.setState({ NextButtonIdx: key });
            //   stepsList[key]["classname"] = "active";
            //   stepsList[activeIndex]["classname"] = "inactive";
            //   this.setState({ Steps: stepsList });
            //   let divID = stepsList[key]["stepId"];
            //   let activeDiv = stepsList[activeIndex]["stepId"];
            //   document.getElementById(divID).style.display = "block";
            //   document.getElementById(activeDiv).style.display = "none";
            // }
          }
        }
        //KRUTI j
        if (stepValidate && allValid) {
          let emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (this.state.email == "" || !emailRegex.test(this.state.email)) {
            this.setState({
              EmailErrText: "Please enter valid email",
            });
            this.hideLoader();
            return cogoToast.error("Please enter valid email");
          }
          var phonenoRegex = /^\d{10}$/;
          if (this.state.PhoneNumber != "") {
            if (
              this.state.PhoneNumber.length < 10 ||
              !phonenoRegex.test(this.state.PhoneNumber)
            ) {
              // this.setState({
              //   contactErrText: "Please enter valid phone number",
              // });
              return cogoToast.error("Please enter valid phone number");
            }
          }
          if (this.state.ContactName == "") {
            return cogoToast.error("Please enter contact name");
          }
          if (this.state.Steps[activeIndex].stepId == "shipsmart") {
            if (
              this.state.documentData.length === 1 &&
              this.state.documentData.indexOf("3") === -1 &&
              this.state.documentData.indexOf("5") === -1 &&
              this.state.documentData.indexOf("4") === -1
            ) {
              var PackageRes = [];
              var PersonList = [];
              var finalManagedBy = [];
              var ManagedBY = "";
              var Access = 0;
              var elsecalled = 0;
              var PackageName = "";
              var newpackagetype = "";
    
              if (this.state.documentData[0] == 2) {
                newpackagetype = "Document";
              }
              if (this.state.documentData[0] == 1) {
                newpackagetype = "Boxes";
              }
              if (this.state.documentData[0] == 4) {
                newpackagetype = "Television";
              }
              if (this.state.documentData[0] == 3) {
                newpackagetype = "Furniture";
              }
              if (this.state.documentData[0] == 5) {
                newpackagetype = "Auto";
              }
              var manageData = {};
              {
                this.state.PersonID
                  ? (manageData = {
                      Email: this.state.email,
                      Phone: this.state.PhoneNumber,
                      PersonID: this.state.PersonID,
                      newpackagetype: newpackagetype,
                    })
                  : (manageData = {
                      Email: this.state.email,
                      Phone: this.state.PhoneNumber,
                      PersonID: 0,
                      newpackagetype: newpackagetype,
                    });
              }
    
              this.showLoader();
    
              // Box Details 1
              var nopack = [];
              var temp = document.getElementsByName("BoxDetails[No_Pack]");
              var boxarray = [].concat.apply(nopack, temp);
              No_packBoxDetails = boxarray.map((x) => {
                return x.value;
              });
    
              var weightpack = [];
              var temp = document.getElementsByName("BoxDetails[Weight]");
              var boxweightarray = [].concat.apply(weightpack, temp);
              WeightBoxDetails = boxweightarray.map((x) => {
                return x.value;
              });
    
              var lengthpack = [];
              var temp = document.getElementsByName("BoxDetails[Length]");
              var lengthboxarray = [].concat.apply(lengthpack, temp);
              LengthBoxDetails = lengthboxarray.map((x) => {
                return x.value;
              });
    
              var widthpack = [];
              var temp = document.getElementsByName("BoxDetails[Width]");
              var widthboxarray = [].concat.apply(widthpack, temp);
              WidthBoxDetails = widthboxarray.map((x) => {
                return x.value;
              });
    
              var heightpack = [];
              var temp = document.getElementsByName("BoxDetails[Height]");
              var heightboxarray = [].concat.apply(heightpack, temp);
              HeightBoxDetails = heightboxarray.map((x) => {
                return x.value;
              });
    
              //Calculation
              var filterFromCountryData = this.state.CountryList.filter(
                (x) => x.CountryID == this.state.FromSelectedCountry.value
              );
              var filterToCountryData = this.state.CountryList.filter(
                (x) => x.CountryID == this.state.ToSelectedCountry.value
              );
              if (
                filterFromCountryData[0].CountryCode &&
                filterToCountryData[0].CountryCode
              ) {
                for (var i = 0; i < No_packBoxDetails.length; i++) {
                  var WE = 0;
                  var LE = 0;
                  var HE = 0;
                  var Total = 0;
                  var Weight = 0;
                  if (WeightBoxDetails[i]) {
                    Weight =
                      Number(WeightBoxDetails[i]) * Number(No_packBoxDetails[i]);
                  }
    
                  if (WidthBoxDetails[i]) {
                    WE = Number(WidthBoxDetails[i]);
                  }
    
                  if (LengthBoxDetails[i]) {
                    LE = Number(LengthBoxDetails[i]);
                  }
    
                  if (HeightBoxDetails[i]) {
                    HE = Number(HeightBoxDetails[i]);
                  }
    
                  if (this.state.SelectedWeightType == "KG/CM") {
                    Total =
                      Math.ceil(parseFloat((WE * LE * HE) / 5000)) *
                      Number(No_packBoxDetails[i]);
                  } else {
                    Total =
                      Math.ceil(parseFloat((WE * LE * HE) / 139)) *
                      Number(No_packBoxDetails[i]);
                  }
    
                  if (Weight > Total) {
                    ChargeWeightBoxDetails[i] = Number(Weight);
                  } else {
                    ChargeWeightBoxDetails[i] = Number(Total);
                  }
                }
              }
    
              var carmake = [];
              var temp = document.getElementsByName("CarDetails[Make]");
              var makecararray = [].concat.apply(carmake, temp);
              MakeCarDetails = makecararray.map((x) => {
                return x.value;
              });
    
              var modelcar = [];
              var temp = document.getElementsByName("CarDetails[Model]");
              var modelcararray = [].concat.apply(modelcar, temp);
              ModelCarDetails = modelcararray.map((x) => {
                return x.value;
              });
    
              var yearcar = [];
              var temp = document.getElementsByName("CarDetails[Year]");
              var yearcararray = [].concat.apply(yearcar, temp);
              YearCarDetails = yearcararray.map((x) => {
                return x.value;
              });
    
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Make]");
              var maketelearray = [].concat.apply(notele, temp);
              var No_packTelevisionDetails = maketelearray.map((x) => {
                return x.value;
              });
    
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Make]");
              var makenewtelearray = [].concat.apply(notele, temp);
              MakeTelevisionDetails = makenewtelearray.map((x) => {
                return x.value;
              });
    
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Model]");
              var modelnewtelearray = [].concat.apply(notele, temp);
              ModelTelevisionDetails = modelnewtelearray.map((x) => {
                return x.value;
              });
    
              var weighttele = [];
              var temp = document.getElementsByName("TelevisionDetails[Weight]");
              var weighttelearray = [].concat.apply(weighttele, temp);
              WeightTelevisionDetails = weighttelearray.map((x) => {
                return x.value;
              });
    
              var lengthtele = [];
              var temp = document.getElementsByName("TelevisionDetails[Length]");
              var lengthtelearray = [].concat.apply(lengthtele, temp);
              LengthTelevisionDetails = lengthtelearray.map((x) => {
                return x.value;
              });
    
              var widthtele = [];
              var temp = document.getElementsByName("TelevisionDetails[Width]");
              var widthtelearray = [].concat.apply(widthtele, temp);
              var WidthTelevisionDetails = widthtelearray.map((x) => {
                return x.value;
              });
    
              var heighttele = [];
              var temp = document.getElementsByName("TelevisionDetails[Height]");
              var heighttelearray = [].concat.apply(heighttele, temp);
              var HeightTelevisionDetails = heighttelearray.map((x) => {
                return x.value;
              });
    
              var doc = [];
              var temp = document.getElementsByName("DocumnetDetails[]");
              var docarray = [].concat.apply(doc, temp);
              var DocumnetDetails = docarray.map((x) => {
                return x.value;
              });
    
              if (this.state.packagetype === "Envelope") {
                this.state.packgedata.no_pack.push(1);
                this.state.packgedata.weight.push(0.5);
                this.state.packgedata.len.push(10);
                this.state.packgedata.width.push(13);
                this.state.packgedata.height.push(1);
                this.state.packgedata.chargableWeight.push(1);
                this.state.packgedata.insuredValue.push(0);
              } else {
                console.log("documentData = ", this.state.documentData);
                console.log("No_packBoxDetails = ", No_packBoxDetails);
    
                this.state.documentData.find((x) => {
                  if (x === "1") {
                    for (let j = 0; j < No_packBoxDetails.length; j++) {
                      this.state.packgedata.no_pack.push(No_packBoxDetails[j]);
                      this.state.packgedata.weight.push(WeightBoxDetails[j]);
                      this.state.packgedata.len.push(LengthBoxDetails[j]);
                      this.state.packgedata.width.push(WidthBoxDetails[j]);
                      this.state.packgedata.height.push(HeightBoxDetails[j]);
                      this.state.packgedata.chargableWeight.push(
                        ChargeWeightBoxDetails[j]
                      );
                      this.state.packgedata.insuredValue.push(0);
                    }
                  }
                  // if (x === "3") {
                  // }
                  if (x === "4") {
                    for (let j = 0; j < MakeTelevisionDetails.length; j++) {
                      this.state.packgedata.no_pack.push(1);
                      this.state.packgedata.weight.push(WeightTelevisionDetails[j]);
                      this.state.packgedata.len.push(LengthTelevisionDetails[j]);
                      this.state.packgedata.width.push(WidthTelevisionDetails[j]);
                      this.state.packgedata.height.push(HeightTelevisionDetails[j]);
                      this.state.packgedata.chargableWeight.push(0);
                      this.state.packgedata.insuredValue.push(0);
                    }
                  }
                  if (x === "5") {
                  }
                });
              }
    
              api
                .post("salesLead/getManagedByPhoneOREmail", manageData)
                .then((res) => {
                  if (res.data.length > 0) {
                    ManagedBY = res.data[0].ManagedBy;
                    var counterData = {
                      PersonID: res.data[0].ManagedBy,
                    };
                    api
                      .post("userManagement/updateLeadCounter", counterData)
                      .then((counterDatares) => {});
                  } else if (
                    this.state.PersonID != "" &&
                    this.state.UserAccess == 1
                  ) {
                    var leadCountData = {
                      PersonID: this.state.PersonID,
                    };
                    api
                      .post("userManagement/updateLeadCounter", leadCountData)
                      .then((res) => {});
                  } else {
                    ManagedBY = null;
                  }
    
                  let data14 = {
                    Email: this.state.email,
                  };
                  api
                    .post("salesLead/getEmailID", data14)
                    .then((res) => {
                      if (res.success) {
                        // data.EmailID = res.data[0][0].EmailID;
                        setTimeout(() => {
                          let PackageDetails = [];
                          let CarDetails = [];
                          this.state.documentData.find((x) => {
                            if (x === "1") {
                              for (var i = 0; i < No_packBoxDetails.length; i++) {
                                var packageOBJ = {
                                  PackageType: x,
                                  ActualWeight: WeightBoxDetails[i],
                                  DimensionL: LengthBoxDetails[i],
                                  DimensionW: WidthBoxDetails[i],
                                  DimensionH: HeightBoxDetails[i],
                                  ChargeableWeight: ChargeWeightBoxDetails[i],
                                  Status: "Active",
                                  Quantity: No_packBoxDetails[i],
                                };
                                PackageDetails.push(packageOBJ);
                              }
                            }
                            if (x === "2") {
                              if (this.state.FromSelectedCountry.value == 89) {
                                var envelopOBJ = {
                                  PackageType: x,
                                  ActualWeight: 1,
                                  DimensionL: 26,
                                  DimensionW: 34,
                                  DimensionH: 3,
                                  ChargeableWeight: 1,
                                  Status: "Active",
                                  Quantity: 1,
                                };
                                PackageDetails.push(envelopOBJ);
                              } else {
                                var envelopOBJ = {
                                  PackageType: x,
                                  ActualWeight: 0.5,
                                  DimensionL: 10,
                                  DimensionW: 13,
                                  DimensionH: 1,
                                  ChargeableWeight: 1,
                                  Status: "Active",
                                  Quantity: 1,
                                };
                                PackageDetails.push(envelopOBJ);
                              }
                            }
                            if (x === "3") {
                              var packObj = {
                                PackageType: x,
                                ActualWeight: 0,
                                Status: "Active",
                                Quantity: 1,
                              };
                              PackageDetails.push(packObj);
                            }
                            if (x === "4") {
                              for (
                                var i = 0;
                                i < MakeTelevisionDetails.length;
                                i++
                              ) {
                                var packageOBJ = {
                                  PackageType: x,
                                  ActualWeight: WeightTelevisionDetails[i],
                                  DimensionL: LengthTelevisionDetails[i],
                                  DimensionW: WidthTelevisionDetails[i],
                                  DimensionH: HeightTelevisionDetails[i],
                                  ChargeableWeight: 0,
                                  Status: "Active",
                                  TVMake: MakeTelevisionDetails[i],
                                  TVModel: ModelTelevisionDetails[i],
                                  Quantity: 1,
                                };
                                PackageDetails.push(packageOBJ);
                              }
                            }
                            if (x === "5") {
                              for (var i = 0; i < MakeCarDetails.length; i++) {
                                var carOBJ = {
                                  PackageType: x,
                                  CarMake: MakeCarDetails[i],
                                  CarModel: ModelCarDetails[i],
                                  CarYear: YearCarDetails[i],
                                  Status: "Active",
                                };
                                PackageDetails.push(carOBJ);
                              }
                            }
                          });
    
                          console.log(
                            "this.state.ToCity = ",
                            this.state.ToCity,
                            " | this.state.ToFedExSelectedCity.value = ",
                            this.state.ToFedExSelectedCity.value,
                            " |this.state.ToFedExSelectedCity.label = ",
                            this.state.ToFedExSelectedCity.label,
                            " "
                          );
                          this.setState({ packdata: PackageDetails });
                          var NewTotalChargable = 0;
                          var NewChargable = 0;
    
                          for (var i = 0; i < PackageDetails.length; i++) {
                            //krutij
                            if (PackageDetails[i].ChargeableWeight) {
                              NewChargable = Number(
                                PackageDetails[i].ChargeableWeight
                              );
                            }
                            NewTotalChargable =
                              Number(NewTotalChargable) + Number(NewChargable);
                          }
                          if (this.state.SelectedWeightType !== "LB/IN") {
                            NewTotalChargable = parseInt(
                              Number(NewTotalChargable) * 2.2
                            );
                          }
                          console.log("packk1", this.state.packdata);
                          var salesLeadData = {
                            urlredirect_path: this.state.page_redirectionPath,
                            userid: 1,
                            SalesLeadManagementID: null,
                            ManagedBy:
                              this.state.SelectedOptionCountryCode.label ==
                              "India(+91)"
                                ? this.state.IndiaManagedBy
                                : ManagedBY,
                            CountryCallCode:
                              this.state.SelectedOptionCountryCode.label,
                            SalesLeadsType: null,
                            ContactName: this.state.ContactName,
                            CompanyName: "",
                            Email: this.state.email,
                            PhoneNumber: this.state.PhoneNumber,
                            FromState: this.state.FromState,
                            FromCity:
                              this.state.FromCity === ""
                                ? this.state.FromFedExSelectedCity.label
                                : this.state.FromCity,
                            ToCity:
                              this.state.ToCity === ""
                                ? this.state.ToFedExSelectedCity.label
                                : this.state.ToCity,
                            FromZipCode: this.state.FromZip,
                            ToZipCode: this.state.ToZip,
                            FromCountryID: this.state.FromSelectedCountry.value,
                            ToState: this.state.ToState,
                            ToCountryID: this.state.ToSelectedCountry.value,
                            SalesLeadDate: null,
                            SalesLeadFollowupDate: null,
                            TentativeMoveDate: null,
                            ReferredBy: this.state.premeta,
                            IPAddress: null,
                            MACAddress: null,
                            DeliveryType:
                              this.state.IsResidential == 1
                                ? "Residential"
                                : "Commercial",
                            ProposalStatus: "New",
                            PackageList: PackageDetails,
                            CarList: CarDetails,
                            NoteList: null,
                            sendTo: "Rates Shown",
                            // proposalStatus: "Auto Quote",
                            weightType:
                              this.state.SelectedWeightType == "LB/IN"
                                ? "LB"
                                : "KG",
                            EmailID: res.data[0][0].EmailID,
                          };
                          localStorage.setItem(
                            "ThankYou",
                            JSON.stringify(salesLeadData)
                          );
                          api
                            .post("salesLead/addSalesLead", salesLeadData)
                            .then((saleres) => {
                              var salesLeadID = saleres.data;
                              localStorage.setItem("LeadID", salesLeadID);
                              var TotalLength = 0;
                              var TotalWidth = 0;
                              var TotalHeight = 0;
                              var PackageDetails = [];
                              for (
                                var i = 0;
                                i < this.state.packgedata.no_pack.length;
                                i++
                              ) {
                                TotalLength += Number(this.state.packgedata.len[i]);
                                TotalWidth += Number(
                                  this.state.packgedata.width[i]
                                );
                                TotalHeight += Number(
                                  this.state.packgedata.height[i]
                                );
                                var packageOBJ = {
                                  PackageNumber: this.state.packgedata.no_pack[i],
                                  PackageWeight: this.state.packgedata.weight[i],
                                  PackageWidth: this.state.packgedata.width[i],
                                  PackageLength: this.state.packgedata.len[i],
                                  PackageHeight: this.state.packgedata.height[i],
                                  PackageChargableWeight: 0,
                                  PackageInsuredValue: 0,
                                };
                                PackageDetails.push(packageOBJ);
                              }
                              var TotalChargeWeight = 0;
                              var TotalInsuredValue = 0;
                              var TotalWeight = 0;
                              var TotalChargable = 0;
                              var ChargableWeight = [];
                              var filterFromCountryData =
                                this.state.CountryList.filter(
                                  (x) =>
                                    x.CountryID ==
                                    this.state.FromSelectedCountry.value
                                );
                              var filterToCountryData =
                                this.state.CountryList.filter(
                                  (x) =>
                                    x.CountryID ==
                                    this.state.ToSelectedCountry.value
                                );
                              if (
                                filterFromCountryData[0].CountryCode &&
                                filterToCountryData[0].CountryCode
                              ) {
                                for (var i = 0; i < PackageDetails.length; i++) {
                                  var WE = 0;
                                  var LE = 0;
                                  var HE = 0;
                                  var Insure = 0;
                                  var Total = 0;
                                  var Weight = 0;
                                  var Chargable = 0;
                                  if (PackageDetails[i].PackageWeight) {
                                    Weight =
                                      Number(PackageDetails[i].PackageWeight) *
                                      Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (PackageDetails[i].PackageWidth) {
                                    WE = Number(PackageDetails[i].PackageWidth);
                                  }
    
                                  if (PackageDetails[i].PackageLength) {
                                    LE = Number(PackageDetails[i].PackageLength);
                                  }
    
                                  if (PackageDetails[i].PackageHeight) {
                                    HE = Number(PackageDetails[i].PackageHeight);
                                  }
    
                                  if (this.state.SelectedWeightType == "KG/CM") {
                                    Total =
                                      Math.ceil(parseFloat((WE * LE * HE) / 5000)) *
                                      Number(PackageDetails[i].PackageNumber);
                                  } else {
                                    Total =
                                      Math.ceil(parseFloat((WE * LE * HE) / 139)) *
                                      Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (Weight > Total) {
                                    PackageDetails[i].PackageChargableWeight =
                                      Number(Weight);
                                    TotalChargeWeight =
                                      parseInt(TotalChargeWeight) +
                                      parseInt(Weight);
                                  } else {
                                    PackageDetails[i].PackageChargableWeight =
                                      Number(Total);
                                    TotalChargeWeight =
                                      parseInt(TotalChargeWeight) + parseInt(Total);
                                  }
    
                                  if (PackageDetails[i].PackageChargableWeight) {
                                    ChargableWeight.push(
                                      PackageDetails[
                                        i
                                      ].PackageChargableWeight.toString()
                                    );
                                  }
    
                                  if (PackageDetails[i].PackageInsuredValue) {
                                    Insure =
                                      Number(
                                        PackageDetails[i].PackageInsuredValue
                                      ) * Number(PackageDetails[i].PackageNumber);
                                  } else {
                                    PackageDetails[i].PackageInsuredValue = 0;
                                    Insure =
                                      Number(
                                        PackageDetails[i].PackageInsuredValue
                                      ) * Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (PackageDetails[i].PackageChargableWeight) {
                                    Chargable = Number(
                                      PackageDetails[i].PackageChargableWeight
                                    );
                                  }
    
                                  TotalInsuredValue =
                                    Number(TotalInsuredValue) + Number(Insure);
                                  TotalWeight =
                                    Number(TotalWeight) + Number(Weight);
                                  TotalChargable =
                                    Number(TotalChargable) + Number(Chargable);
                                }
                              }
                              var PackageNumber1 = [];
                              var Weight1 = [];
                              var DimeL1 = [];
                              var DimeW1 = [];
                              var DimeH1 = [];
                              var ChargableWeight1 = [];
                              var InsuredValues1 = [];
    
                              var PackagedetailsFinalValues = [];
    
                              var TotalPackageNumber1 = 0;
                              var TotalLength1 = 0;
                              var TotalWidth1 = 0;
                              var TotalHeight1 = 0;
                              var TotalInsuredValues1 = 0;
                              for (var i = 0; i < PackageDetails.length; i++) {
                                if (PackageDetails[i].PackageNumber) {
                                  TotalPackageNumber1 =
                                    Number(TotalPackageNumber1) +
                                    parseInt(PackageDetails[i].PackageNumber);
                                  PackageNumber1.push(
                                    PackageDetails[i].PackageNumber.toString()
                                  );
                                } else {
                                  PackageDetails[i].PackageNumber = "1";
                                  TotalPackageNumber1 =
                                    Number(TotalPackageNumber1) +
                                    parseInt(PackageDetails[i].PackageNumber);
                                  PackageNumber1.push(
                                    PackageDetails[i].PackageNumber.toString()
                                  );
                                }
                                Weight1.push(
                                  PackageDetails[i].PackageWeight.toString()
                                );
    
                                if (PackageDetails[i].PackageLength.toString()) {
                                  DimeL1.push(
                                    PackageDetails[i].PackageLength.toString()
                                  );
                                } else {
                                  PackageDetails[i].PackageLength = "1";
                                  DimeL1.push(
                                    PackageDetails[i].PackageLength.toString()
                                  );
                                }
    
                                if (PackageDetails[i].PackageWidth) {
                                  DimeW1.push(
                                    PackageDetails[i].PackageWidth.toString()
                                  );
                                } else {
                                  PackageDetails[i].PackageWidth = "1";
                                  DimeW1.push(
                                    PackageDetails[i].PackageWidth.toString()
                                  );
                                }
    
                                if (PackageDetails[i].PackageHeight) {
                                  DimeH1.push(
                                    PackageDetails[i].PackageHeight.toString()
                                  );
                                } else {
                                  PackageDetails[i].PackageHeight = "1";
                                  DimeH1.push(
                                    PackageDetails[i].PackageHeight.toString()
                                  );
                                }
    
                                if (PackageDetails[i].PackageChargableWeight) {
                                  ChargableWeight1.push(
                                    PackageDetails[
                                      i
                                    ].PackageChargableWeight.toString()
                                  );
                                }
    
                                if (PackageDetails[i].PackageInsuredValue) {
                                  InsuredValues1.push(
                                    PackageDetails[i].PackageInsuredValue.toString()
                                  );
                                } else {
                                  PackageDetails[i].PackageInsuredValue = "0";
                                  InsuredValues1.push(
                                    PackageDetails[i].PackageInsuredValue.toString()
                                  );
                                }
                                TotalLength1 += parseInt(
                                  PackageDetails[i].PackageLength
                                );
                                TotalWidth1 += parseInt(
                                  PackageDetails[i].PackageWidth
                                );
                                TotalHeight1 += parseInt(
                                  PackageDetails[i].PackageHeight
                                );
                                TotalInsuredValues1 += parseInt(
                                  PackageDetails[i].PackageInsuredValue
                                );
                              }
    
                              TotalChargeWeight = 0;
                              TotalInsuredValue = 0;
                              TotalWeight = 0;
                              TotalChargable = 0;
                              ChargableWeight = [];
                              if (
                                filterFromCountryData[0].CountryCode &&
                                filterToCountryData[0].CountryCode
                              ) {
                                for (var i = 0; i < PackageDetails.length; i++) {
                                  var WE = 0;
                                  var LE = 0;
                                  var HE = 0;
                                  var Insure = 0;
                                  var Total = 0;
                                  var Weight = 0;
                                  var Chargable = 0;
                                  if (PackageDetails[i].PackageWeight) {
                                    Weight =
                                      Number(PackageDetails[i].PackageWeight) *
                                      Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (PackageDetails[i].PackageWidth) {
                                    WE = Number(PackageDetails[i].PackageWidth);
                                  }
    
                                  if (PackageDetails[i].PackageLength) {
                                    LE = Number(PackageDetails[i].PackageLength);
                                  }
    
                                  if (PackageDetails[i].PackageHeight) {
                                    HE = Number(PackageDetails[i].PackageHeight);
                                  }
    
                                  if (this.state.SelectedWeightType == "KG/CM") {
                                    Total =
                                      Math.ceil(parseFloat((WE * LE * HE) / 5000)) *
                                      Number(PackageDetails[i].PackageNumber);
                                  } else {
                                    Total =
                                      Math.ceil(parseFloat((WE * LE * HE) / 139)) *
                                      Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (Weight > Total) {
                                    PackageDetails[i].PackageChargableWeight =
                                      Number(Weight);
                                    TotalChargeWeight =
                                      parseInt(TotalChargeWeight) +
                                      parseInt(Weight);
                                  } else {
                                    PackageDetails[i].PackageChargableWeight =
                                      Number(Total);
                                    TotalChargeWeight =
                                      parseInt(TotalChargeWeight) + parseInt(Total);
                                  }
    
                                  ChargableWeight.push(
                                    PackageDetails[
                                      i
                                    ].PackageChargableWeight.toString()
                                  );
    
                                  if (PackageDetails[i].PackageInsuredValue) {
                                    Insure =
                                      Number(
                                        PackageDetails[i].PackageInsuredValue
                                      ) * Number(PackageDetails[i].PackageNumber);
                                  } else {
                                    PackageDetails[i].PackageInsuredValue = 0;
                                    Insure =
                                      Number(
                                        PackageDetails[i].PackageInsuredValue
                                      ) * Number(PackageDetails[i].PackageNumber);
                                  }
    
                                  if (PackageDetails[i].PackageChargableWeight) {
                                    Chargable = Number(
                                      PackageDetails[i].PackageChargableWeight
                                    );
                                  }
    
                                  TotalInsuredValue =
                                    Number(TotalInsuredValue) + Number(Insure);
                                  TotalWeight =
                                    Number(TotalWeight) + Number(Weight);
                                  TotalChargable =
                                    Number(TotalChargable) + Number(Chargable);
                                }
                              }
                              var checkWeight = 50;
                              this.state.TotalChargableSend = TotalChargable;
    
                              if (this.state.SelectedWeightType == "LB/IN") {
                                checkWeight = 50;
                              }
                              // else if (
                              //   this.state.SelectedWeightType == "KG/CM" &&
                              //   filterFromCountryData[0].CountryCode == "IN"
                              // ) {
                              //   checkWeight = this.state.ratestoIndia;
                              // }
                              else if (this.state.SelectedWeightType == "KG/CM") {
                                checkWeight = 25;
                              } else {
                                checkWeight = 50;
                              }
    
                              console.log(
                                "Test from = ",
                                this.state.ThankyouPageMoveFromZip
                              );
                              console.log(
                                "Test to = ",
                                this.state.ThankyouPageMoveToZip
                              );
    
                              if (
                                this.state.FromSelectedCountry.value == 89 &&
                                this.state.ToSelectedCountry.value != 202
                              ) {
                                this.hideLoader();
                                console.log("Testing Here");
                                window.location = nextpageurl;
                              } else {
                                if (
                                  TotalChargable < checkWeight &&
                                  this.state.ThankyouPageMoveFromZip == false &&
                                  this.state.ThankyouPageMoveToZip == false
                                ) {
                                  var CurrDate = new Date();
                                  var addDay = 0;
    
                                  console.log(
                                    "CurrDate.getDay() = ",
                                    CurrDate.getDay()
                                  );
                                  if (CurrDate.getDay() == 0) {
                                    addDay = 2;
                                  }
                                  if (CurrDate.getDay() == 6) {
                                    addDay = 3;
                                  }
    
                                  console.log("addDay = ", addDay);
    
                                  var shipDateToSend = "";
                                  if (addDay != 0) {
                                    var shipDateToSend12 = CurrDate.setDate(
                                      CurrDate.getDate() + addDay
                                    );
                                    shipDateToSend = new Date(
                                      shipDateToSend12
                                    ).toISOString();
                                  } else {
                                    shipDateToSend = new Date().toISOString();
                                  }
    
                                  console.log("DatestoSend = ", shipDateToSend);
                                  var data = {
                                    data: {
                                      quoteData: {
                                        PackageType: this.state.packagetype,
                                        WeightType:
                                          this.state.SelectedWeightType == "LB/IN"
                                            ? "LB"
                                            : "KG",
                                        SelectedWeightType:
                                          this.state.SelectedWeightType == "LB/IN"
                                            ? "LB"
                                            : "KG",
                                        UpsData: {
                                          FromCountry: JSON.stringify(
                                            filterFromCountryData[0]
                                          ),
                                          FromCity:
                                            this.state.FromCity === ""
                                              ? this.state.FromFedExSelectedCity
                                                  .label
                                              : this.state.FromCity,
                                          FromUPSCity:
                                            this.state.FromUPSSelectedCity,
                                          FromFedExCity:
                                            this.state.FromFedExSelectedCity,
                                          FromZipCode: this.state.FromZip,
                                          FromStateProvinceCode:
                                            this.state.fromStateName,
                                          ToCountry: JSON.stringify(
                                            filterToCountryData[0]
                                          ),
                                          ToCity:
                                            this.state.ToCity === ""
                                              ? this.state.ToFedExSelectedCity.label
                                              : this.state.ToCity,
                                          ToUPSCity: null,
                                          ToFedExCity: null,
                                          ToZipCode: this.state.ToZip,
                                          ToStateProvinceCode: "",
                                        },
    
                                        PackageNumber:
                                          this.state.packgedata.no_pack,
                                        Weight: this.state.packgedata.weight,
                                        DimeL: this.state.packgedata.len,
                                        DimeW: this.state.packgedata.width,
                                        DimeH: this.state.packgedata.height,
                                        TotalLength: TotalLength,
                                        TotalWidth: TotalWidth,
                                        TotalInsuredValues: TotalInsuredValue,
                                        TotalHeight: TotalHeight,
                                        ChargableWeight: ChargableWeight,
                                        InsuredValues:
                                          this.state.packgedata.insuredValue,
    
                                        TotalWeight: TotalChargable,
                                        IsPickUp: false,
                                        WeightCount:
                                          this.state.packgedata.weight.length,
                                        LengthCount:
                                          this.state.packgedata.len.length,
                                        WidthCount:
                                          this.state.packgedata.width.length,
                                        HeightCount:
                                          this.state.packgedata.height.length,
                                        PackCount:
                                          this.state.packgedata.no_pack.length,
                                        PackageDetailsCount:
                                          this.state.packgedata.no_pack.length,
                                        PackageDetailsText:
                                          this.state.packgedata.no_pack.length,
                                        EnvelopeWeightLBSText: TotalChargable
                                          ? TotalChargable
                                          : 0,
                                        ShipDate: shipDateToSend,
    
                                        PackageDetails: PackageDetails,
                                        AgentCode: 1,
                                      },
                                    },
                                  };
                                  if (
                                    this.state.IsResidential === true ||
                                    this.state.IsResidential === 1
                                  ) {
                                    data.data.quoteData.IsResidencial = true;
                                  } else {
                                    data.data.quoteData.IsResidencial = false;
                                  }
                                  data.data.quoteData.PackageNumber =
                                    PackageNumber1;
                                  data.data.quoteData.Weight = Weight1;
                                  data.data.quoteData.DimeL = DimeL1;
                                  data.data.quoteData.DimeW = DimeW1;
                                  data.data.quoteData.DimeH = DimeH1;
                                  data.data.quoteData.TotalLength = TotalLength1;
                                  data.data.quoteData.TotalWidth = TotalWidth1;
                                  data.data.quoteData.TotalInsuredValues =
                                    TotalInsuredValues1;
                                  data.data.quoteData.TotalHeight = TotalHeight1;
                                  data.data.quoteData.ChargableWeight =
                                    ChargableWeight1;
                                  data.data.quoteData.InsuredValues =
                                    InsuredValues1;
                                  data.data.quoteData.WeightCount = Weight1.length;
                                  data.data.quoteData.LengthCount = DimeL1.length;
                                  data.data.quoteData.WidthCount = DimeW1.length;
                                  data.data.quoteData.HeightCount = DimeH1.length;
    
                                  data.data.quoteData.PackCount =
                                    TotalPackageNumber1.toString();
                                  data.data.quoteData.PackageDetailsCount =
                                    TotalPackageNumber1;
                                  data.data.quoteData.PackageDetailsText =
                                    TotalPackageNumber1.toString();
                                  let imgArray = [];
    
                                  console.log("Rates= ", data.data);
                                  api.post("getQuote/GetRateImages").then((msg) => {
                                    imgArray = msg.data[0];
                                    api
                                      .post("getQuote/getRates", data.data)
                                      .then((msg) => {
                                        this.hideLoader();
    
                                        if (
                                          msg.data.length === 1 &&
                                          msg.data[0].Service_Type === "Fedex Error"
                                        ) {
                                          this.hideLoader();
                                          window.location = nextpageurl;
                                        } else {
                                          for (
                                            var i = 0;
                                            i < msg.data.length;
                                            i++
                                          ) {
                                            if (
                                              msg.data[i].MainServiceName == "" ||
                                              msg.data[i].MainServiceName ==
                                                undefined
                                            ) {
                                              if (
                                                msg.data[
                                                  i
                                                ].ServiceDisplayName.includes(
                                                  "FEDEX"
                                                )
                                              ) {
                                                msg.data[i].MainServiceName =
                                                  "FedEx";
                                              }
                                            }
                                          }
                                          let contentText = "Boxes";
                                          if (
                                            this.state.packagetype == "Envelope"
                                          ) {
                                            contentText = "Envelop";
                                            TotalChargable = 0.5;
                                          }
    
                                          var arr1 = [];
                                          var arr2 = [];
                                          msg.data.forEach((obj) => {
                                            if (obj.IsError) {
                                              if (
                                                obj.Delivery_Date !==
                                                "Service was validated at the country level, but might not be valid for the actual intended city for the destination."
                                              ) {
                                                arr2.push(obj);
                                              }
                                            } else {
                                              if (
                                                obj.Delivery_Date !==
                                                "Service was validated at the country level, but might not be valid for the actual intended city for the destination."
                                              ) {
                                                arr1.push(obj);
                                              }
                                            }
                                          });
                                          var msg = [];
                                          msg.data = arr1.concat(arr2);
    
                                          for (
                                            var idx = 0;
                                            idx < msg.data.length;
                                            idx++
                                          ) {
                                            const element = msg.data[idx];
                                            let isError = element.IsError;
    
                                            let Baseurl = window.btoa(
                                              msg.data[idx].ServiceType +
                                                "/" +
                                                msg.data[idx].MainServiceName +
                                                "/" +
                                                msg.data[idx].ServiceDisplayName +
                                                "/" +
                                                msg.data[idx].Rates.toFixed(2) +
                                                ""
                                            );
    
                                            let imgUrl = imgArray.filter(
                                              (x) =>
                                                x.MainServiceName ===
                                                element.MainServiceName
                                            );
    
                                            if (
                                              msg.data[idx].MainServiceName ==
                                              "FedEx"
                                            ) {
                                              console.log(
                                                "msg.data[idx] = ",
                                                msg.data[idx].BaseP
                                              );
    
                                              var totalValue =
                                                ((msg.data[idx].BaseP -
                                                  msg.data[idx].Rates) /
                                                  msg.data[idx].BaseP) *
                                                100;
                                              totalValue = totalValue.toFixed(2);
                                              totalValue = Math.round(totalValue);
    
                                              element.discountPercentage =
                                                totalValue;
                                              console.log("Percent ", totalValue);
                                              msg.data[idx].Discounts =
                                                totalValue + "%";
                                            }
    
                                            msg.data[idx].urlIMG = imgUrl[0].IMGURL;
                                            let bookNowURL =
                                              bookurl +
                                              Baseurl +
                                              `?saleid=` +
                                              encodeURIComponent(salesLeadID);
                                            this.state.tempFinalImage.push(
                                              <div className="search-result-row">
                                                <div className="provide-img">
                                                  <span>
                                                    <img
                                                      src={imgUrl[0].IMGURL}
                                                    ></img>
                                                  </span>
                                                </div>
                                                <div className="provider-name">
                                                  <h3>
                                                    {element.ServiceDisplayName}
                                                  </h3>
                                                </div>
                                                <div className="est-date">
                                                  <span>Estimated Delivery</span>
                                                  <p>{element.Delivery_Date}</p>
                                                </div>
    
                                                <div className="rates-discount">
                                                  {element.MainServiceName ==
                                                    "FedEx" &&
                                                  element.BaseP > 0 &&
                                                  element.BaseP > element.Rates ? (
                                                    <div className="est-discount">
                                                      <span>
                                                        Save{" "}
                                                        {element.discountPercentage}
                                                        %
                                                      </span>
                                                      <p>{element.BaseP} retail</p>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* {element.BaseP > element.Rates ? (
                                                        <div className="est-discount">
                                                          <span>
                                                            Save {element.discountPercentage}%
                                                          </span>
                                                          <p>{element.BaseP} retail</p>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )} */}
                                                </div>
    
                                                <div className="quote-price">
                                                  <h3>
                                                    {element.Rates.toFixed(2)}
                                                  </h3>
                                                </div>
    
                                                <div className="book-quote">
                                                  {element.Delivery_Date !==
                                                  "Invalid Destination Postal Code." ? (
                                                    <a
                                                      href={bookNowURL}
                                                      className="next-btn"
                                                    >
                                                      Book Now{" "}
                                                      <img
                                                        src={arrowRightWhite}
                                                        alt="Next"
                                                      />
                                                    </a>
                                                  ) : (
                                                    <div className="Disablebook-quote a">
                                                      <a className="next-btn">
                                                        Book Now{" "}
                                                        <img
                                                          src={arrowRightWhite}
                                                          alt="Next"
                                                        />
                                                      </a>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                          this.setState({
                                            finalImage: this.state.tempFinalImage,
                                          });
    
                                          let LeadDetails = {
                                            FromCountryValue: this.state
                                              .SelectedOption
                                              ? this.state.SelectedOption
                                              : this.state.USSelectedOption,
                                            FromZipCode: this.state.FromZip,
                                            ToCountryValue: this.state
                                              .SelectedOptionTo
                                              ? this.state.SelectedOptionTo
                                              : this.state.ToSelectedCountry,
    
                                            FromCity:
                                              this.state.FromCity === ""
                                                ? this.state.FromFedExCityValue
                                                    .label
                                                : this.state.FromCity,
                                            ToCity:
                                              this.state.ToCity === ""
                                                ? this.state.ToFedExCityValue.label
                                                : this.state.ToCity,
    
                                            Weight: this.state.TotalChargableSend,
                                            WeightType:
                                              this.state.SelectedWeightType ==
                                              "LB/IN"
                                                ? "LB"
                                                : "KG",
                                            ChargableWeight:
                                              data.data.quoteData.ChargableWeight,
                                            ToZipCode: this.state.ToZip,
                                            PackageTypeValue:
                                              this.state.packagetype,
                                            ContactName: this.state.ContactName,
                                            Email: this.state.email,
                                            PhoneNumber: this.state.PhoneNumber,
                                          };
                                          localStorage.removeItem("gerRates");
                                          localStorage.setItem(
                                            "gerRates",
                                            JSON.stringify(msg.data)
                                          );
                                          localStorage.setItem(
                                            "salesLeadID",
                                            salesLeadID
                                          );
                                          localStorage.setItem(
                                            "LeadDetails",
                                            JSON.stringify(LeadDetails)
                                          );
    
                                          localStorage.removeItem("packagedetails");
                                          localStorage.setItem(
                                            "packagedetails",
                                            JSON.stringify(this.state.packdata)
                                          );
    
                                          let mailData = {
                                            SalesLeadID: salesLeadID,
                                            ChargableWeight: ChargableWeight,
                                            RateData: msg.data,
                                            IsResidential: this.state.IsResidential,
                                            RateType: "Rates",
                                            WeightType:
                                              this.state.SelectedWeightType ==
                                              "LB/IN"
                                                ? "LB"
                                                : "KG",
                                                currencyType:this.state.setCurrencyIcon
                                          };
                                          api
                                            .post(
                                              "salesLead/sendGetRateEmail",
                                              mailData
                                            )
                                            .then((mailres) => {
                                              if (mailres.success) {
                                                this.CallGetRate();
                                              } else {
                                                this.CallGetRate();
                                                cogoToast.error("errorrrrrrr");
                                              }
                                            });
                                        }
                                      });
                                  });
                                } else {
                                  this.hideLoader();
                                  console.log("Testing Here");
                                  window.location = nextpageurl;
                                }
                              }
                            });
                        }, 3000);
                      }
                    })
                    .catch((err) => {
                      console.log("error.....", err);
                    });
                });
            } else {
              // Box Details
              var nopack = [];
              var temp = document.getElementsByName("BoxDetails[No_Pack]");
              var boxarray = [].concat.apply(nopack, temp);
              No_packBoxDetails = boxarray.map((x) => {
                return x.value;
              });
              var weightpack = [];
              var temp = document.getElementsByName("BoxDetails[Weight]");
              var boxweightarray = [].concat.apply(weightpack, temp);
              WeightBoxDetails = boxweightarray.map((x) => {
                return x.value;
              });
              var lengthpack = [];
              var temp = document.getElementsByName("BoxDetails[Length]");
              var lengthboxarray = [].concat.apply(lengthpack, temp);
              LengthBoxDetails = lengthboxarray.map((x) => {
                return x.value;
              });
              var widthpack = [];
              var temp = document.getElementsByName("BoxDetails[Width]");
              var widthboxarray = [].concat.apply(widthpack, temp);
              WidthBoxDetails = widthboxarray.map((x) => {
                return x.value;
              });
              var heightpack = [];
              var temp = document.getElementsByName("BoxDetails[Height]");
              var heightboxarray = [].concat.apply(heightpack, temp);
              HeightBoxDetails = heightboxarray.map((x) => {
                return x.value;
              });
    
              var carmake = [];
              var temp = document.getElementsByName("CarDetails[Make]");
              var makecararray = [].concat.apply(carmake, temp);
              MakeCarDetails = makecararray.map((x) => {
                return x.value;
              });
              var modelcar = [];
              var temp = document.getElementsByName("CarDetails[Model]");
              var modelcararray = [].concat.apply(modelcar, temp);
              ModelCarDetails = modelcararray.map((x) => {
                return x.value;
              });
              var yearcar = [];
              var temp = document.getElementsByName("CarDetails[Year]");
              var yearcararray = [].concat.apply(yearcar, temp);
              YearCarDetails = yearcararray.map((x) => {
                return x.value;
              });
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Make]");
              var maketelearray = [].concat.apply(notele, temp);
              var No_packTelevisionDetails = maketelearray.map((x) => {
                return x.value;
              });
    
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Make]");
              var makenewtelearray = [].concat.apply(notele, temp);
              MakeTelevisionDetails = makenewtelearray.map((x) => {
                return x.value;
              });
              var notele = [];
              var temp = document.getElementsByName("TelevisionDetails[Model]");
              var modelnewtelearray = [].concat.apply(notele, temp);
              ModelTelevisionDetails = modelnewtelearray.map((x) => {
                return x.value;
              });
              var weighttele = [];
              var temp = document.getElementsByName("TelevisionDetails[Weight]");
              var weighttelearray = [].concat.apply(weighttele, temp);
              WeightTelevisionDetails = weighttelearray.map((x) => {
                return x.value;
              });
              var lengthtele = [];
              var temp = document.getElementsByName("TelevisionDetails[Length]");
              var lengthtelearray = [].concat.apply(lengthtele, temp);
              LengthTelevisionDetails = lengthtelearray.map((x) => {
                return x.value;
              });
              var widthtele = [];
              var temp = document.getElementsByName("TelevisionDetails[Width]");
              var widthtelearray = [].concat.apply(widthtele, temp);
              var WidthTelevisionDetails = widthtelearray.map((x) => {
                return x.value;
              });
    
              var heighttele = [];
              var temp = document.getElementsByName("TelevisionDetails[Height]");
              var heighttelearray = [].concat.apply(heighttele, temp);
              var HeightTelevisionDetails = heighttelearray.map((x) => {
                return x.value;
              });
              var doc = [];
              var temp = document.getElementsByName("DocumnetDetails[]");
              var docarray = [].concat.apply(doc, temp);
              var DocumnetDetails = docarray.map((x) => {
                return x.value;
              });
              console.log("Wlcome Here");
              var PackageRes = [];
              var PersonList = [];
              var finalManagedBy = [];
              var ManagedBY = "";
              var Access = 0;
              var elsecalled = 0;
              var PackageName = "";
              var manageData = {};
              var newpackagetype = "";
    
              if (this.state.documentData[0] == 2) {
                newpackagetype = "Document";
              }
              if (this.state.documentData[0] == 1) {
                newpackagetype = "Boxes";
              }
              if (this.state.documentData[0] == 4) {
                newpackagetype = "Television";
              }
              if (this.state.documentData[0] == 3) {
                newpackagetype = "Furniture";
              }
              if (this.state.documentData[0] == 5) {
                newpackagetype = "Auto";
              }
              {
                this.state.PersonID
                  ? (manageData = {
                      Email: this.state.email,
                      Phone: this.state.PhoneNumber,
                      PersonID: this.state.PersonID,
                      newpackagetype: newpackagetype,
                    })
                  : (manageData = {
                      Email: this.state.email,
                      Phone: this.state.PhoneNumber,
                      PersonID: 0,
                      newpackagetype: newpackagetype,
                    });
              }
    
              this.showLoader();
              api
                .post("salesLead/getManagedByPhoneOREmail", manageData)
                .then((res) => {
                  if (res.data.length > 0) {
                    var counterData = {
                      PersonID: res.data[0].ManagedBy,
                    };
                    ManagedBY = res.data[0].ManagedBy;
                    api
                      .post("userManagement/updateLeadCounter", counterData)
                      .then((res) => {});
                  } else if (
                    this.state.PersonID != "" &&
                    this.state.UserAccess == 1
                  ) {
                    var leadCountData = {
                      PersonID: res.data[0].ManagedBy,
                    };
    
                    api
                      .post("userManagement/updateLeadCounter", leadCountData)
                      .then((res) => {});
                  } else {
                    ManagedBY = null;
                  }
    
                  let data14 = {
                    Email: this.state.email,
                  };
                  api
                    .post("salesLead/getEmailID", data14)
                    .then((res) => {
                      if (res.success) {
                        // data.EmailID = res.data[0][0].EmailID;
    
                        setTimeout(() => {
                          let PackageDetails = [];
                          let CarDetails = [];
                          this.state.documentData.find((x) => {
                            if (x === "1") {
                              for (var i = 0; i < No_packBoxDetails.length; i++) {
                                var packageOBJ = {
                                  PackageType: x,
                                  ActualWeight: WeightBoxDetails[i],
                                  DimensionL: LengthBoxDetails[i],
                                  DimensionW: WidthBoxDetails[i],
                                  DimensionH: HeightBoxDetails[i],
                                  ChargeableWeight: 0,
                                  Status: "Active",
                                  Quantity: No_packBoxDetails[i],
                                };
                                PackageDetails.push(packageOBJ);
                              }
                            }
                            if (x === "2") {
                              if (this.state.FromSelectedCountry.value == 89) {
                                var envelopOBJ = {
                                  PackageType: x,
                                  ActualWeight: 1,
                                  DimensionL: 26,
                                  DimensionW: 34,
                                  DimensionH: 3,
                                  ChargeableWeight: 1,
                                  Status: "Active",
                                  Quantity: 1,
                                };
                                PackageDetails.push(envelopOBJ);
                              } else {
                                var envelopOBJ = {
                                  PackageType: x,
                                  ActualWeight: 0.5,
                                  DimensionL: 10,
                                  DimensionW: 13,
                                  DimensionH: 1,
                                  ChargeableWeight: 1,
                                  Status: "Active",
                                  Quantity: 1,
                                };
                                PackageDetails.push(envelopOBJ);
                              }
                            }
                            if (x === "3") {
                              var packObj = {
                                PackageType: x,
                                ActualWeight: 0,
                                Status: "Active",
                                Quantity: 1,
                              };
                              PackageDetails.push(packObj);
                            }
                            if (x === "4") {
                              for (
                                var i = 0;
                                i < MakeTelevisionDetails.length;
                                i++
                              ) {
                                var packageOBJ = {
                                  PackageType: x,
                                  ActualWeight: WeightTelevisionDetails[i],
                                  DimensionL: LengthTelevisionDetails[i],
                                  DimensionW: WidthTelevisionDetails[i],
                                  DimensionH: HeightTelevisionDetails[i],
                                  ChargeableWeight: 0,
                                  Status: "Active",
                                  TVMake: MakeTelevisionDetails[i],
                                  TVModel: ModelTelevisionDetails[i],
                                  Quantity: 1,
                                };
                                PackageDetails.push(packageOBJ);
                              }
                            }
                            if (x === "5") {
                              for (var i = 0; i < MakeCarDetails.length; i++) {
                                var carOBJ = {
                                  PackageType: x,
                                  CarMake: MakeCarDetails[i],
                                  CarModel: ModelCarDetails[i],
                                  CarYear: YearCarDetails[i],
                                  Status: "Active",
                                };
                                PackageDetails.push(carOBJ);
                              }
                            }
                          });
                          this.setState({ packdata: PackageDetails });
                          console.log("packk", this.state.packdata);
                          var salesLeadData = {
                            urlredirect_path: this.state.page_redirectionPath,
                            userid: 1,
                            SalesLeadManagementID: null,
                            ManagedBy:
                              this.state.SelectedOptionCountryCode.label ==
                              "India(+91)"
                                ? this.state.IndiaManagedBy
                                : ManagedBY,
                            SalesLeadsType: null,
                            CountryCallCode:
                              this.state.SelectedOptionCountryCode.label,
                            ContactName: this.state.ContactName,
                            CompanyName: "",
                            Email: this.state.email,
                            PhoneNumber: this.state.PhoneNumber,
                            FromState: this.state.FromState,
                            FromCity:
                              this.state.FromCity === ""
                                ? this.state.FromFedExSelectedCity.label
                                : this.state.FromCity,
                            ToCity:
                              this.state.ToCity === ""
                                ? this.state.ToFedExSelectedCity.label
                                : this.state.ToCity,
                            FromZipCode: this.state.FromZip,
                            ToZipCode: this.state.ToZip,
                            FromCountryID: this.state.FromSelectedCountry.value,
                            ToState: this.state.ToState,
                            ToCountryID: this.state.ToSelectedCountry.value,
                            SalesLeadDate: null,
                            SalesLeadFollowupDate: null,
                            TentativeMoveDate: null,
                            ReferredBy: this.state.premeta,
                            IPAddress: null,
                            MACAddress: null,
                            DeliveryType:
                              this.state.IsResidential == 1
                                ? "Residential"
                                : "Commercial",
                            ProposalStatus: "New",
                            PackageList: PackageDetails,
                            CarList: CarDetails,
                            NoteList: null,
                            sendTo: "Thank you",
                            // proposalStatus: "New",
                            weightType:
                              this.state.SelectedWeightType == "LB/IN"
                                ? "LB"
                                : "KG",
                            EmailID: res.data[0][0].EmailID,
                          };
    
                          api
                            .post("salesLead/addSalesLead", salesLeadData)
                            .then((saleres) => {
                              this.hideLoader();
                              var salesLeadID = saleres.data;
                              localStorage.setItem("LeadID", salesLeadID);
                              console.log("Wc Here");
                              localStorage.setItem(
                                "ThankYou",
                                JSON.stringify(salesLeadData)
                              );
                              window.location = nextpageurl;
                            });
                        }, 3000);
                      }
                    })
                    .catch((err) => {
                      console.log("error.....", err);
                    });
                });
            }
          }
        }
      }
    };

  navigateChange = (key) => {
    debugger
    let stepsList = this.state.Steps;
    let activeIndex = stepsList.findIndex((x) => x.classname === "active");
    this.setState({ activeIndex: activeIndex });
    if (
      activeIndex == 0 &&
      this.state.Steps[activeIndex].stepId == "shipperdetails"
    ) {
      if (this.state.packdetailsChage != "") {
        this.state.stepChanges = 0;

        if (
          this.state.ShowEnvelop == true ||
          this.state.showfurniture == true
        ) {
          var zipvalData = 0;

          if (
            this.state.FromSelectedCountry.label != "" &&
            this.state.FromIsFedxCity == 0 &&
            this.state.FromZip == ""
          ) {
            stepValidate = false;
            this.setState({
              FromZipErr: true,
              FromZipErrText: "Please enter From Zip",
            });
            zipvalData = 1;
          }

          if (
            this.state.ToSelectedCountry.label != "" &&
            this.state.ToIsFedxCity == 0 &&
            this.state.ToZip == ""
          ) {
            this.setState({
              ToZipErr: true,
              ToZipErrText: "Please enter To Zip",
            });
            zipvalData = 1;
          }

          if (
            this.state.ToIsFedxCity == 1 &&
            this.state.ToFedExSelectedCity == ""
          ) {
            this.setState({
              ToCityErr: true,
              ToCityErrText: "Please enter to city",
            });
            zipvalData = 1;
          }

          if (
            this.state.FromIsFedxCity == 1 &&
            this.state.FromFedExSelectedCity == ""
          ) {
            this.setState({
              FromCityErr: true,
              FromFedExCityValueErr: "Please enter from city",
            });
            zipvalData = 1;
          }

          if (zipvalData == 0) {
            this.state.stepChanges = 1;
            var elementdivs = document.getElementById("nave");
            elementdivs.classList.add("three-boxes");
            document.getElementById("step4").className = "active";
            document.getElementById("step3").className = "";
            key = key + 2;
          } else {
            return;
          }
        } else {
          var zipvalData = 0;

          if (
            this.state.FromSelectedCountry.label != "" &&
            this.state.FromIsFedxCity == 0 &&
            this.state.FromZip == ""
          ) {
            stepValidate = false;
            this.setState({
              FromZipErr: true,
              FromZipErrText: "Please enter From Zip",
            });
            zipvalData = 1;
          }

          if (
            this.state.ToSelectedCountry.label != "" &&
            this.state.ToIsFedxCity == 0 &&
            this.state.ToZip == ""
          ) {
            this.setState({
              ToZipErr: true,
              ToZipErrText: "Please enter To Zip",
            });
            zipvalData = 1;
          }

          if (
            this.state.ToIsFedxCity == 1 &&
            this.state.ToFedExSelectedCity == ""
          ) {
            this.setState({
              ToCityErr: true,
              ToCityErrText: "Please enter to city",
            });
            zipvalData = 1;
          }

          if (
            this.state.FromIsFedxCity == 1 &&
            this.state.FromFedExSelectedCity == ""
          ) {
            this.setState({
              FromCityErr: true,
              FromFedExCityValueErr: "Please enter from city",
            });
            zipvalData = 1;
          }

          if (zipvalData == 0) {
            key = key + 1;
            this.state.stepChanges = 0;
            document.getElementById("step2").className = "active";
            document.getElementById("step3").className = "";
          } else {
            return;
          }
        }
      } else {
        var zipval = 0;
        if (this.state.ToSelectedCountry.label == "") {
          zipval = 1;
          stepValidate = false;
          this.setState({
            ToCountryErr: true,
            ToCountryErrText: "Please select To Country",
          });
        }

        if (
          this.state.FromSelectedCountry.label != "" &&
          this.state.FromIsFedxCity == 0 &&
          this.state.FromZip == ""
        ) {
          stepValidate = false;
          this.setState({
            FromZipErr: true,
            FromZipErrText: "Please enter From Zip",
          });
          zipval = 1;
        }

        if (
          this.state.ToSelectedCountry.label != "" &&
          this.state.ToIsFedxCity == 0 &&
          this.state.ToZip == ""
        ) {
          this.setState({
            ToZipErr: true,
            ToZipErrText: "Please enter To Zip",
          });
          zipval = 1;
        }

        if (
          this.state.ToIsFedxCity == 1 &&
          this.state.ToFedExSelectedCity == ""
        ) {
          this.setState({
            ToCityErr: true,
            ToCityErrText: "Please enter to city",
          });
          zipval = 1;
        }

        if (
          this.state.FromIsFedxCity == 1 &&
          this.state.FromFedExSelectedCity == ""
        ) {
          this.setState({
            FromCityErr: true,
            FromFedExCityValueErr: "Please enter from city",
          });
          zipval = 1;
        }

        if (zipval == 0) {
          document.getElementById("step1").className = "active";
          document.getElementById("step3").className = "";
        } else {
          return;
        }
        //
      }
    } else if (
      (activeIndex == 1 || activeIndex == 2) &&
      this.state.Steps[activeIndex].stepId == "packagedetails"
    ) {
      var envelope = document.getElementById("envelope");
      var box = document.getElementById("box");
      var television = document.getElementById("television");
      var furniture = document.getElementById("furniture");
      var auto = document.getElementById("auto");
      // var packageDetails = document.getElementById("packageDetails");
      if (
        envelope.classList.contains("active") ||
        box.classList.contains("active") ||
        television.classList.contains("active") ||
        furniture.classList.contains("active") ||
        auto.classList.contains("active")
      ) {
        // if (this.state.packagetype != "") {

        if (
          (envelope.classList.contains("active") &&
            this.state.ShowEnvelop == true) ||
          (furniture.classList.contains("active") &&
            this.state.showfurniture == true)
        ) {
          this.state.stepChanges = 1;
          // if(type == "envelope"){
          var elementdivs = document.getElementById("nave");
          elementdivs.classList.add("three-boxes");
          // }

          key = key + 1;
          document.getElementById("step4").className = "active";
          document.getElementById("step1").className = "";
        } else {
          console.log("Ok");
          this.state.stepChanges = 0;
          var elementdivs = document.getElementById("nave");
          elementdivs.classList.remove("three-boxes");
          document.getElementById("step2").className = "active";
          document.getElementById("step1").className = "";
        }
      } else {
        var envelope = document.getElementById("envelope");
        var box = document.getElementById("box");
        var television = document.getElementById("television");
        var furniture = document.getElementById("furniture");
        var auto = document.getElementById("auto");
        // var packageDetails = document.getElementById("packageDetails");
        if (
          envelope.classList.contains("active") ||
          box.classList.contains("active") ||
          television.classList.contains("active") ||
          furniture.classList.contains("active") ||
          auto.classList.contains("active")
        ) {
          document.getElementById("step2").className = "active";
          document.getElementById("step1").className = "";
        } else {
          document.getElementById("referrederror").style.display = "block";
          this.setState({
            erorrmsg: "Please select shipment content",
          });
          return;
        }
      }
    } else if (
      activeIndex == 2 &&
      this.state.Steps[activeIndex].stepId == "shipmentdetails"
    ) {
      var checkPackagesval = 0;
      if (this.state.showBoxDetails === true) {
        for (let index = 0; index < this.state.boxDetails.length - 1; index++) {
          var weight_pack = document.getElementById(
            "weight_pack" + index
          ).value;
          if (weight_pack == "") {
            checkPackagesval = 1;
            this.setState({
              BoxesErrText: "Please enter Weight",
            });
          } else if (weight_pack == 0) {
            checkPackagesval = 1;
            this.setState({
              BoxesErrText: "Please enter valid weight",
            });
          }
        }
      }

      if (this.state.showTVDetails === true) {
        for (let index = 0; index < this.state.tvDetails.length; index++) {
          var no_packteli0 = document.getElementById(
            "no_packteli" + index
          ).value;
          var model_teli0 = document.getElementById("model_teli" + index).value;
          var wight_packteli = document.getElementById(
            "wight_packteli" + index
          ).value;

          if (wight_packteli == 0) {
            checkPackagesval = 1;
            this.setState({
              TelevisionErrText: "Please enter valid weight",
            });
          }
          if (wight_packteli == "") {
            checkPackagesval = 1;
            this.setState({
              TelevisionErrText: "Please enter Tv weight",
            });
          }
          if (model_teli0 == "") {
            checkPackagesval = 1;
            this.setState({
              TelevisionErrText: "Please enter TV model",
            });
          }
          if (no_packteli0 == "") {
            checkPackagesval = 1;
            this.setState({
              TelevisionErrText: "Please enter Brand Name",
            });
          }
        }
      }

      if (this.state.showCarDetails === true) {
        for (let index = 0; index < this.state.carDetails.length; index++) {
          // const element = array[index];

          let carMake = document.getElementById("car_make" + index).value;

          let car_model = document.getElementById("car_model" + index).value;

          let car_year = document.getElementById("car_year" + index).value;

          if (car_year == "" || car_year == "Select") {
            checkPackagesval = 1;
            this.setState({
              CarErrText: "Please enter Auto car year",
            });
          }
          if (car_model == "") {
            checkPackagesval = 1;
            this.setState({
              CarErrText: "Please enter Auto car model",
            });
          }
          if (carMake == "") {
            checkPackagesval = 1;
            this.setState({
              CarErrText: "Please enter Auto Brand Name",
            });
          }
        }
      }

      if (checkPackagesval == 0) {
        document.getElementById("step4").className = "active";
        document.getElementById("step2").className = "";
      } else {
        return;
      }
    } else if (
      activeIndex == 3 &&
      this.state.Steps[activeIndex].stepId == "shipsmart"
    ) {
      var val = 0;
      if (this.state.ContactName == "") {
        this.setState({
          cnameErrText: "Please enter contact name",
        });
        val = 1;
      }
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.state.email == "" || !emailRegex.test(this.state.email)) {
        this.setState({
          EmailErrText: "Please enter email",
        });
        val = 1;
      }
      if (this.state.PhoneNumber == "") {
        this.setState({
          // cnameErrText: "Please enter contact name",
          ContactNumberErrText: "Please enter phone number",
          // EmailErrText: "Please enter email",
        });
        val = 1;
      } else {
        if (this.state.PhoneNumber.length == 10) {
          val = 0;
        } else {
          this.setState({
            // cnameErrText: "Please enter contact name",
            ContactNumberErrText: "Please enter valid phone number",
            // EmailErrText: "Please enter email",
          });
          val = 1;
        }
      }
      if (val == 0) {
        this.finalStep(key);
      } else {
        return;
      }
    }

    if (key !== activeIndex) {
      this.setState({ NextButtonIdx: key });
      stepsList[key]["classname"] = "active";
      stepsList[activeIndex]["classname"] = "inactive";
      this.setState({ Steps: stepsList });

      let divID = stepsList[key]["stepId"];
      let activeDiv = stepsList[activeIndex]["stepId"];

      document.getElementById(divID).style.display = "block";
      document.getElementById(activeDiv).style.display = "none";
    }
  };
  render() {
    const { allpackagesValue, submitList, allAutoValue, allTelevisionValue } =
      this.state;
    const dataaa = "Hello from ParentComponent!";
    const AllRatesDetails = this.state.AllRatesDetails;
    var ToFedExCityListDisplay = this.state.ToFedExCityList.map((city) => {
      return { value: city.CityCode, label: city.CityName };
    });
    var FromFedExCityListDisplay = this.state.FromFedExCityList.map((city) => {
      return { value: city.CityCode, label: city.CityName };
    });

    return (
      <div className="get-quote-wrap">
        {this.state.getRatesModule === true ? (
          <GetRate AllRatesDetails={AllRatesDetails} dataaa={dataaa} />
        ) : null}

        {this.state.Loading === true ? (
          <div className="loading">
            <SimpleBackdrop />
          </div>
        ) : null}

        <div className="quote-header">
          <div className="container">
            <div className="header-inner">
              <div className="logo">
                <a href={BaseUrlData}>
                  <img src={logoImage} alt="SFL Worldwide" />
                </a>
              </div>
              <div className="header-main-menu">
                <ul>
                  <li>
                    <a href= {BaseUrlData + "track-shipment/"}>
                      Tracking
                    </a>
                  </li>
                  <li>
                    <a href="https://hub.sflworldwide.com/">Book Shipment</a>
                  </li>
                  <li>
                    <a href={BaseUrlData + "contact-us"}>
                      Contact Us
                    </a>
                  </li>
                  <li className="head-contact-no">
                    <a href={this.state.hrefPhone}>
                      <img src={HeadContact}></img>
                      <label>{this.state.PhoneNumberTollFree}</label>
                    </a>
                  </li>
                  <li className="head-get-quote">
                    <a href="https://rates.sflworldwide.com/">
                      <img src={HeadGetQuote}></img>
                      <label>Get Quote</label>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-right">
                <ul>
                  <li>
                    <a href="#">
                      <img src={HeadUser}></img>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={HeadSearch}></img>
                    </a>
                  </li>
                  <li className="head-menu-outer">
                    <a onClick={() => this.showHideMenu()}>
                      <img src={HeadMenu}></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="wizard-outer">
          <div className="container">
            <div className="wizard-top-section" id="nave">
              <ul>
                <li className="active" value="3" id="step3">
                  {this.state.nextStepValue === null ? (
                    <img src={Step3}></img>
                  ) : (
                    <img src={tickIcon}></img>
                  )}

                  <div className="step-inner">
                    <span>Step 1</span>

                    <label>Location</label>
                  </div>
                </li>
                <li className="" value="1" id="step1">
                  {this.state.PackageStatus === null ? (
                    <img src={Step1}></img>
                  ) : (
                    <img src={tickIcon}></img>
                  )}

                  <div className="step-inner">
                    <span>Step 2</span>
                    <label>Select Package</label>
                  </div>
                </li>
                {this.state.stepChanges == 0 ? (
                  <li className="" value="2" id="step2">
                    {this.state.ContactStatus === null ? (
                      <img src={Step2}></img>
                    ) : (
                      <img src={tickIcon}></img>
                    )}

                    <div className="step-inner">
                      <span>Step 3</span>
                      <label>Package Details</label>
                    </div>
                  </li>
                ) : null}

                <li className="" value="4" id="step4">
                  <img src={Step4}></img>
                  <div className="step-inner">
                    {this.state.stepChanges == 1 ? (
                      <span>Step 3</span>
                    ) : (
                      <span>Step 4</span>
                    )}
                    <label>Contact</label>
                  </div>
                </li>
              </ul>
            </div>

            {this.state.nextStepValue === null ? (
              <div
                className="Package-details-outer step3"
                id="shipperdetails"
                //style={{ display: "none" }}
              >
                <div className="box-outer section-1">
                  <div className="cst-row">
                    <div className="input-outer input-select col-6">
                      <label>Sending from</label>
                      <Select
                        className="from-country-div"
                        classNamePrefix="frn-select"
                        id="fromCountrySelect"
                        autoComplete="none"
                        options={this.state.CountryListValue}
                        isSearchable="true"
                        onChange={(e) => {
                          this.handleSelect(e, "from");
                        }}
                        value={
                          this.state.SelectedOption
                            ? this.state.SelectedOption
                            : this.state.USSelectedOption
                        }
                        filterOptions={this.handleFilter}
                      ></Select>
                    </div>

                    {this.state.FromSelectedCountry.label ===
                    "United States" ? (
                      <div className="input-outer col-6">
                        <label>From Zip Code</label>
                        <div className="ipt-with-icon icn-left">
                          <input
                            id="fromzipSelect"
                            type="text"
                            maxLength={7}
                            autoComplete="none"
                            role="presentation"
                            onBlur={(e) => this.ChangeFromZipUS(e)}
                            placeholder=" from Zip Code"
                            onFocus={() =>
                              this.setState({
                                FromZipErr: false,
                                FromZipErrText: "",
                                FromZipNotFoundErrText: "",
                              })
                            }
                            onChange={(e) =>
                              this.setState({
                                FromZipCodeValue: e.target.value,
                              })
                            }
                          ></input>
                          <span className="ipt-icn"></span>
                        </div>

                        <FormHelperText style={{ color: "red" }}>
                          {this.state.FromZipErrText}
                        </FormHelperText>
                      </div>
                    ) : this.state.FromSelectedCountry.label === "China" ? (
                      <div className="input-outer col-6">
                        <div className="cst-row">
                          <div className="col-6">
                            <label>From Zip Code</label>
                            <div className="ipt-with-icon icn-left">
                              <input
                                id="fromzipSelect"
                                type="text"
                                maxLength={7}
                                autoComplete="none"
                                role="presentation"
                                onBlur={(e) => this.ChangeFromZipUS(e)}
                                placeholder=" from Zip Code"
                                onFocus={() =>
                                  this.setState({
                                    FromZipErr: false,
                                    FromZipErrText: "",
                                    FromZipNotFoundErrText: "",
                                  })
                                }
                                onChange={(e) =>
                                  this.setState({
                                    FromZipCodeValue: e.target.value,
                                  })
                                }
                              ></input>
                              <span className="ipt-icn"></span>
                            </div>
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.FromZipErrText}
                            </FormHelperText>
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.FromZipErrText}
                            </FormHelperText>
                          </div>
                          <div className="col-6">
                            <div className="input-outer input-select">
                              <Select
                                className="from-country-div"
                                classNamePrefix="frn-select"
                                id="fromCitySelect"
                                options={FromFedExCityListDisplay}
                                isSearchable="true"
                                autoComplete="none"
                                // value={this.state.FromFedExCityValue}
                                // onFocus={(e) =>
                                //   this.handleChange_Value1("FromFedExCity")
                                // }
                                onBlur={(e) =>
                                  this.handleChange_Value1("FromFedExCity")
                                }
                                onChange={(event, value) =>
                                  this.ChangeFromCity(value, "FedEx1")
                                }
                              ></Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : this.state.FromIsFedxCity === 1 ? (
                      <div className="input-outer input-select col-6">
                        <label>From City</label>
                        <div className="top-sp1">
                          <Select
                            className="from-country-div"
                            classNamePrefix="frn-select"
                            id="fromCitySelect"
                            autoComplete="none"
                            options={this.state.FromFedExCityOptionValue}
                            isSearchable="true"
                            onBlur={(e) => {
                              this.setState({
                                FromCityErr: false,
                                FromFedExCityValueErr: "",
                              });
                            }}
                            onChange={(e) => {
                              this.ChangeFromCity(e, "FedEx1");
                            }}
                            value={this.state.FromFedExCityValue}
                          ></Select>
                        </div>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.FromFedExCityValueErr}
                        </FormHelperText>
                      </div>
                    ) : (
                      <div className="input-outer col-6">
                        <label>From Zip Code</label>
                        <div className="ipt-with-icon icn-left">
                          <input
                            id="fromzipSelect"
                            type="text"
                            maxLength={7}
                            
                            role="presentation"
                            autoComplete="none"
                            onBlur={(e) => this.ChangeFromZipUS(e)}
                            placeholder=" from Zip Code"
                            onFocus={() =>
                              this.setState({
                                FromZipErr: false,
                                FromZipErrText: "",
                                FromZipNotFoundErrText: "",
                                FromZipCodeErrText: "",
                              })
                            }
                            onChange={(e) =>
                              this.setState({
                                FromZipCodeValue: e.target.value,
                              })
                            }
                          ></input>
                          <span className="ipt-icn"></span>
                        </div>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.FromZipCodeErr}
                        </FormHelperText>
                      </div>
                    )}
                  </div>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ShipmentErr}
                  </FormHelperText>
                </div>
                <div className="box-outer section-2 ">
                  <div className="cst-row">
                    <div className="input-outer input-select col-6">
                      <label>Sending to</label>
                      <Select
                        className="from-country-div"
                        classNamePrefix="frn-select"
                        id="toCountrySelect"
                        options={this.state.CountryListValue}
                        isSearchable="true"
                        autoComplete="none"
                        onChange={(e) => {
                          this.handleSelect(e, "to");
                        }}
                        onFocus={() =>
                          this.setState({
                            ToCountryErrText: "",
                          })
                        }
                        value={
                          this.state.ToSelectedCountry?.label
                            ? this.state.ToSelectedCountry
                            : this.state.SelectedOptionTo
                        }
                        filterOptions={this.handleFilter}
                      ></Select>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToCountryErrText}
                      </FormHelperText>
                    </div>
                    {this.state.ToSelectedCountry.label === "United States" ? (
                      <div className="input-outer col-6">
                        <label>To Zip Code</label>
                        <div className="ipt-with-icon icn-left">
                          <input
                            id="TozipSelect"
                            type="text"
                            maxLength={7}
                            autoComplete="none"
                            role="presentation"
                            hidden={
                              Object.keys(this.state.ToSelectedCountry)
                                .length === 0
                                ? "hidden"
                                : ""
                            }
                            onBlur={(e) => this.ChangeToZipUS(e)}
                            placeholder="To Zip Code"
                            onFocus={() =>
                              this.setState({
                                ToZipCodeErr: false,
                                ToZipCodeHelperText: "",
                                ToZipCodeEnterText: "",
                              })
                            }
                          ></input>
                          <span className="ipt-icn"></span>
                        </div>
                        <FormHelperText>
                          {this.state.ToZipCodeHelperText}
                        </FormHelperText>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.ToZipErrText}
                        </FormHelperText>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.ToZipCodeEnterText}
                        </FormHelperText>
                      </div>
                    ) : this.state.ToSelectedCountry.label === "China" ? (
                      <div className="input-outer col-6">
                        <div className="cst-row">
                          <div className="input-outer col-6">
                            <label>To Zip Code</label>
                            <div className="ipt-with-icon icn-left">
                              <input
                                id="TozipSelect"
                                type="text"
                                maxLength={7}
                                autoComplete="none"
                                role="presentation"
                                hidden={
                                  Object.keys(this.state.ToSelectedCountry)
                                    .length === 0
                                    ? "hidden"
                                    : ""
                                }
                                onBlur={(e) => this.ChangeToZipUS(e)}
                                placeholder="To Zip Code"
                                onFocus={() =>
                                  this.setState({
                                    ToZipCodeErr: false,
                                    ToZipCodeHelperText: "",
                                    ToZipCodeEnterText: "",
                                  })
                                }
                              ></input>
                              <span class="ipt-icn"></span>
                            </div>
                            <FormHelperText>
                              {this.state.ToZipCodeHelperText}
                            </FormHelperText>
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.ToZipErrText}
                            </FormHelperText>
                            <FormHelperText style={{ color: "red" }}>
                              {this.state.ToZipCodeEnterText}
                            </FormHelperText>
                          </div>
                          <div className="input-outer col-6">
                            {/* <div className="input-outer input-select">
                              <Autocomplete
                                options={ToFedExCityListDisplay}
                                id="toCitySelect"
                                getOptionLabel={(option) => option.label}
                                value={this.state.ToFedExCityValue}
                                autoSelect
                                hidden={
                                  Object.keys(this.state.ToSelectedCountry)
                                    .length === 0
                                    ? "hidden"
                                    : ""
                                }
                                onFocus={(e) =>
                                  this.handleChange_Value1("FromFedExCity")
                                }
                                onBlur={(e) =>
                                  this.handleChange_Value1("ToFedExCity")
                                }
                                onChange={(event, value) =>
                                  this.ChangeToCity(value, "FedEx")
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select City"
                                    fullWidth
                                    error={this.state.ToCityErr}
                                    helperText={this.state.ToCityErrText}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ) : this.state.ToIsFedxCity === 1 ? (
                      <div className="input-outer input-select col-6">
                        {/* <span>select</span> Hello Anshu */}
                        <label>To City</label>
                        <div className="top-sp1">
                          <Select
                            className="from-country-div"
                            classNamePrefix="frn-select"
                            id="toCitySelect"
                            options={this.state.ToFedExCityOptionValue}
                            isSearchable="true"
                            onChange={(e) => {
                              this.ChangeToCity(e, "FedEx1");
                            }}
                            onFocus={() =>
                              this.setState({
                                ToCityErr: false,
                                ToCityErrText: "",
                              })
                            }
                            value={this.state.ToFedExCityValue}
                          ></Select>
                        </div>

                        <FormHelperText style={{ color: "red" }}>
                          {this.state.ToCityErrText}
                        </FormHelperText>
                      </div>
                    ) : this.state.ToSelectedCountry.label != "" ? (
                      <div className="input-outer col-6">
                        <div className="top-sp">
                          <div className="ipt-with-icon icn-left">
                            <input
                              id="TozipSelect"
                              type="text"
                              maxLength={7}
                              autoComplete="none"
                              role="presentation"
                              hidden={
                                Object.keys(this.state.ToSelectedCountry)
                                  .length === 0
                                  ? "hidden"
                                  : ""
                              }
                              onBlur={(e) => this.ChangeToZipUS(e)}
                              placeholder="To Zip Code"
                              onFocus={() =>
                                this.setState({
                                  ToZipCodeErr: false,
                                  ToZipCodeHelperText: "",
                                  ToZipCodeEnterText: "",
                                })
                              }
                            ></input>
                            {this.state.ToSelectedCountry.label == "" ? (
                              ""
                            ) : (
                              <span className="ipt-icn"></span>
                            )}
                          </div>
                        </div>
                        <FormHelperText>
                          {this.state.ToZipCodeHelperText}
                        </FormHelperText>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.ToZipErrText}
                        </FormHelperText>
                        <FormHelperText style={{ color: "red" }}>
                          {this.state.ToZipCodeEnterText}
                        </FormHelperText>
                      </div>
                    ) : null}
                    <div className="resident-check">
                      <input
                        type="checkbox"
                        onChange={(e) => this.isResidenceChange(e)}
                      />{" "}
                      I'm shipping to a residence
                    </div>
                  </div>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ToStateCodeValueErr}
                  </FormHelperText>
                </div>
              </div>
            ) : (
              false
            )}

            <div
              className="wizard-main-section step1"
              id="packagedetails"
              style={{ display: "none" }}
            >
              <h2>Select Types of package</h2>
              <ul>
                <li
                  className=""
                  value="2"
                  id="envelope"
                  onClick={(e) => this.packageDetailsChange(e, "2", "envelope")}
                  href=""
                >
                  <a>
                    <span className="icn-wrap">
                      <img className="df-icon" src={Envelop}></img>
                      <img className="active-icon" src={envelopActive}></img>
                    </span>
                    <label>Envelop</label>
                  </a>
                </li>
                <li
                  className=""
                  id="box"
                  value="1"
                  onClick={(e) => this.packageDetailsChange(e, "1", "box")}
                  href=""
                >
                  <a>
                    <span className="icn-wrap">
                      <img className="df-icon" src={Boxes}></img>
                      <img className="active-icon" src={boxesActive}></img>
                    </span>
                    <label>Boxes</label>
                  </a>
                </li>
                <li
                  className=""
                  id="television"
                  value="4"
                  onClick={(e) =>
                    this.packageDetailsChange(e, "4", "television")
                  }
                  href=""
                >
                  <a>
                    <span className="icn-wrap">
                      <img className="df-icon" src={Television}></img>
                      <img className="active-icon" src={televisionActive}></img>
                    </span>
                    <label>Television</label>
                  </a>
                </li>
                <li
                  className=""
                  id="furniture"
                  value="3"
                  onClick={(e) =>
                    this.packageDetailsChange(e, "3", "furniture")
                  }
                  href=""
                >
                  <a>
                    <span className="icn-wrap">
                      <img className="df-icon" src={Furniture}></img>
                      <img className="active-icon" src={furnitureActive}></img>
                    </span>
                    <label>Furniture</label>
                  </a>
                </li>
                <li
                  className=""
                  id="auto"
                  value="5"
                  onClick={(e) => this.packageDetailsChange(e, "5", "auto")}
                  href=""
                >
                  <a>
                    <span className="icn-wrap">
                      <img className="df-icon" src={Auto}></img>
                      <img className="active-icon" src={autoActive}></img>
                    </span>
                    <label>Auto</label>
                  </a>
                </li>
              </ul>
            </div>

            <FormHelperText style={{ color: "red" }} id="referrederror">
              {this.state.erorrmsg}
            </FormHelperText>

            <div
              className="Package-details-outer step2"
              id="shipmentdetails"
              style={{ display: "none" }}
            >
              {this.state.showBoxDetails === true ? (
                <div className="box-outer section-1 box-white">
                  <div className="box-title mb-15">
                    <h2>Box Details </h2>SelectedWeightType
                  </div>
                  <div className="bx-wrap">
                    <div className="title-ipt">
                      <FormControl>
                        <select
                          className="weightType"
                          id="field"
                          name="field"
                          onChange={(event, value) =>
                            this.changeWeightType(event, value)
                          }
                          value={this.state.SelectedWeightType}
                        >
                          <option value="LB/IN">LB/IN</option>
                          <option value="KG/CM">KG/CM</option>
                        </select>
                        {/* <Autocomplete
                          options={this.state.packedBy}
                          fullWidth={true}
                          id="field"
                          name="field"
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          value={this.state.SelectedWeightType}
                          onChange={(event, value) =>
                            this.changeWeightType(event, value)
                          }
                          renderInput={(params) => (
                            <TextField
                              type="text"
                              {...params}
                              // className={classes.input}
                              // label="weight"
                              // margin="normal"
                              fullWidth
                            />
                          )}
                        /> */}
                      </FormControl>
                      {/* <FormControl className={classes.formControl} fullWidth>
                        <InputLabel
                          htmlFor="selectshipmenttype"
                          className={classes.selectLabel}
                        >
                          Residential
                        </InputLabel>
                        <Select
                          onChange={(event) => this.ChangeResidential(event)}
                          inputProps={{
                            name: "residential?",
                            id: "residential",
                          }}
                          defaultValue="no"
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="yes"
                          >
                            {" "}
                            Yes{" "}
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="no"
                          >
                            {" "}
                            No{" "}
                          </MenuItem>
                        </Select>
                      </FormControl> */}
                    </div>
                    {this.renderBoxDetails()}
                    <FormHelperText style={{ color: "red" }}>
                      {this.state.BoxesErrText}
                    </FormHelperText>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.showTVDetails === true ? (
                <div className="box-outer section-2 box-white">
                  <h2>Television</h2>
                  <div className="bx-wrap">
                    <div className="title-ipt for-tv">
                      <FormControl>
                        <select
                          id="field"
                          name="field"
                          className="weightType"
                          onChange={(event, value) =>
                            this.changeWeightType(event, value)
                          }
                          value={this.state.SelectedWeightType}
                        >
                          <option value="LB/IN">LB/IN</option>
                          <option value="KG/CM">KG/CM</option>
                        </select>
                        {/* <Autocomplete
                          options={this.state.packedBy}
                          fullWidth={true}
                          id="field"
                          name="field"
                          getOptionLabel={(option) =>
                            option.label ? option.label : option
                          }
                          value={this.state.SelectedWeightType}
                          onChange={(event, value) =>
                            this.changeWeightType(event, value)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // label="weight"
                              // margin="normal"
                              fullWidth
                            />
                          )}
                        /> */}
                      </FormControl>
                    </div>
                    {this.renderTvDetails()}
                    <FormHelperText style={{ color: "red" }}>
                      {this.state.TelevisionErrText}
                    </FormHelperText>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.showCarDetails === true ? (
                <div className="box-outer section-3 box-white">
                  <h2>Auto</h2>
                  {this.renderCarDetails()}
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.CarErrText}
                  </FormHelperText>
                </div>
              ) : (
                ""
              )}
            </div>
            <FormHelperText style={{ color: "red" }} id="weightBoxDetailserror">
              {this.state.PackageDetailsErr}
            </FormHelperText>

            <div
              className="Package-details-outer step3"
              id="shipperdetails"
              style={{ display: "none" }}
            >
              <div className="box-outer section-1">
                <div className="cst-row">
                  <div className="input-outer input-select col-6">
                    <label>Sending from</label>

                    <Select
                      className="from-country-div"
                      classNamePrefix="frn-select"
                      id="fromCountrySelect"
                      options={this.state.CountryListValue}
                      isSearchable="true"
                      onChange={(e) => {
                        this.handleSelect(e, "from");
                      }}
                      value={
                        this.state.SelectedOption
                          ? this.state.SelectedOption
                          : this.state.USSelectedOption
                      }
                      filterOptions={this.handleFilter}
                    ></Select>
                  </div>

                  {this.state.FromSelectedCountry.label === "United States" ? (
                    <div className="input-outer col-6">
                      <label>From Zip Code</label>
                      <div className="ipt-with-icon icn-left">
                        <input
                          id="fromzipSelect"
                          type="text"
                          maxLength={7}
                          autoComplete="off"
                          role="presentation"
                          onBlur={(e) => this.ChangeFromZipUS(e)}
                          placeholder=" from Zip Code"
                          onFocus={() =>
                            this.setState({
                              FromZipErr: false,
                              FromZipErrText: "",
                              FromZipNotFoundErrText: "",
                            })
                          }
                          onChange={(e) =>
                            this.setState({
                              FromZipCodeValue: e.target.value,
                            })
                          }
                        ></input>
                        <span className="ipt-icn"></span>
                      </div>

                      <FormHelperText style={{ color: "red" }}>
                        {this.state.FromZipCodeErrText}
                      </FormHelperText>
                    </div>
                  ) : this.state.FromSelectedCountry.label === "China" ? (
                    <div className="input-outer col-6">
                      <div className="cst-row">
                        <div className="col-6">
                          <label>From Zip Code</label>
                          <div className="ipt-with-icon icn-left">
                            <input
                              id="fromzipSelect"
                              type="text"
                              maxLength={7}
                              autoComplete="off"
                              role="presentation"
                              onBlur={(e) => this.ChangeFromZipUS(e)}
                              placeholder=" from Zip Code"
                              onFocus={() =>
                                this.setState({
                                  FromZipErr: false,
                                  FromZipErrText: "",
                                  FromZipNotFoundErrText: "",
                                })
                              }
                              onChange={(e) =>
                                this.setState({
                                  FromZipCodeValue: e.target.value,
                                })
                              }
                            ></input>
                            <span className="ipt-icn"></span>
                          </div>
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.FromZipCodeErr}
                          </FormHelperText>
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.FromZipCodeErr}
                          </FormHelperText>
                        </div>
                        <div className="col-6">
                          <div className="input-outer input-select">
                            {/* <span>Hello</span> */}
                            <Select
                              className="from-country-div"
                              classNamePrefix="frn-select"
                              id="fromCitySelect"
                              options={FromFedExCityListDisplay}
                              isSearchable="true"
                              // value={this.state.FromFedExCityValue}
                              onFocus={(e) =>
                                this.handleChange_Value1("FromFedExCity")
                              }
                              onBlur={(e) =>
                                this.handleChange_Value1("FromFedExCity")
                              }
                              onChange={(event, value) =>
                                this.ChangeFromCity(value, "FedEx")
                              }

                              // filterOptions={this.handleFilter}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.FromIsFedxCity === 1 ? (
                    <div className="input-outer input-select col-6">
                      <div className="top-sp">
                        {/* <span>Hello12</span> */}
                        <Select
                          className="from-country-div"
                          classNamePrefix="frn-select"
                          id="fromCitySelect"
                          options={this.state.FromFedExCityOptionValue}
                          isSearchable="true"
                          onChange={(e) => {
                            this.ChangeFromCity(e, "FedEx1");
                          }}
                          value={this.state.FromFedExCityValue}
                          // filterOptions={this.handleFilter}
                        ></Select>
                      </div>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.FromFedExCityValueErr}
                      </FormHelperText>
                    </div>
                  ) : (
                    <div className="input-outer col-6">
                      <label>From Zip Code</label>
                      <div className="ipt-with-icon icn-left">
                        <input
                          id="fromzipSelect"
                          type="text"
                          maxLength={7}
                          autoComplete="off"
                          role="presentation"
                          onBlur={(e) => this.ChangeFromZipUS(e)}
                          placeholder=" from Zip Code"
                          onFocus={() =>
                            this.setState({
                              FromZipErr: false,
                              FromZipErrText: "",
                              FromZipNotFoundErrText: "",
                              FromZipCodeErrText: "",
                            })
                          }
                          onChange={(e) =>
                            this.setState({
                              FromZipCodeValue: e.target.value,
                            })
                          }
                        ></input>
                        <span className="ipt-icn"></span>
                      </div>
                      {/* <FormHelperText style={{ color: "red" }}>
                        {this.state.FromZipErrText}
                      </FormHelperText> */}
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.FromZipCodeErr}
                      </FormHelperText>
                    </div>
                  )}
                </div>
                <FormHelperText style={{ color: "red" }}>
                  {this.state.ShipmentErr}
                </FormHelperText>
              </div>
              <div className="box-outer section-2 ">
                <div className="cst-row">
                  <div className="input-outer input-select col-6">
                    <label>Sending to</label>
                    <Select
                      className="from-country-div"
                      classNamePrefix="frn-select"
                      id="toCountrySelect"
                      options={this.state.CountryListValue}
                      isSearchable="true"
                      onChange={(e) => {
                        this.handleSelect(e, "to");
                      }}
                      // value={
                      //   this.state.ToSelectedCountry?.label
                      //     ? this.state.ToSelectedCountry
                      //     : ""
                      // }
                      value={
                        this.state.ToSelectedCountry?.label
                          ? this.state.ToSelectedCountry
                          : this.state.SelectedOptionTo
                      }
                      filterOptions={this.handleFilter}
                    ></Select>
                  </div>
                  {this.state.ToSelectedCountry.label === "United States" ? (
                    <div className="input-outer col-6">
                      <label>To Zip Code</label>
                      <div className="ipt-with-icon icn-left">
                        <input
                          id="TozipSelect"
                          type="text"
                          maxLength={7}
                          autoComplete="off"
                          role="presentation"
                          hidden={
                            Object.keys(this.state.ToSelectedCountry).length ===
                            0
                              ? "hidden"
                              : ""
                          }
                          onBlur={(e) => this.ChangeToZipUS(e)}
                          placeholder="To Zip Code"
                          onFocus={() =>
                            this.setState({
                              ToZipCodeErr: false,
                              ToZipCodeHelperText: "",
                              ToZipCodeEnterText: "",
                            })
                          }
                        ></input>
                        <span className="ipt-icn"></span>
                      </div>
                      <FormHelperText>
                        {this.state.ToZipCodeHelperText}
                      </FormHelperText>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToZipErrText}
                      </FormHelperText>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToZipCodeEnterText}
                      </FormHelperText>
                    </div>
                  ) : this.state.ToSelectedCountry.label === "China" ? (
                    <div className="input-outer col-6">
                      <div className="cst-row">
                        <div className="input-outer col-6">
                          <label>To Zip Code</label>
                          <div className="ipt-with-icon icn-left">
                            <input
                              id="TozipSelect"
                              type="text"
                              maxLength={7}
                              autoComplete="off"
                              role="presentation"
                              hidden={
                                Object.keys(this.state.ToSelectedCountry)
                                  .length === 0
                                  ? "hidden"
                                  : ""
                              }
                              onBlur={(e) => this.ChangeToZipUS(e)}
                              placeholder="To Zip Code"
                              onFocus={() =>
                                this.setState({
                                  ToZipCodeErr: false,
                                  ToZipCodeHelperText: "",
                                  ToZipCodeEnterText: "",
                                })
                              }
                            ></input>
                            <span class="ipt-icn"></span>
                          </div>
                          <FormHelperText>
                            {this.state.ToZipCodeHelperText}
                          </FormHelperText>
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.ToZipErrText}
                          </FormHelperText>
                          <FormHelperText style={{ color: "red" }}>
                            {this.state.ToZipCodeEnterText}
                          </FormHelperText>
                        </div>
                        <div className="input-outer col-6">
                          <div className="input-outer input-select">
                            {/* <Autocomplete
                              options={ToFedExCityListDisplay}
                              id="toCitySelect"
                              getOptionLabel={(option) => option.label}
                             
                              value={this.state.ToFedExCityValue}
                              autoSelect
                              hidden={
                                Object.keys(this.state.ToSelectedCountry)
                                  .length === 0
                                  ? "hidden"
                                  : ""
                              }
                              onFocus={(e) =>
                                this.handleChange_Value1("FromFedExCity")
                              }
                              onBlur={(e) =>
                                this.handleChange_Value1("ToFedExCity")
                              }
                              onChange={(event, value) =>
                                this.ChangeToCity(value, "FedEx")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select City"
                                  fullWidth
                                  error={this.state.ToCityErr}
                                  helperText={this.state.ToCityErrText}
                                  variant="outlined"
                                />
                              )}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : this.state.ToIsFedxCity === 1 ? (
                    <div className="input-outer input-select col-6">
                      <div className="top-sp">
                        {/* <Select
                          className="from-country-div"
                          classNamePrefix="frn-select"
                          id="toCitySelect"
                          options={this.state.ToFedExCityOptionValue}
                          isSearchable="true"
                          onChange={(e) => {
                            this.ChangeToCity(e, "FedEx1");
                          }}
                          value={this.state.ToFedExCityValue}
                        filterOptions={this.handleFilter}
                        ></Select> */}
                      </div>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToZipCodeValueErr}
                      </FormHelperText>
                    </div>
                  ) : this.state.ToSelectedCountry.label != "" ? (
                    <div className="input-outer col-6">
                      <div className="top-sp">
                        <div className="ipt-with-icon icn-left">
                          <input
                            id="TozipSelect"
                            type="text"
                            maxLength={7}
                            autoComplete="off"
                            role="presentation"
                            hidden={
                              Object.keys(this.state.ToSelectedCountry)
                                .length === 0
                                ? "hidden"
                                : ""
                            }
                            onBlur={(e) => this.ChangeToZipUS(e)}
                            placeholder="To Zip Code"
                            onFocus={() =>
                              this.setState({
                                ToZipCodeErr: false,
                                ToZipCodeHelperText: "",
                                ToZipCodeEnterText: "",
                              })
                            }
                          ></input>
                          {this.state.ToSelectedCountry.label == "" ? (
                            ""
                          ) : (
                            <span className="ipt-icn"></span>
                          )}
                        </div>
                      </div>
                      <FormHelperText>
                        {this.state.ToZipCodeHelperText}
                      </FormHelperText>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToZipErrText}
                      </FormHelperText>
                      <FormHelperText style={{ color: "red" }}>
                        {this.state.ToZipCodeEnterText}
                      </FormHelperText>
                    </div>
                  ) : null}
                  <div className="resident-check">
                    <input
                      type="checkbox"
                      onChange={(e) => this.isResidenceChange(e)}
                    />{" "}
                    I'm shipping to a residence
                  </div>
                </div>
                <FormHelperText style={{ color: "red" }}>
                  {this.state.ToStateCodeValueErr}
                </FormHelperText>
              </div>
            </div>
            <div
              className="Package-details-outer step4"
              id="shipsmart"
              style={{ display: "none" }}
            >
              <div className="box-outer section-1">
                <div className="input-outer col-2">
                  <label>Name</label>
                  <input
                    autoComplete="none"
                    role="presentation"
                    id="ContactName"
                    type="text"
                    maxLength={30}
                    onBlur={(e) => this.validateShipperInfo(e, "cname")}
                    onChange={(e) => {
                      this.shipperInfoChange(e, "ContactName");
                    }}
                    onFocus={() =>
                      this.setState({
                        cnameErrText: "",
                      })
                    }
                    placeholder="Enter name"
                  ></input>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.cnameErrText}
                  </FormHelperText>

                  <span className="user-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ContactvalueErr}
                  </FormHelperText>
                </div>
                <div className="input-outer col-2">
                  <label>Email Address</label>
                  <input
                  
                    autoComplete="none"
                    role="presentation"
                    type="email"
                    maxLength={60}
                    id="email"
                    value={this.state.email || ""}
                    onBlur={(e) => this.validateShipperInfo(e, "email")}
                    onChange={(e) => this.shipperInfoChange(e, "Email")}
                    onFocus={() =>
                      this.setState({
                        EmailErrText: "",
                        shipperdetailsErrText: "",
                        EmailIdErrText: "",
                      })
                    }
                    // onBlur={() => {
                    //   this.setState({
                    //     EmailIdErrText: "Please enter valid email"
                    //   })
                    // }}

                    placeholder="Enter email"
                  ></input>
                  <span className="email-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.EmailErrText
                      ? this.state.EmailErrText
                      : this.state.EmailIdErrText}
                  </FormHelperText>
                </div>
                <div className="input-outer input-select col-country-code">
                    <label>Country Code</label>

                    <Select
                      className="Country-code"
                      classNamePrefix="Test-frn-select"
                      id="fromCountrySelect"
                      options={this.state.countryCodeListValue}
                      isSearchable="true"
                      onBlur={(e) => this.validateShipperInfo(e, "countryCode")}
                      onChange={(e) => this.shipperInfoChange(e, "countryCode")}
                      value={
                        this.state.SelectedOptionCountryCode
                         
                          
                      }
                      filterOptions={this.handleFilter}
                    ></Select>
                    <FormHelperText style={{ color: "red" }}>
                    {this.state.CountryCodeErrText
                      ? this.state.CountryCodeErrText
                      : this.state.CountryCodeIdErrText}
                  </FormHelperText>
                  </div>
                <div className="input-outer col-custom">
                  <label>Phone Number</label>
                  <input
                    autoComplete="none"
                    role="presentation"
                    id="Number"
                    type="tel"
                    placeholder="Enter phone number"
                    value={this.state.PhoneNumber || ""}
                    // onChange={(e) => this.getBoxesData(i, e)}
                    onChange={(e) => {
                      e.target.value.length <= 10 &&
                        // this.getBoxesData(i, e);
                        this.setState({ PhoneNumber: e.target.value });
                    }}
                    onBlur={(e) => this.validateShipperInfo(e, "phoneblur")}
                    onFocus={() =>
                      this.setState({
                        contactErrText: "",
                        onlyNumberErrText: "",
                        ContactNumberErrText: "",
                      })
                    }
                  ></input>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.contactErrText}
                  </FormHelperText>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.onlyNumberErrText}
                  </FormHelperText>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.ContactNumberErrText}
                  </FormHelperText>
                  <span className="phone-icon"></span>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.PhoneNumbervalueErr}
                  </FormHelperText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="btn-outer">
            <a
              className="backbtn"
              onClick={() =>
                this.navigateChangeprevious(this.state.NextButtonIdx - 1)
              }
            >
              Back
            </a>
            {/* ): null} */}

            <a
              className="nextbtn"
              onClick={() => this.navigateChange(this.state.NextButtonIdx + 1)}
            >
              Next
            </a>
          </div>
        </div>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.5.5/css/simple-line-icons.min.css"
        ></link>

        <div
          className="sidebar-outer"
          style={{ display: "none" }}
          id="sidebarCheck"
        >
          <div className="sidenav_wrap">
            <a
              href="javscript:;"
              onClick={() => this.showHideMenu()}
              className="side_nav_close"
              aria-label="Close Sidebar"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.04962 14.95C1.23715 15.1375 1.49146 15.2428 1.75662 15.2428C2.02179 15.2428 2.27609 15.1375 2.46362 14.95L7.99962 9.414L13.5356 14.95C13.7242 15.1322 13.9768 15.233 14.239 15.2307C14.5012 15.2284 14.752 15.1232 14.9374 14.9378C15.1228 14.7524 15.228 14.5016 15.2303 14.2394C15.2326 13.9772 15.1318 13.7246 14.9496 13.536L9.41362 8L14.9496 2.464C15.1318 2.2754 15.2326 2.02279 15.2303 1.7606C15.228 1.4984 15.1228 1.24759 14.9374 1.06218C14.752 0.876772 14.5012 0.771603 14.239 0.769325C13.9768 0.767046 13.7242 0.867841 13.5356 1.05L7.99962 6.586L2.46362 1.05C2.27502 0.867841 2.02242 0.767046 1.76022 0.769325C1.49802 0.771603 1.24721 0.876772 1.0618 1.06218C0.876396 1.24759 0.771227 1.4984 0.768949 1.7606C0.76667 2.02279 0.867464 2.2754 1.04962 2.464L6.58562 8L1.04962 13.536C0.862151 13.7235 0.756836 13.9778 0.756836 14.243C0.756836 14.5082 0.862151 14.7625 1.04962 14.95Z"></path>
              </svg>
            </a>
            <div className="sidenav_inner">

              {this.state.indiaornot == "No"?(

                  <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
                <li className="menu-item">
                  <a href={BaseUrlData} className="menu-link">
                    <i
                      className="icon before line-icon icon-home"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Home</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://rates.sflworldwide.com/"
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-cursor"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Get Quote</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-tag"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Book Shipment</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"track-shipment"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-target"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Tracking</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="https://hub.sflworldwide.com/" className="menu-link">
                    <i
                      className="icon before line-icon icon-user"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">My Account</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+ "about-us"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-briefcase"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">About Us</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+ "services"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-list"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Services</span>
                  </a>
                </li>
                
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"shipping-for-small-business"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-organization"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Small Business Shipping</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"shop-us-stores-ship-worldwide"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-globe"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">
                      Shop US and Ship worldwide
                    </span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"carriers"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-paper-plane"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Major Carriers</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"worldwide-destinations"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-globe-alt"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Worldwide Destinations</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"resources"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-notebook"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Resources</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href={BaseUrlData+"faq"} className="menu-link">
                    <i
                      className="icon before line-icon icon-question"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">FAQs</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"contact-us/locations"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-location-pin"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Locations</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href={BaseUrlData+"blog"} className="menu-link">
                    <i
                      className="icon before line-icon icon-info"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Blog</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"contact-us"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-envelope"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Contact Us</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"search"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-magnifier"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Site Search</span>
                  </a>
                </li>
              </ul>

                ):
                
                  <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
                <li className="menu-item">
                  <a href={BaseUrlData} className="menu-link">
                    <i
                      className="icon before line-icon icon-home"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Home</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://rates.sflworldwide.com/"
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-cursor"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Get Quote</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-tag"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Book Shipment</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"track-shipment"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-target"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Tracking</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="https://hub.sflworldwide.com/" className="menu-link">
                    <i
                      className="icon before line-icon icon-user"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">My Account</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+ "about-us"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-briefcase"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">About Us</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+ "services"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-list"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Services</span>
                  </a>
                </li>
                
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"worldwide-destinations"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-globe-alt"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Worldwide Destinations</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"resources"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-notebook"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Resources</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href={BaseUrlData+"faq"} className="menu-link">
                    <i
                      className="icon before line-icon icon-question"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">FAQs</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"contact-us/locations"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-location-pin"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Locations</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a href={BaseUrlData+"blog"} className="menu-link">
                    <i
                      className="icon before line-icon icon-info"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Blog</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"contact-us"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-envelope"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Contact Us</span>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href={BaseUrlData+"search"}
                    className="menu-link"
                  >
                    <i
                      className="icon before line-icon icon-magnifier"
                      aria-hidden="true"
                    ></i>
                    <span className="menu-text">Site Search</span>
                  </a>
                </li>
              </ul>

                }

              

            </div>
          </div>
        </div>
      </div>
    );
  }
}

//export default GetQuoteThankyouDemo;
export default withStyles()(GetQuoteThankyouDemo);
